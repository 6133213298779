$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
require '../directives/ui_dropdown.coffee'
eventHub = require '../event_hub.coffee'
products_store = require '../products_store.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'

purchase_order_request = new Vue
#  el: '.purchase-order-request-form-wrapper'
  data: ->
    items: []
    errors: []
    is_ready: false
    contact_id: null

  components:
    'purchase-order-request-item': require('./components/purchase-order-request-item.coffee')


  mixins: [formSubmitMixin]

  created: ->
    el = $('.purchase-order-request-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)


    @contact_id = window.contact_gid

    products_store.fetchSimpleItems(@).then =>
      if window.purchase_order_request_items
        Vue.set(@, 'items', window.purchase_order_request_items)
      else
        @addItem()


  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})


  computed:
    count: -> @items.length


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    validContactId: ->
      @contact_id? and @contact_id != ""


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    isValidAttributes: ->
      @validContactId

    isValid: ->
       @isValidAttributes and @hasItems and @itemsIsValid #and not @items_has_duplicates


  methods:
    addItem: (event)->
      item =
        id: null
        _destroy: null
        quantity: 1
        product_id: null
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.product_id isnt null and
        item.quantity != "" and q > 0




if document.querySelector('.purchase-order-request-form-wrapper')
  purchase_order_request.$mount('.purchase-order-request-form-wrapper')
