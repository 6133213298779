eventHub = require '../event_hub.coffee'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'inventory_stocktaking[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'inventory_stocktaking[items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <input  v-model="item.product_id"
            :name="'inventory_stocktaking[items_attributes][' + _uid + '][product_id]'"
            type='hidden'>
          <div class='p-2'>{{item.product_name}}</div>
        </div>

      </td>

      <td>
        <div class="ui field">
           <input  v-model="item.expected_quantity"
            :name="'inventory_stocktaking[items_attributes][' + _uid + '][expected_quantity]'"
            type='hidden'>
          <span class='num'>{{item.expected_quantity}}</span>
        </div>
      </td>
      <td>
        <div class="ui field">
          <input  v-model="item.quantity"
            :name="'inventory_stocktaking[items_attributes][' + _uid + '][quantity]'"
            type='hidden'>
          <span class='num'>{{item.quantity}}</span>
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']
#  data: ->
#    inventory: 0


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    removeItem: (index)->
      if @item.id
        @$set(@item, '_destroy', true)
      else
        eventHub.$emit('remove-item', { index: index })


  computed:
    isValid: ->
      @item.product_id? and round_quantity(@item.quantity) > 0

    hasEqualQuantity: ->
      round_number(@item.quantity) is round_number(@item.expected_quantity)

    inventoryIsEmpty: ->
      round_number(@item.expected_quantity) is 0


    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list['text-muted']    = @hasEqualQuantity
      list['text-danger']    = @inventoryIsEmpty
      list

