$ = require 'jquery'
Vue = require 'vue'
require '../directives/date_picker.coffee'


credit_memo = new Vue
  data: ->
    is_ready: false
    tax_office_id: window.credit_reconciliation?.tax_office_id
    owner_id: window.credit_reconciliation?.owner_id
    contact_id: window.contact_gid
    issued_at: window.credit_reconciliation?.issued_at
    reference: window.credit_reconciliation?.reference
    amount: window.credit_reconciliation?.amount



  computed:
    isValidContact: ->
      @tax_office_id != null and @tax_office_id isnt ""


    isValidAttributes: ->
      @isValidContact and @owner_id != null


    isValid: ->
      @isValidAttributes



if document.querySelector('.credit-reconciliation-form-wrapper')
  credit_memo.$mount('.credit-reconciliation-form-wrapper')
