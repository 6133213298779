$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
eventHub = require '../event_hub.coffee'


hr_offboarding_task_list_form = new Vue
  data:
    tasks: window.hr_offboarding_tasks || []
    newTask: {}
    editTaskIndex: null
    editForm: false
    formIsVisible: false
    is_ready: true

  components:
    'taskitem': require('./components/task-item.coffee')
    'datepicker': require('../components/simple_date_picker.coffee')


  created: ->
    @newTask = @buildNewTask()

    eventHub.$on('editTask', @editTask)


  computed:
    taskIsValid: ->
      vm = @
      not _.some @tasks, (i)-> not vm.itemIsValid(i)


    isValid: ->
      @taskIsValid


  methods:
    buildNewTask: ->
      name: null
      description: null
      assigned_to_id: null
      due_date: null
      context: 'offboarding'
      employee_id: window.employee_id


    toggleTaskForm: ->
      @formIsVisible = !@formIsVisible


    editTask: (data)->
      if @editForm and @editTaskIndex is data.index
        @newTask = @buildNewTask()
        @formIsVisible = false
        @editTaskIndex = null
        @editForm = false

      else
        @newTask = @tasks[data.index]
        @formIsVisible = true
        @editTaskIndex = data.index
        @editForm = true


    saveTask: ->
      url = "/hr/tasks/#{@newTask.id}"

      data = { hr_task: @newTask }

      $.ajax({
        type: 'PUT',
        dataType: 'json',
        url: url,
        data: data,
      }).done((r)=>
        console.log('response', r)

        @newTask = @buildNewTask()

        @tasks[@editTaskIndex] = r.task

        @editTaskIndex = null
        @editForm = false
        @formIsVisible = false
      )


    addTask: ->
      @createTask()

    createTask: ->
      url = "/hr/tasks"

      data = { hr_task: @newTask }

      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        data: data,
      }).done((r)=>
        console.log('response', r)

        @tasks.push r
        @newTask = @buildNewTask()
        @formIsVisible = false
      )

    itemIsValid: (task)->
      task.name isnt "" and task.name isnt null and task.name?.length > 2



selector = '.hr-offboarding-task-list-wrapper'
hr_offboarding_task_list_form.$mount(selector) if document.querySelector(selector)
