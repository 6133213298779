$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
products_store = require '../products_store.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
require '../directives/ui_dropdown.coffee'
require '../directives/date_picker.coffee'



sales_order_invoice = new Vue
#  el: '.sales-order-invoice-form-wrapper'
  data:
    items: window.sales_order_invoice_items || []
    is_ready: false
    invoice_number: window.sales_order_invoice?.invoice_number
    payment_term_id: window.sales_order_invoice?.payment_term_id
    due_at: window.sales_order_invoice?.due_at
    invoiced_at: window.sales_order_invoice?.invoiced_at
    cash_discount: window.sales_order_invoice?.cash_discount
    stamp_cash: window.sales_order_invoice?.stamp_cash
    vat_rate: window.sales_order_invoice?.vat_rate
    discount_tax_rate: window.sales_order_invoice?.discount_tax_rate
    is_taxable: window.sales_order_invoice?.is_taxable
    owner_id: window.sales_order_invoice?.owner_id


  components:
    'sales-order-invoice-item': require('./components/sales-order-invoice-item.coffee')

  mixins: [formSubmitMixin]


  created: ->
    el = $('.sales-order-invoice-items-wrapper')
    return unless el.length

    @items = window.sales_order_invoice_items or []


  computed:
    selectedItems: -> _.filter(@items, (i)-> i.selected) or []

    total_units: -> @selectedItems.length


#    taxed_sub_total: ->
#      _.reduce @selectedItems, (sum, item)=>
#        if @itemIsValid(item)
#          sum.plus(@item_taxed_sub_total(item))
#        else
#          sum.plus(0)
#      , Big(0)
#
#    vat_value: ->
#      rate = round_number(@vat_rate, 2)
#      return Big(0) if rate is 0
#
#      Big(@taxed_sub_total).times(rate).div(100)
#
#
#    discount_tax_value: ->
#      rate = round_number(@discount_tax_rate, 2)
#      return Big(0) if rate is 0
#
#      Big(@taxed_sub_total).times(rate).div(100)
#
#
#    taxed_net_total: ->
#      stamp_cash = round_number(@stamp_cash, 3)
#      cash_discount = round_number(@cash_discount, 3)
#
#      Big(@taxed_sub_total)
#        .plus(@vat_value)
#        .minus(@discount_tax_value)
#        .minus(stamp_cash)
#        .minus(cash_discount)


    basic_sub_total: ->
      _.reduce @selectedItems, (sum, item)=>
        return sum.plus(0) unless @itemIsValid(item)

        quantity = round_quantity(item.quantity)
        unit_price = round_number(item.unit_price, 3)

        sum.plus(Big(quantity).times(unit_price))

      , Big(0)


    tax_amount: ->
      _.reduce @selectedItems, (sum, item)=>
        return sum.plus(0) unless @itemIsValid(item)

        quantity = round_quantity(item.quantity)
        unit_price = round_number(item.unit_price, 3)
        tax_rate = round_number(item.tax, 2)

        sub_total = Big(quantity).times(unit_price)
        tax = sub_total.times(tax_rate).div(100)

        sum.plus(tax)

      , Big(0)

    basic_net_total: ->
      cash_discount = round_number(@cash_discount, 2)
      @sub_total.minus(cash_discount)


    sub_total: ->
      @basic_sub_total


    net_total: ->
      @basic_net_total.plus(@tax_amount)



    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(i)


    # Validations

    hasSelectedItems: -> @selectedItems.length > 0

    validDueAt: ->
      @due_at? and @due_at != ""

    validInvoicedAt: ->
      @invoiced_at? and @invoiced_at != ""

    validInvoiceNumber: ->
      @invoice_number? and @invoice_number != ""

    validPaymentTermId: ->
      @payment_term_id? and @payment_term_id != ""

    isValidAttributes: ->
      @validDueAt and @validInvoicedAt and @validPaymentTermId

    isValid: ->
      @isValidAttributes and @hasSelectedItems and @itemsIsValid


  methods:
    item_unit_price_factor: (item)->
      return Big(1) if item.product_vat is 0
      Big(1).plus(Big(item.product_vat).div(100))

    item_taxed_unit_price: (item)->
      unit_price = round_number(item.unit_price, 3)
      Big(unit_price).div(@item_unit_price_factor(item))

    item_taxed_sub_total: (item)->
      quantity = round_quantity(item.quantity)
      @item_taxed_unit_price(item).times(quantity)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.quantity != "" and q > 0 and
        q <= round_quantity(item.not_invoiced_quantity)


el = '.sales-order-invoice-form-wrapper'
sales_order_invoice.$mount(el) if document.querySelector(el)
