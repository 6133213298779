inputKeyHandlers =
  methods:
    nextItem: (item_class, e)->
      $next = $(".item-#{@_uid}").next()
      $next.find(item_class).focus().select() if $next.length

    prevItem: (item_class, e)->
      $prev = $(".item-#{@_uid}").prev()
      $prev.find(item_class).focus().select() if $prev.length

module.exports = inputKeyHandlers
