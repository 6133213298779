$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
_ = require 'lodash/core'


routing = new Vue
  data:
    items: []
    is_ready: true
    name: null
    reference: null

  components:
    'routing-operation': require('./components/routing_operation.coffee')

  mixins: [formSubmitMixin]


  created: ->
    el = $('.mrp-routing-form-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)


  computed:
    count: -> @items.length


    itemsIsValid: ->
      vm = @
      not _.some(@items, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @items.length > 0


    isValidAttributes: ->
      @name isnt null


    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid


  methods:
    addItem: (event)->
      item = {name: null, work_center_id: null, description: null, duration_in_minutes: null}
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      item.name isnt null and item.work_center_id isnt null

  events:
    'remove-item': (index)-> @removeItem(index)


if document.querySelector('.mrp-routing-form-wrapper')
  routing.$mount('.mrp-routing-form-wrapper')
