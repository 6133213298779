store = require '../../stores/default.coffee'

module.exports =
  template: """
    <div class="pos-items-with-categories">
      <div class="pos--items--categories">
        <category-item
          ref="category_item"
          v-for="(item, index) in cats"
          :item="item"
          :index="index"
          :key="index"
        >
        </category-item>
      </div>

      <div class="pos--items--list">
        <product-item
          ref="product_item"
          v-for="(item, index) in products"
          :item="item"
          :index="index"
          :key="index"
        >
        </product-item>
      </div>
    </div>
  """

  components:
    'category-item': require('./category_item.coffee')
    'product-item': require('./product_item.coffee')

  store: store


  computed:
    cats: -> @$store.state.pos.current_tab.pos_category_children
    products: -> @$store.state.pos.current_tab.pos_category_products
