UnitOfMeasureHandlers =
  data: ->
    unit_of_measure: null

  methods:
    setDefaultUoM: (unit)->
      return if @item.unit_of_measure_id

      @item.unit_of_measure_id = unit.id
      @setUoM(unit)

    setUoM: (uom)->
      console.log "UoM => setting", uom
      return unless uom

      @unit_of_measure = uom

    updateUnitPrice: (unit_price)->
      return unless @product or @unit_of_measure
      console.log('UoM => UPDATING UNIT PRICE FROM => ', unit_price)

      price = @calcUnitPriceFromUom(unit_price)
      console.log('UoM => UPDATING UNIT PRICE TO => ',  price)
      @item.unit_price = @round_money price

    calcUnitPriceFromUom: (unit_price)->
      console.log('UoM => We have product') if @product
      console.log('UoM => We have UoM') if @unit_of_measure
      return 0 unless @product or @unit_of_measure

      ratio = +@unit_of_measure.ratio
      console.log('UoM => Ratio', ratio)
      console.log('UoM => Relation', @unit_of_measure.relation)

      return 0 if ratio is 0

      switch @unit_of_measure.relation
        when 'bigger' then unit_price * ratio
        when 'smaller' then unit_price / ratio
        else unit_price

    # From Reference Unit
    calcQuantityInSelectedUom: (value)->
      return 0 unless @product or @unit_of_measure

      ratio = +@unit_of_measure.ratio

      return 0 if ratio is 0

      switch @unit_of_measure.relation
        when 'bigger' then value / ratio
        when 'smaller' then value * ratio
        else value


module.exports = UnitOfMeasureHandlers
