$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'

module.exports =
  template: """
    <select :name="name" class='rtl a-dropdown' ref='dropdown'>
      <option value='' v-if='insertBlankOption'></option>
      <option v-for="item in options" :value="getItemvalue(item)" :key="item.value">
        {{item.name}}
      </option>
    </select>
  """
  props: ['options', 'name', 'itemValue', 'value', 'placeholder', 'uid', 'includeBlank']
  data: ->
    dropdown: null
    currentItemValue: 'value'


  created: ->
    console.log 'DropDownComponent::created'
    console.log 'OPTIONS: ', @options
    eventHub.$on('refresh-dropdown', @refreshDropDown)


  mounted: ->
    Vue.nextTick =>
      @init_dropdown()


  destroyed: ->
    console.log 'DropDownComponent::destroyed'


  watch:
    value: (newID, oldID)->
      if newID != oldID
        console.log "DropDownComponent::watch value", newID
        @setSelected() if @dropdown


  methods:
    _el: -> @$el

    getItemvalue: (item)->
      @currentItemValue = @itemValue if @itemValue

      item[@currentItemValue]


    setSelected: ->
      console.log(@uid, '== UoM => Set value........', @value)
      @dropdown.dropdown('set selected', @value)

    refreshDropDown: (data)->
      options = data.units or data.options
      uid = data.uid

      return if uid != @uid

      console.log('== Refreshing Dropdown!........')
      if @dropdown
        Vue.nextTick =>
#          @dropdown.dropdown 'refresh'
          @dropdown.dropdown 'change values', options
          @dropdown.dropdown('set selected', @value)


    init_dropdown: ->
      console.log('== Initializing Dropdown!........')

      @dropdown = $(@$refs.dropdown)
      @dropdown.val(@value) # Initial value

      @dropdown.dropdown
        match: 'text'
        minCharacters: 2
        showOnFocus: true
        forceSelection: false
        selectOnKeydown: false
        placeholder: @placeholder
        onChange: (value, text, $choice)=>
          console.log('DropDownComponent::change => current: ', @value)
          console.log('DropDownComponent::change::to ', value, text)

          if parseInt(value) isnt @value
            console.log('DropDownComponent::change::emit ', value)
            @$emit('input', value)
          else
            console.log 'DropDownComponent::item_add:: no change'

      # Initial Setting id
      if @value
        console.log 'DropDownComponent::Initial id'
        @dropdown.dropdown('set selected', @value)


  computed:
    currentValue: -> null
    classList: -> {}

    insertBlankOption: -> if @includeBlank? then @includeBlank else true
