$ = require 'jquery'
Vue = require 'vue'
store = require '../stores/default.coffee'
eventHub = require '../event_hub.coffee'

VueBarcodeScanner = require 'vue-barcode-scanner'
CartNumericKeyboard = require './components/cart-numeric-keyboard.coffee'
PaymentScreen = require './components/payment-screen.coffee'
ReceiptScreen = require './components/receipt-screen.coffee'
Search = require './components/search.coffee'
Tabs = require './components/tabs.coffee'
ItemsComponent = require './components/items.coffee'

options = {
  sensitivity: 300,
  callbackAfterTimeout: false,
}
Vue.use(VueBarcodeScanner, options)


pos_ui = new Vue
  store: store
  data: ->
    is_ready: false
    cartKeyboardKey: null
    dragged: null
    show_payment: false
    show_receipt: false


  components:
    'breadcrumb': require('./components/breadcrumb.coffee')
    'cart': require('./components/cart.coffee')
    'cart-numeric-keyboard': CartNumericKeyboard
    'payment-screen': PaymentScreen
    'receipt-screen': ReceiptScreen
    'search': Search
    'tabs': Tabs
    'items-component': ItemsComponent



  created: ->
#    console.log 'POS UI ready'
    @$store.commit('setCurrentTabAsEmpty')

    @$barcodeScanner.init(@onBarcodeScanned)


  destroyed: ->
    @$barcodeScanner.destroy()


  watch:
    cartKeyboardKey: (key)->
      @cartPressKey(key)
      @cartKeyboardKey = null



  mounted: ->
    @$store.dispatch('POS_LOAD_CATEGORIES').then (data)=>
      console.info 'Categories loaded.'
      @$store.commit('addTab')
      @$store.dispatch('POS_LOAD_CAT_WITH_PRODUCTS', {cid: 349}).then (data)=>
        eventHub.$emit('pos-push-breadcrumb-category', { category: data })
        @$store.commit('set_current_category', {category: data})



  computed:
    total: -> @$store.getters.orderTotal
    current_tab: -> @$store.state.pos.current_tab
    current_category: -> @$store.state.pos.current_category
    current_cart_item: -> @$store.state.pos.current_tab.current_cart_item
    pos_session_id: -> @$store.state.pos.pos_session_id



  methods:
    closeSession: ->
      console.log('Closing Session!')
      window.location.href = "/pos/sessions/#{@pos_session_id}/close_pos"

    onBarcodeScanned: (code)->
      console.info("CODE: ", code)
      # @TODO: move this code to search component
#      @searchQuery = code


    cartPressKey: (key)->
      return unless @current_cart_item

      if key is 'DEL'
        @$store.commit('reduceCartItem', { by: 1 })
      else
        @$store.commit('increaseCartItem', { by: key })


    showPaymentScreen: -> @show_payment = true
    hidePaymentScreen: -> @show_payment = false

    showReceiptScreen: -> @show_receipt = true
    hideReceiptScreen: -> @show_receipt = false



el = '.pos--main-container'
pos_ui.$mount(el) if document.querySelector(el)
