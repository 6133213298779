import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect () {
    this.element.addEventListener("click", this.post.bind(this));
  }

  initialize() {
    if (!this.hasUrlValue) {
      // console.warn('NO URL DEFINED FOR PRINT ACTION!')
    }
  }

  post(e) {
    e?.preventDefault();

    const data = {ids: this.ids}
    this.post_records(data);
  }

  post_records(data) {
    console.log('POSTING')

    console.log('IDS: ', this.ids);
    console.log('URL: ', this.urlWithParams);

    fetch(this.url, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response.ok) {
          greenPOS.notify_success("All records posted to ledger.");
        }
      })
  }

  get urlWithParams() {
    return URI(this.url).addSearch('ids', this.ids.join(',')).toString();
  }

  get url() {
    return this.element.href;
  }

  get ids() {
    return Array.from(document.querySelectorAll('.fi-record'))
      .map((r)=> r.dataset.id);
  }

  disconnect() {
    this.element.removeEventListener("click", this.post);
  }
};
