$ = require 'jquery'
window.jQuery = $
_ = require 'lodash/core'
_groupBy = require 'lodash/groupBy'
Vue = require 'vue'
Big = require 'big.js'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <div class='ui label text store-name'>
      {{store_name}}
    </div>
  """
  props: ['stores_list', 'initial_store_name', 'render_type']
  emits: ['set_store_id', 'set_location_id']

  data: ->
    popup_el: null
    store_name: @initial_store_name
    hovered: false
    selected_id: null


  created: ->
    console.log '> Stores dropdown ready'

#    @$on('products-loaded', @initPopup)


  mounted: ->
    @popup_el = $(@$el)


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Stores dropdown!"

  watch:
    initial_store_name: ->
      @store_name = @initial_store_name

    stores_list: ->
      if @stores_list.length > 0
        Vue.nextTick(=> @initPopup())


  methods:
    set_store: (id)->
      @selected_id = id
      @$emit('set_store_id', id)
      @$emit('set_location_id', null)

    set_location: (id)->
      @selected_id = id
      @$emit('set_store_id', null)
      @$emit('set_location_id', id)

    update_stock_on_hand: (stock)->
      @$emit('stock_on_hand', stock)


    initPopup: ->
      return if @hovered

      vm = @
      popupLoading = '<i class="notched circle loading icon green"></i> wait...'

      @hovered = true

      @popup_el.popup
        inline: true
        on: 'click'
        exclusive: true
        hoverable: true
        position: 'bottom left'
        html: popupLoading
        variation: 'wide'
        lastResort: 'bottom right'
        delay:
          show: 400
          hide: 400
        onShow: (el)->
          vm.render_stores_popup(this)


    hide_popup: ->
      @popup_el.popup 'hide'


    render_stores_popup: (popup)->
      return if @stores_list.length is 0

      html = """
        <ul class='ui middle aligned selection list sales-order-stores-list'>
      """

      stores_list = _.filter @stores_list, (s)->
        round_quantity(s.filtered_stock_on_hand) > 0

      if @render_type is 'grouped'
        html += @render_grouped_stores_list(stores_list)
      else
        html += @render_all_stores(stores_list)

      html += "</ul>"
      popup.html(html)

      popup.on 'click', '.stores-list-item', @handle_store_item_click


    render_grouped_stores_list: (stores_list)->
      html = ''
      grouped_stores_list = _groupBy stores_list, 'location_id'

      _.each grouped_stores_list, (stores, location_id)=>

        # stores form current_location or with no location
        if +location_id is App.location_id
          html += @render_current_location(location_id, stores)

        else if location_id is "null"
          html += @render_shared_locations(stores)

        else if location_id isnt "null"
          html += @render_other_location(location_id, stores)

        else
          # Do nothing
      html


    render_all_stores: (stores)->
      @render_current_location(App.location_id, stores)


    handle_store_item_click: (e)->
      $item = e.currentTarget.querySelector('.store-name-in-list')

      location_id = $item.dataset.locationId
      store_id = $item.dataset.id

      @store_name = $item.dataset.name
      @update_stock_on_hand(round_quantity($item.dataset.soh))

      # Do nothing if clicked on current_location
      if +location_id is App.location_id
        @hide_popup()
        return

      if location_id
        @set_location(location_id)
      else
        @set_store(store_id)

      @hide_popup()



    calc_stores_quantity: (stores)->
      stores.reduce (sum, item)=>
        sum.plus(item.filtered_stock_on_hand)
      , Big(0)


    render_store_item: (store)->
      "<li class='clearfix stores-list-item'>
        <span class='store-name-in-list'
        data-id='#{store.store_id}'
        data-name='#{store.store_name}'
        data-soh='#{store.filtered_stock_on_hand}'>
          #{store.store_code or store.store_name}
        </span>
        <span class='ui label num qty-label qty-#{@_quantity_class(store)}'>
          #{@round_quantity(store.filtered_stock_on_hand)}
        </span>
      </li>"


    render_location_item: (location)->
      "<li class='clearfix stores-list-item'>
        <span class='store-name-in-list'
        data-location-id='#{location.location_id}'
        data-name='#{location.location_name}'
        data-soh='#{location.location_quantity}'>
          #{location.location_name}
        </span>
        <span class='ui label white num qty-label qty-'>
          #{@round_quantity(location.location_quantity)}
        </span>
      </li>"


    render_current_location: (location_id, stores)->
      html = ''
      filtered_stores = _.filter stores, (s)->
        +s.store_id in window.App.user_allowed_stores_ids
      filtered_stores = _.filter filtered_stores, (s)-> not s.defects

      if filtered_stores.length
        _.each filtered_stores, (store)=>
          html += @render_store_item(store)

      else
        location_quantity = @calc_stores_quantity(stores)

        location =
          location_id: location_id
          location_name: App.location_name
          location_quantity: location_quantity.toFixed(App.settings.quantity_round)

        html += @render_location_item(location)
      html

    render_shared_locations: (stores)->
      html = ''
      filtered_stores = _.filter stores, (s)->
        +s.store_id in window.App.user_allowed_stores_ids
      filtered_stores = _.filter filtered_stores, (s)-> not s.defects

      _.each filtered_stores, (store)=>
        html += @render_store_item(store)
      html

    render_other_location: (location_id, stores)->
      location_quantity = @calc_stores_quantity(stores)

      location =
        location_id: location_id
        location_name: window.App.locations[location_id]
        location_quantity: location_quantity.toFixed(App.settings.quantity_round)

      @render_location_item(location)


    _quantity_class: (item)->
      qty = round_quantity(item.quantity)

      reorder_quantity = item.reorder_quantity
      safty_quantity = item.safty_quantity
      max_quantity = item.max_quantity

      switch
        when qty is 0 then 'reorder_quantity'
        when qty < safty_quantity then 'lt-safty_quantity'
        when qty is safty_quantity then 'safty_quantity'
        when qty is reorder_quantity then 'reorder_quantity'
        when qty > reorder_quantity and qty < max_quantity then 'available'
        when qty is max_quantity then 'max_quantity'
        when qty > max_quantity then 'gt-max_quantity'
