import consumer from "./consumer";

// const products_cache = require('../products_caching.coffee');
const products_cache = window.ProductsCache;

consumer.subscriptions.create("NotificationsChannel", {

    connected() {
      console.log('NotificationsChannel::Connected');
    },
    disconnected() {},

    received(data) {
      console.log('Notifications :: DATA::', data);

      this.handle_sales_order_status_changes(data);
      this.handle_sales_order_other_status_changes(data);


      if (data.notification_type === 'system:invalidate_products_cache') {
        console.log(data.notification_type);
        const current_host = window.location.host;

        if (data.host && ['*', current_host].includes(data.host)) {
          console.warn('INVALIDATING DATA');
          products_cache.invalidateCache();
        }
      }

      if (data.notification_type === 'system:add_to_products_cache') {
        console.log(data.notification_type);
        products_cache.addToCache(data.product);
      }

      if (data.notification_type === 'system:update_products_cache') {
        console.log(data.notification_type);
        if (data.new_items.length) { products_cache.addToCache(data.new_items); }
        if (data.changed_items.length) { products_cache.updatedCachedItems(data.changed_items); }
        if (data.deleted_items.length) { products_cache.removeCachedItems(data.deleted_items); }
      }


      if (data.notification_type === 'sales_order_fulfillment::warehouse_shipment') {
        if (App.location_id === +data.location_id) {
          $('.warehouse-shipments-list tbody').append(data.html);
        }
      }

      if (data.notification_type === 'sales_order::warehouse_packaging') {
        if (App.location_id === +data.location_id) {
          $('.warehouse-packaging-list tbody').append(data.html);
        }
      }

      if (data.notification_type === 'sales_order::sales_orders') {
        if (App.location_id === +data.location_id) {
          $('.sales-orders-list tbody').prepend(data.html);
        }
      }

      if (data.notification_type === 'location_stock_transfer::change_status') {
        this.update_location_stock_transfer_status(data);
      }

      if (data.notification_type === 'sales_refunds::new_return') {
        if (App.location_id === +data.location_id) {
          $('.sales-refunds-list tbody').prepend(data.html);
        }
      }

      if (data.location_id && (App.location_id === +data.location_id)) {
        if (data.notification_type === 'sales_order_invoice::item_created') {
          $('.cashier-items-list tbody').append(data.html);
        }

        if (data.notification_type === 'sales_order_return::item_received') {
          $('.cashier-items-list tbody').append(data.html);
        }

        if (data.notification_type === 'sales_order_reservation::payment_created') {
          $('.cashier-items-list tbody').append(data.html);
        }

        if (data.notification_type === 'general_sales_return::item_received') {
          $('.cashier-items-list tbody').append(data.html);
        }
      }
    },

    status_class(status){
      switch (status) {
        case 'Draft': return 'yellow';
        case 'Active': return 'green';
        case 'Finalized': return 'blue';
        case 'Fulfilled': return 'purple';
        case 'Void': return 'red';
      }
    },

  fulfillment_class(status) {
    switch (status) {
      case 'shipped': return 'full';
      case 'partial_shipped': return 'partial';
      default: return 'empty';
    }
  },

  packed_class(status) {
    switch (status) {
      case 'packed': return 'full';
      case 'partial_packed': return 'partial';
      default: return 'empty';
    }
  },


    handle_sales_order_status_changes(data){
      if (data.notification_type === 'sales_order::change_status') {
        const status_el = `.so-status-label[data-so-id='${data.sales_order_id}']`;
        $(status_el)
          .addClass(this.status_class(data.status))
          .text(data.status);

        const $order_row = $(`#sales_order_${data.sales_order_id}`)
        $order_row
          .find('.so-extended-status.fulfillment')
          .find('.so-status-shape')
          .removeClass('empty partial full')
          .addClass(this.fulfillment_class(data.fulfillment_status));

        $order_row
          .find('.so-extended-status.packed')
          .find('.so-status-shape')
          .removeClass('empty partial full')
          .addClass(this.packed_class(data.packed_status));
      }
    },


    handle_sales_order_other_status_changes(data){
      let el_query;
      if (data.notification_type === 'sales_order_invoice::change_status') {
        el_query = `.soi-status-label[data-soi-id='${data.sales_order_invoice_id}']`;
        $(el_query)
          .removeClass('empty full')
          .addClass(data.status === 'paid' ? 'full' : 'empty');

        if (data.status === 'paid') {
          $(`#sales_order_invoice_${data.sales_order_invoice_id}`)
            .find('.btn-auto-pay')
            .addClass('js-disabled disabled');
        }
      }

      if (data.notification_type === 'sales_order_fulfillment::change_status') {
        el_query = `.sof-status-label[data-sof-id='${data.sales_order_fulfillment_id}']`;
        $(el_query)
          .removeClass('empty full')
          .addClass(data.status === 'shipped' ? 'full' : 'empty');

        if (data.status === 'shipped') {
          $(`#sales_order_fulfillment_${data.sales_order_fulfillment_id}`)
            .find('.btn-ship')
            .addClass('js-disabled disabled');
        }
      }

      if (data.notification_type === 'sales_order_return::change_status') {
        el_query = `.sor-status-label[data-sor-id='${data.sales_order_return_id}']`;
        $(el_query)
          .removeClass('empty full')
          .addClass(data.status === 'received' ? 'full' : 'partial');

        if (data.status === 'received') {
          $(`#sales_order_return_${data.sales_order_return_id}`)
            .find('.btn-received')
            .addClass('js-disabled disabled');
        }
      }
    },


    update_location_stock_transfer_status(data){
      // Update index/show status
      const id = data.location_stock_transfer_id;

      const status_tag = status => $('<span/>', {class: 'status-simple-label'}).data('status', status)
        .append($('<span/>', {class: `status-shape status-${status}`}))
        .append($('<span/>', {class: "status-label", text: status}));

      $(`#location_stock_transfer_${id}`)
        .find('.request_status').empty().append(status_tag(data.request_status))
        .end()
        .find('.receive_status').empty().append(status_tag(data.receive_status))
        .end()
        .find('.response_status').empty().append(status_tag(data.response_status));


      // update show status
      $(`.location_stock_ransfer_response_statuses_${id}`)
        .find('.active').removeClass('active').end()
        .find(`.${data.response_status}`).addClass('active');
    }
  }
);
