Vue = require 'vue'
_ = require 'lodash/core'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'


record_view = new Vue
  data: ->
    context: window.fi_transaction?.context
    description: window.fi_transaction?.description
    issued_at: window.fi_transaction?.issued_at
    reference: window.fi_transaction?.reference
    commercial_document_id: window.fi_transaction?.commercial_document_id
    commercial_document_total: window.commercial_document_total
    items: [] #window.transaction_items
    errors: []
    is_ready: true
    form_action: 'save' # 'save_and_new', 'save_and_export'
    tax_types: window.tax_types

  mixins: [formSubmitMixin]

  components:
    'datepicker': require('../components/simple_date_picker.coffee')
    'account-item': require('./components/account-item.coffee')


  created: ->
    eventHub.$on('removeItem', @removeItem)
    eventHub.$on('addEmptyItem', @addEmptyItem)

    if window.transaction_items
      Vue.set(@, 'items', window.transaction_items)

    if @items?.length is 0
      @addItem()
      @addItem()


  computed:
    count: -> @active_items.length

    active_items: ->
      @items.filter((item)=> not item._destroy and item.account_id)

    debit_items: ->
      @active_items.filter((item)=> round_number(item.debit_amount) > 0)

    credit_items: ->
      @active_items.filter((item)=> round_number(item.credit_amount) > 0)


    debits_total: ->
      t = _.reduce @debit_items, (sum, item)=>
        if @itemIsValid(item)
          sum.plus(Big(item.debit_amount).plus(item.tax_amount))
        else
          sum
      , Big(0)
      t.toFixed(3)


    credits_total: ->
      t = _.reduce @credit_items, (sum, item)=>
        if @itemIsValid(item)
          sum.plus(Big(item.credit_amount).plus(item.tax_amount))
        else
          sum
      , Big(0)
      t.toFixed(3)

    net_movement: ->
      @credits_total - @debits_total


    itemsIsValid: ->
      not _.some(@active_items, (i)=> not @itemIsValid(i))


    hasItems: ->
      @active_items.length > 1


    totalIsZero: ->
      @net_movement is 0


    isValidAttributes: ->
#      @description?
      @issued_at?


    creditsTotalIsValid: ->
      return true unless @commercial_document_id
      @commercial_document_total is parseFloat(@credits_total)


    isValid: ->
      @hasItems and @totalIsZero and @itemsIsValid and @creditsTotalIsValid and @isValidAttributes

    last_account_description: ->
      @active_items[@active_items.length - 1].description if @active_items.length >= 1


  methods:
    submitWithAction: (action, e)->
      return unless @isValid

      @form_action = action

      Vue.nextTick ->
        document.querySelectorAll('.js-btn-submit').forEach((el)=> el.classList.add('js-disabled'))
        document.querySelector('.fi-record-transaction-form').submit()

    addItem: (event)->
      item =
        id: null
        _destroy: null
        description: @last_account_description
        account_id: null
        debit_amount: 0
        credit_amount: 0
        issued_at: @issued_at
        tax_type_id: null
        tax_amount: 0
      @items.push item
      event and event.preventDefault()


    addEmptyItem: ()->
      console.log('EMPTY')
      last_item = _.last(@items)

      if last_item?.account_id?
        @addItem()


    removeItem: (data)->
      console.log('Remove Index', data.index)
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      credit = round_number(item.credit_amount)
      debit = round_number(item.debit_amount)
      ((credit > 0 and debit is 0) or (debit > 0 and credit is 0)) and item.account_id isnt null


el = document.querySelector('.fi-record-form-wrapper')
record_view.$mount(el) if el
