$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
_ = require 'lodash/core'

module.exports =
  template: """
    <tr class="form-field">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'purchase_order_return[items_attributes][' + _uid + '][id]'">

        <div class="fied">
          <input type="checkbox" v-model="item.selected" value="on"
            v-bind:name="'purchase_order_return[items_attributes][' + _uid + '][selected]'">
        </div>
      </td>
      <td>
        <div class="ui field item-name">{{ item.product_name }}</div>
        <input v-model="item.product_id"
          v-bind:name="'purchase_order_return[items_attributes][' + _uid + '][product_id]'"
          type='hidden'>

        <input v-model="item.purchase_order_item_id"
          v-bind:name="'purchase_order_return[items_attributes][' + _uid + '][purchase_order_item_id]'"
          type='hidden'>
      </td>

      <td>
        <div class="ui field" v-bind:class="{ 'error': !valid_quantity }">
          <input v-model.number="item.quantity"
            v-bind:name="'purchase_order_return[items_attributes][' + _uid + '][quantity]'"
            class="input-small item-quantity ltr">
        </div>
      </td>

      <td class='num' v-if='item.expires_at'>
        {{item.expires_at}}
        <input v-model="item.expires_at"
          v-bind:name="'purchase_order_return[items_attributes][' + _uid + '][expires_at]'"
          type='hidden'>
      </td>

      <td>
        <div class="not-returnd-quantity num">{{ not_returned_quantity }}</div>
      </td>

      <td>
        <div class="total-quantity num">{{ item.total_quantity }}</div>
      </td>

      <td class='text-start'>
        <div class="total num">{{ total }}</div>
      </td>
    </tr>
  """
  props: ['item']
  data: ->
    {}

  created: ->
    console.log 'I am ready'

  destroyed: ->
    console.log 'Destroyed ME'


  watch:
    'item.selected': (selected)->
      console.log 'SELECTED', selected

  computed:
    total: ->
      Big(round_quantity(@item.quantity))
        .times(round_number(@item.unit_price, 2)).toFixed(App.settings.money_round)

    not_returned_quantity: ->
      @item.not_returned_quantity - @item.quantity

    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0 and q <= @item.not_returned_quantity


    errors: ->
      quantity: @valid_quantity


    isValid: ->
      not _.some @errors, (v, k)-> v is false

