$.ajaxSetup({ cache: false });
// Backbone.emulateHTTP = true;
Q.longStackSupport = true;


/*
  Offline Options
*/
// Assume we are online when an ajax request was successful
Offline.confirmUp = Offline.markUp;
Offline.confirmDown = Offline.check;

Offline.options = {
  // Should we monitor AJAX requests to help decide if we have a connection.
  interceptRequests: true,
  // Should we store and attempt to remake requests which fail while the connection is down.
  requests: false
};
