products_store = require '../../products_store.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'
UnitOfMeasureHandlers = require '../../mixins/unit_of_measure_handlers.coffee'


module.exports =
  template: """
    <tr class="form-field">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'stock_adjustment[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'stock_adjustment[items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <select v-ui-dropdown="item.product_id"
            :name="'stock_adjustment[items_attributes][' + _uid + '][product_id]'"
            :data-uid="_uid"
            class='product-dropdown'></select>
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model.number="item.price"
            :name="'stock_adjustment[items_attributes][' + _uid + '][price]'"
            class="input-small item-price ltr">
        </div>
      </td>

      <td>
        <div class='two fields mb-0'>
          <div class="ui field">
            <input v-model.number="item.quantity"
              :name="'stock_adjustment[items_attributes][' + _uid + '][quantity]'"
              class="input-small item-quantity ltr">
          </div>
          <input type='hidden' v-model='item.unit_of_measure_id'
            :name="'stock_adjustment[items_attributes][' + _uid + '][unit_of_measure_id]'" />
          <span class="p-2 d-inline-block">{{product && product.unit_of_measure.name}}</span>
        </div>
      </td>

      <td>
        <div class="stock-on-hand num text-left">{{stock_on_hand}}</div>
      </td>

      <td>
        <div class="stock-after-change num text-left">{{stock_after_change}}</div>
      </td>

      <td>
        <div class="text-start">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  mixins: [UnitOfMeasureHandlers]

  props: ['item', 'index', 'store_id', 'reason']
  data: ->
    product: null
    product_id: null
    stores_list: []


  created: ->
    console.log 'I am ready'
    eventHub.$on('change-product-id', @change_product_id)


  renderError: (h, err)->
    # h('pre', { style: { color: 'red' }}, err.stack)
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    get_product: (product_id)->
      products_store.fetchItem(product_id)

    change_product_id: (data)->
      if data.uid == @_uid
        @item.product_id = data.id

    removeItem: (index)->
      eventHub.$emit('remove-item', { index: index })


    update_item_data: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @stores_list = @product.stores_list
        @setDefaultUoM(@product.unit_of_measure)

      , (response)->
        console.error response




  watch:
    'item.product_id': (id)->
      console.log('product id', id)
      @update_item_data()

    reason: (n, o)->
      console.log 'New Reason', n
      # @item.quantity = @quantity_with_sign()


    store_id: (n, o)->
      console.log 'New Store', n


  computed:
    price: ->
      return 0 unless @product
      round_number @product.cost_price, 2


    stock_on_hand: ->
      stock = _.find @stores_list, {store_id: +@store_id}
      if stock
        round_quantity(stock.quantity)
      else
        0


    quantity_with_sign: ->
      additions = ['supplier', 'customer', 'production']
      subtractions = ['damaged', 'shrinkage', 'promotion', 'stocktake']
      quantity = round_quantity @item.quantity

      if @reason in additions
        if quantity > 0 then quantity else -quantity
      else if @reason in subtractions
        if quantity > 0 then -quantity else quantity
      else
        quantity


    stock_after_change: ->
      stock_on_hand = round_quantity @stock_on_hand
      quantity = round_quantity @item.quantity

      Big(stock_on_hand).plus(@quantity_with_sign)
        .toFixed(App.settings.money_round)

