_ = require 'lodash/core'
$ = require 'jquery'
Immutable = require 'immutable'

pos =
  namespaced: false

  state: {
    tabs: Immutable.OrderedMap(),
    current_tab: {},
    current_tab_id: null,

    sell_policy_items: window.sell_policy_items,

    empty_tab: {
      id: null,
      date: null,
      pos_category: null,
      pos_category_children: [],
      pos_category_products: [],
      current_category: null,
      current_breadcrumb_item: null,
      current_cart_item: null,
      cart_items: Immutable.OrderedMap(),
      client_id: window.default_client_id,
      paid_amount: 0,
      status: 'draft',
      order: {},
      order_discount_percent: 0,
      formatted_date: null,
      payment_method: null, #'cash',
      credit_card_id: null
    },

    pos_session_id: window.pos_session_id,
    pos_id: window.pos_id,

    saving_order: false
  },

  actions: {
    POS_LOAD_CATEGORIES: (context)->
      url = "/api/product_categories/list_root?branch_id=#{App.branch_id}"
      Q($.get(url)
        .done((response)->
          context.commit('set_root_categories', { cats: response })
        ).fail(->
          console.warn('FAILED URL', url)
        )
      )

    POS_LOAD_CAT_WITH_PRODUCTS: (context, data)->
      url = "/api/product_categories/#{data.cid}?branch_id=#{App.branch_id}&pos_id=#{context.state.pos_id}"
      Q($.get(url)
        .done((response)->
          context.commit('set_pos_category', { category: response })
          context.commit('SET_POS_CATEGORY_CHILDREN', { children: response.children })
          context.commit('SET_POS_PRODUCTS', { products: response.products })
        ).fail(->
          console.warn('FAILED URL', url)
        )
      )


    saveCart: (context, data)->
      url = "/pos/orders"
      data = {
        pos_order: {
          pos_session_id: window.pos_session_id,
          client_id: context.state.current_tab.client_id,
          items_attributes: context.getters.cartItemsAsArray,
          discount_amount: context.getters.orderDiscount.valueOf(),
          total: context.getters.orderTotal,
          tax_amount: context.getters.totalTaxes.valueOf(),
          paid_amount: context.state.current_tab.paid_amount,
          change_amount: context.getters.change_amount,
          payment_method: context.state.current_tab.payment_method,
          credit_card_id: context.state.current_tab.credit_card_id
        }
      }

      console.log "SAVING DATA::", data

      context.state.saving_order = true

      Q($.ajax({
          method: 'POST',
          data: data,
          url: url,
        }).done((response)->
          console.log("SaveCart Response", response)

          context.state.saving_order = false

          if response.ok
            context.commit('changeCurrentOrderStatus')
            context.commit('assignOrderToCurrentTab', {order: response.order, formatted_date: response.formatted_date})
          else
            console.log('ERROR: SAVING ORDER')
            console.log(response.error)
#            Q.reject(response.error)
#            throw response.error


        ).fail(->
          console.log("Saving Cart Failed!")
          context.state.saving_order = false
        )
      )


    searchProducts: (context, data)->
      url = "/api/pos/products?branch_id=#{App.branch_id}&q=#{data.query}"
      Q($.get(url)
        .done((response)->
          context.commit('SET_POS_PRODUCTS', {products: response.products})
        ).fail(->
        console.warn('FAiled Loading Products')
        )
      )

    removeTab: (context, data)->
      tab_id = data?.tab_id ? context.state.current_tab_id
#      console.info "Removing tab ", tab_id
      context.commit('removeTab', { tab_id: +tab_id })
      last_tab = context.state.tabs.last()

      if last_tab
        context.commit('setCurrentTab', { tab_id: last_tab.id })
      else
        context.commit('addTab')


    fetchClient: (context, data)->
      url = '/api/clients/' + context.state.current_tab.client_id

      Q($.get(url).done((response)->
        json = response
        console.log('Client', json.client)
        console.log('Segment', json.segment)
        if json.segment
          context.commit('setOrderDiscountPercent', json.segment.discount_percentage)
        ).fail(->
          console.warn('FAILED URL', url)
        )
      )
  },
  mutations: {
    addTab: (state)->
      # Get tab id from tabs count
      tab_id = state.tabs.count() + 1

      tab = {
        id: tab_id,
        pos_category: null,
        pos_category_children: state.pos_root_categories,
        pos_category_products: [],
        current_category: null,
        current_breadcrumb_item: null,
        current_cart_item: null,
        cart_items: Immutable.OrderedMap(),
        client_id: window.default_client_id,
        paid_amount: 0,
        date: new Date().toISOString(),
        status: 'draft',
        order: {},
        order_discount_percent: 0,
        formatted_date: null,
        payment_method: null # 'cash'
      }

      state.tabs = state.tabs.set(tab_id, tab)

      # Set as current tab
      state.current_tab_id = tab_id
      state.current_tab = tab


    removeTab: (state, data)->
      state.tabs = state.tabs.delete(data.tab_id)


    setCurrentTab: (state, data)->
      state.current_tab_id = data.tab_id
      state.current_tab = state.tabs.get(data.tab_id)


    setCurrentTabAsEmpty: (state)->
      state.current_tab = state.empty_tab


    changeCurrentOrderStatus: (state)->
      state.current_tab.status = 'finalized'


    assignOrderToCurrentTab: (state, data)->
      state.current_tab.order = data.order
      state.current_tab.formatted_date = data.formatted_date


    set_root_categories: (state, data)->
      state.pos_root_categories = data.cats


    set_pos_category: (state, data)->
      state.current_tab.pos_category = data.category


    SET_POS_CATEGORY_CHILDREN: (state, data)->
      state.current_tab.pos_category_children = data.children


    set_category_children_as_root: (state, data)->
      state.current_tab.pos_category_children = state.pos_root_categories


    SET_POS_PRODUCTS: (state, data)->
      state.current_tab.pos_category_products = data.products


    set_current_category: (state, data)->
      state.current_tab.current_category = data.category


    set_current_breadcrumb_item: (state, data)->
      state.current_tab.current_breadcrumb_item = data.category


    setPosClient: (state, data)->
      state.current_tab.client_id = data.id

    setPaidAmount: (state, data)->
      state.current_tab.paid_amount = data.amount

    setPaymentMethod: (state, method)->
      state.current_tab.payment_method = method

    setCreditCard: (state, id)->
      state.current_tab.credit_card_id = id

    setOrderDiscountPercent: (state, percent)->
      state.current_tab.order_discount_percent = percent


    addToCart: (state, data)->
      product = data.item
      return unless product

      current_item = state.current_tab.cart_items.get(product.id)

      # check if we have an item in cart
      # if we have increase quantity
      # else add new item

      if current_item
        # check if stock is available
        if current_item.quantity is +product.stock_quantity
          greenPOS.notify_error('Stock is not enough.')
        else
          current_item.quantity += 1

      else
        current_item = {
          product_id: product.id
          product_name: product.name
          retail_price: product.retail_price
          quantity: 1
          discount_amount: 0
          vat: product.vat
          vat_amount: 0
          item: product
        }


      # product_id || categories_ids || products_ids
      matched_policy = state.sell_policy_items.find (item)->
        (item.product_id is product.id) or (item.categories_ids.includes(product.product_category_id)) or (item.products_ids.includes(product.id))
      discount_percent = matched_policy?.percent_discount or 0

      console.log('FOUND MATCH: ', matched_policy)
      console.log('FOR: ', product.id, product.product_category_id)

      sub_total = Big(product.retail_price).times(current_item.quantity)
      discount_amount = sub_total.times(discount_percent).div(100)
      current_item.discount_amount = discount_amount.valueOf()

      # Vat Inclusive [Vat included in product price)
      vat_factor = (Big(product.vat).div(100)).plus(1)

      sub_total_after_discount = sub_total.minus(discount_amount)
      sub_total_without_vat = sub_total_after_discount.div(vat_factor)
      vat_amount = sub_total_after_discount.minus(sub_total_without_vat)

      current_item.vat_amount = vat_amount.valueOf()

      state.current_tab.current_cart_item = current_item
      state.current_tab.cart_items = state.current_tab.cart_items.set product.id, current_item


    increaseCartItem: (state, data)->
      item = state.current_tab.current_cart_item
      item.quantity += data.by
      state.current_tab.cart_items = state.current_tab.cart_items.set item.product_id, item


    reduceCartItem: (state, data)->
      item = state.current_tab.current_cart_item

      # reduce quantity
      item.quantity -= data.by

      if item.quantity > 0
        state.current_tab.cart_items = state.current_tab.cart_items.set item.product_id, item

      else
        # remove item
        state.current_tab.cart_items = state.current_tab.cart_items.delete item.product_id
        # set current item to last item
        state.current_tab.current_cart_item = state.current_tab.cart_items.last()



    selectCartItem: (state, data)->
      state.current_tab.current_cart_item = state.current_tab.cart_items.get data.product_id

  },

  getters: {
    remaining_amount: (state, getters)->
      Big(state.current_tab.paid_amount).minus(getters.orderTotal).toFixed(2)


    change_amount: (state, getters)->
      Math.abs(getters.remaining_amount)


    orderSubTotal: (state)->
      items = state.current_tab.cart_items

      items.reduce (sum, item)->
        retun sum if item?.quantity is 0

        quantity = Big(item.quantity)
        sum.plus(quantity.times(item.retail_price).minus(item.discount_amount))

      , Big(0)

    orderDiscount: (state, getters)->
      sub_total = getters.orderSubTotal
      discount_percent = state.current_tab.order_discount_percent
      sub_total.times(discount_percent).div(100)

    orderTotal: (state, getters)->
      sub_total = getters.orderSubTotal
      total = sub_total.minus(getters.orderDiscount)
      total.toFixed(App.settings.money_round)

    amountDue: (state, getters)->
      getters.orderSubTotal.toFixed(App.settings.money_round)

    totalTaxes: (state, getters)->
      items = state.current_tab.cart_items

      tax = items.reduce (sum, item)->
        retun sum if item?.vat_amount is 0

        sum.plus(item.vat_amount)

      , Big(0)

      discount_percent = Big(state.current_tab.order_discount_percent)
      after_discount_percent = Big(1).minus(discount_percent.div(100))
      tax.times(after_discount_percent)


    cartItemsAsArray: (state)->
      state.current_tab.cart_items.toSet().toArray().map (item)->
        {
          product_id: item.product_id,
          product_name: item.product_name,
          quantity: item.quantity,
          unit_price: item.retail_price,
          discount_amount: item.discount_amount,
          vat: item.vat,
          total: Big(item.quantity ? 0).times(item.retail_price).minus(item.discount_amount).toFixed(2)
        }

    matched_sell_policy_item: (state)-> (product_id, category_id) ->
      # product_id || categories_ids || products_ids
      match = state.sell_policy_items.find (item)->
        (item.product_id is product_id) or (item.categories_ids.include(category_id)) or (item.products_ids.include(product_id))
      console.log('FOUND MATCH: ', match)
      console.log('FOR: ', product_id, category_id)
      match
  }

module.exports = pos
