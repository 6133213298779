$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'


clearance =
  data: ->
    id: window.clearance.id
    name: window.clearance?.name
    contact_gid: window.contact_gid
    issued_at: window.clearance?.issued_at
    sub_title: window.clearance?.sub_title
    contractor: window.clearance?.contractor
    advisor: window.clearance?.advisor
    tag_list: window.clearance?.tag_list
    items: window.items || []
    advisor_employees: window.clearance?.advisor_employees
    contractor_employees: window.clearance?.contractor_employees
    is_ready: false

  components:
    'item': require('./clearance-item.coffee')
    'employee-item': require('./clearance-employee.coffee')


  created: ->
    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('remove-employee', @removeEmployee)

    @items.push(@emptyItem('section')) unless @id

    if @advisor_employees.length == 0
      @advisor_employees.push({name: null, job: null})

    if @contractor_employees.length == 0
      @contractor_employees.push({name: null, job: null})


  computed:
    items_count: -> @items.length

    itemsIsValid: ->
      not @items.some (i)=> not @itemIsValid(i)

    hasItems: ->
      @items_count.length > 0


    isValidAttributes: ->
      @name?

    isValid: ->
      @isValidAttributes and @hasItems
      true


  methods:
    emptyItem: (type)-> {
      item_type: type,
      item_no: '',
      description: '',
      unit: '',
      contract_quantity: 0,
      unit_price: 0,
      prev_quantity: 0,
      current_quantity: 0,
      total_quantity: 0,
      total_amount: 0,
      notes: ''
    }

    addItem: (event)->
      @items.push @emptyItem('normal')
      event and event.preventDefault()

    addSection: (event)->
      @items.push @emptyItem('section')
      event and event.preventDefault()

    addContractorEmployee: (event) ->
      @contractor_employees.push({name: null, job: null})
      event and event.preventDefault()

    addAdvisorEmployee: (event) ->
      @advisor_employees.push({name: null, job: null})
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)

    removeEmployee: (data)->
      console.log('DATA', data)
      this[data.type].splice(data.index, 1)


    itemIsValid: (item)->
      item.description?


  events:
    'remove-item': (index)-> @removeItem(index)


el = document.querySelector('.project-clearance-form-wrapper')
new Vue(clearance).$mount(el) if el

