$ = require 'jquery'
Vue = require 'vue'

eventHub = require '../event_hub.coffee'


module.exports =
  template: """
   <input type='text' :name="name"
    class='simple-date-picker' data-controller='datepicker' autocomplete='off'/>
  """
  props: ['name', 'value', 'date_format']
  data: ->
    datepicker: null


  created: ->
    console.log 'SimpleDatePickerComponent::created'


  mounted: ->
    Vue.nextTick => @init_datepicker()


  destroyed: ->
    console.log 'SimpleDatePickerComponent::destroyed'


#  watch:
#    value: (newDate, oldDate)->
#      if newDate != oldDate
#        console.log "SimpleDatePickerComponent::change date", newDate
#        @datepicker.datepicker('update', newDate)


  methods:
    _el: -> @$el

    default_date_format: -> 'yyyy-mm-dd'

    init_datepicker: ->
      $el = $(@_el())
      @datepicker = $el

      $el.datepicker(
        format: @date_format or @default_date_format()
        weekStart: 6
        todayBtn: false
        todayHighlight: true
        language: 'ar'
        startView: 0

      ).on 'changeDate', (e)=>
        @datepicker.datepicker('hide')

        console.log 'SimpleDatePickerComponent changeDate', e.date

        @$emit('input', e.format())


      if @value
        console.log 'SimpleDatePickerComponent::initial date', @value
        @datepicker.datepicker('update', @value)
