$ = require 'jquery'
Selectize = require 'selectize'
Vue = require 'vue'

store = require '../stores/default.coffee'
# products_store = require '../products_store.coffee'
products_cache = require '../../products_caching.coffee'

eventHub = require '../event_hub.coffee'

Vue.directive 'simple-products-dropdown',
  params: ['products']

  inserted: (el)->
    console.log 'SimpleProductsDD::INSERTED', el
    console.log "PLENGHT: ", products_cache.products.length

  # componentUpdated: (el)->
  #   console.log 'SimpleProductsDD Component Updated!'

  bind: (el, binding, vnode)->
    # console.log "ITEMS inside directive", products_store.items.length
    # console.log 'EL', el, binding

    Vue.nextTick ->
      $dropdown = $(el).selectize
        plugins: ['dropdown_direction']
        dropdownDirection: 'auto'
        highlight: false
        valueField: 'id'
        labelField: 'name'
        maxOptions: 50
        options: products_cache.products #store.state.products
        searchField: ['name', 'sku', 'brand', 'tags', 'ean_code']
        searchConjunction: 'and'
        sortField: [
          {field: 'name', direction: "asc"},
        ]

        render:
          option: (item, escape)->
            "<div>" +
            "<span class='xpname'>#{escape(item.name)}</span>" +
            "</div>"

      $dropdown = $dropdown[0].selectize

      vnode.context.dropdown = $dropdown

      $dropdown.on 'item_add', (val, $item)->
        console.log 'SimpleProductsDD::item_add', val

        if parseInt(val) isnt vnode.context.product_id
          eventHub.$emit(
            'change-product-id',
            { id: val, uid: $(el).data('uid')}
          )

        else
          console.log 'SimpleProductsDD: item_add : same id'

      if vnode.context.product_id
        console.log 'SimpleProductsDD::add from directive'
        $dropdown.addItem(vnode.context.product_id, true) # silent


  update: (el, binding, vnode)->
    if binding.value != binding.oldValue
      console.log "ProductsDD:: update", binding.value
      el.selectize.addItem(binding.value)

  unbind: (el)->
    console.log "unbind"
    el.selectize.destroy()

