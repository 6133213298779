$ = require 'jquery'
Vue = require 'vue'

module.exports =
  template: """
    <select :name="name" class='rtl ui-dropdown ui search dropdown' ref='dropdown'>
      <option value=''>{{prompt}}</option>
      <option v-for="o in options" :value="o.value" :key="o.value">
        {{o.name}}
      </option>
    </select>
  """
  props: ['options', 'prompt', 'name', 'value']
  data: ->
    dropdown: null


  created: ->
    console.log "#{@name}::DropDownComponent::created"


  mounted: ->
    Vue.nextTick =>
      @init_dropdown()


  destroyed: ->
    console.log "#{@name}::DropDownComponent::destroyed"


  watch:
    value: (newID, oldID)->
      if newID != oldID
        console.log "#{@name}::DropDownComponent::change id", newID
        if @dropdown
          @dropdown.dropdown('set selected', newID)


  methods:
    _el: -> @$el

    init_dropdown: ->
      @dropdown = $(@$refs.dropdown)
      @dropdown.dropdown
        match: 'text'
        minCharacters: 2
        showOnFocus: true
        forceSelection: false
        selectOnKeydown: false
        placeholder: @prompt
        onChange: (value, text, $choice)=>
          console.log(value, text, $choice)
          if parseInt(value) isnt @value
            @$emit('input', value)
          else
          console.log "#{@name}::DropDownComponent: item_add : same id"

      # Initial Setting product id
      if @value
        console.log "#{@name}::DropDownComponent::Initial Setting for account_id"
        @dropdown.dropdown('set selected', @value)


  computed:
    classList: -> {}
