$ = require 'jquery'
window.jQuery = $
Vue = require 'vue'
Big = require 'big.js'
_ = require 'lodash/core'
_groupBy = require 'lodash/groupBy'
products_store = require '../../products_store.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'


# @mouseenter="bind_product_popup"

module.exports = Vue.extend
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'sales_order_fulfillment[items_attributes][' + _uid + '][id]'">

        <input v-if="item.source_item_id" type="hidden" v-model="item.source_item_id"
          :name="'sales_order_fulfillment[items_attributes][' + _uid + '][source_item_id]'">


        <div class="fied">
          <input type="checkbox"
            v-model="item.selected" value="on"
            :name="'sales_order_fulfillment[items_attributes][' + _uid + '][selected]'">
        </div>
      </td>
      <td>
        <div class="ui field item-name">{{ item.product_name }}</div>
        <input type="hidden"
          v-model="item.sales_order_item_id"
          :name="'sales_order_fulfillment[items_attributes][' + _uid + '][sales_order_item_id]'"
          class="input-small">
      </td>

      <td v-if="!item.id">
        <div class="select-store-wrapper">
          <div class='ui label text store-name'
            :class="{'blue': store_or_location_is_present}"
            @click="bind_product_popup"> {{store_name}} </div>
          <input type="hidden" v-model="item.store_id"
          :name="'sales_order_fulfillment[items_attributes][' + _uid + '][store_id]'">

          <input v-if="!item.source_item_id" type="hidden" v-model="item.location_id"
          :name="'sales_order_fulfillment[items_attributes][' + _uid + '][location_id]'">
        </div>
      </td>

      <td>
        <div class='two fields m0'>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="'sales_order_fulfillment[items_attributes][' + _uid + '][quantity]'"
            @keydown.enter.prevent
            @keydown.up="prevItem"
            @keydown.down="nextItem"
            class="text-center item-quantity ltr">
        </div>
        <span class="p-2 d-inline-block">{{ item.uom_name }}</span>
        </div>
      </td>

      <td>
        <div class="num text-center">{{ not_packed_quantity }}</div>
      </td>

      <td>
        <div class="total-quantity num text-center">{{ round_quantity(item.total_quantity) }}</div>
      </td>
    </tr>
  """
  props: ['item', 'pack_store_id', 'pack_location_id']
  mixins: [inputKeyHandlers]
  data: ->
    hovered: false
    store_name: "اختر مخزن"
    stores_list: []
    stock_on_hand: 0

#  created: ->
#    console.log 'I am ready'

  # mounted: ->
  #   # do nothing

  destroyed: ->
    console.log 'Destroyed ME'


  watch:
    'item.selected': (selected)->
      # console.log 'SELECTED', selected
      # console.log 'SELECTED : product', @item.product_id

    'isValid': (nVal, oVal)->
      @item.valid = nVal


  computed:
    not_packed_quantity: ->
      @item.not_packed_quantity - @item.quantity

    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0 and q <= @item.not_packed_quantity


    valid_store_or_location: ->
      @item.store_id != "" or @item.location_id != ""


    stock_is_enough: ->
      if @item.store_id? or @item.location_id?
        @round_quantity(@stock_on_hand) >= @round_quantity(@item.quantity)
      else
        true


    errors: ->
      quantity: @valid_quantity
      store_or_location: @valid_store_or_location
      stock_is_enough: @stock_is_enough


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    store_or_location_is_present: ->
      @item.store_id? || @item.location_id?


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["sof-item"]      = true
      list["item-#{@_uid}"] = true
      list


  methods:
    render_store_item: (store)->
      "<li class='clearfix stores-list-item'>
      <span class='store-name-in-list'
      data-id='#{store.store_id}'
      data-name='#{store.store_name}'
      data-soh='#{store.filtered_stock_on_hand}'>
        #{store.store_code or store.store_name}
      </span>
      <span class='ui label num qty-label qty-#{@_quantity_class(store)}'>
        #{@round_quantity(store.filtered_stock_on_hand)}
      </span>
      </li>"


    render_location_item: (location)->
      "<li class='clearfix stores-list-item'>
      <span class='store-name-in-list'
      data-location-id='#{location.location_id}'
      data-name='#{location.location_name}'
      data-soh='#{location.location_quantity}'>
        #{location.location_name}
      </span>
      <span class='ui label color-black num qty-label qty-'>
        #{@round_quantity(location.location_quantity)}
      </span>
      </li>"


    render_stores_popup: (popup, $popup_el)->
      vm = this

      products_store.fetchItem(vm.item.product_id).then (product)->
        vm.stores_list = product.stores_list

        return if vm.stores_list.length is 0

        html = """
          <ul class='ui middle aligned selection list sales-order-stores-list'>
        """

        stores_list = vm.stores_list.filter (s)->
          round_quantity(s.filtered_stock_on_hand) > 0

        grouped_stores_list = _groupBy stores_list, 'location_id'

        _.each grouped_stores_list, (stores, location_id)->
          # stores for current_location and no location
          if +location_id is App.location_id
            filtered_stores = _.filter stores, (s)->
              +s.store_id in window.App.user_allowed_stores_ids

            filtered_stores = _.filter filtered_stores, (s)-> not s.defects

            if filtered_stores.length
              _.each filtered_stores, (store)->
                html += vm.render_store_item(store)

            else
              location_quantity = _.reduce stores, (sum, item)->
                sum.plus(item.filtered_stock_on_hand)
              , Big(0)

              location =
                location_id: location_id
                location_name: App.location_name
                location_quantity: location_quantity.toFixed(App.settings.quantity_round)

              html += vm.render_location_item(location)

          else if location_id is "null"
            filtered_stores = _.filter stores, (s)->
              +s.store_id in window.App.user_allowed_stores_ids

            filtered_stores = _.filter filtered_stores, (s)-> not s.defects

            _.each filtered_stores, (store)->
              html += vm.render_store_item(store)

          else if location_id isnt "null" and not vm.item.source_item_id? # for subpacks
            location_quantity = _.reduce stores, (sum, item)->
              sum.plus(item.filtered_stock_on_hand)
            , Big(0)

            location =
              location_id: location_id
              location_name: window.App.locations[location_id]
              location_quantity: location_quantity.toFixed(App.settings.quantity_round)

            html += vm.render_location_item(location)

          else
            # Do nothing

        html += "</ul>"
        popup.html(html)

        popup.on 'click', '.stores-list-item', (e)->
          $this = $(@).find('.store-name-in-list')

          location_id = $this.data('locationId')

          # Do nothing if clicked on current_location
          if +location_id is App.location_id
            $popup_el.popup 'hide'
            return

          if location_id
            vm.$set vm.item, 'location_id', location_id
            vm.$set vm.item, 'store_id', null
          else
            vm.$set vm.item, 'store_id', $this.data('id')
            vm.$set vm.item, 'location_id', null

          vm.store_name = $this.data('name')
          vm.stock_on_hand = round_quantity($this.data('soh'))
          $popup_el.popup 'hide'

      , (response)->
        console.error "Can not load Data!"
        popup.html('error');


    bind_product_popup: ->
      return if @hovered

      vm = @
      popupLoading = '<i class="notched circle loading icon green"></i> wait...'
      $el = $(vm.$parent.$el).find(".item-#{@_uid}")
      return unless $el.length
      $popup_el = $el.find('.store-name')

      @hovered = true

      $popup_el.popup
        inline: true
        on: 'click'
        exclusive: true
        hoverable: true
        position: 'bottom left'
        html: popupLoading
        variation: 'wide'
        lastResort: 'bottom right'
        delay:
          show: 400
          hide: 400
        onShow: (el)->
          vm.render_stores_popup(this, $popup_el)

      $popup_el.popup 'show'


    _quantity_class: (item)->
      qty = round_quantity(item.filtered_stock_on_hand)

      reorder_quantity = item.reorder_quantity
      safty_quantity = item.safty_quantity
      max_quantity = item.max_quantity

      switch
        when qty is 0 then 'reorder_quantity'
        when qty < safty_quantity then 'lt-safty_quantity'
        when qty is safty_quantity then 'safty_quantity'
        when qty is reorder_quantity then 'reorder_quantity'
        when qty > reorder_quantity and qty < max_quantity then 'available'
        when qty is max_quantity then 'max_quantity'
        when qty > max_quantity then 'gt-max_quantity'

