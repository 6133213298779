eventHub = require '../../event_hub.coffee'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <div class="ui field">
          <input  v-model="item.id"
            :name="'hr_conflict_incident[' + attr_name + '][' + _uid + '][id]'"
             placeholder="id"
            class="item-item_no">
        </div>
      </td>
      <td>
        <div class="ui field">
          <input  v-model="item.name"
            :name="'hr_conflict_incident[' + attr_name + '][' + _uid + '][name]'"
             placeholder="Name"
            class="item-item_no">
        </div>
      </td>
      <td colspan='8'>
        <div class="ui field">
          <input v-model="item.address"
            :name="'hr_conflict_incident[' + attr_name + '][' + _uid + '][address]'"
             placeholder="address"
            class="font-weight-500 fs-5 item-address">
        </div>
      </td>
      <td>
        <div class="ui field">
          <input  v-model="item.phone"
            :name="'hr_conflict_incident[' + attr_name + '][' + _uid + '][phone]'"
            placeholder="phone"
            class="item-item_no">
        </div>
      </td>
      <td>
        <div class="ui field">
          <input  v-model="item.other"
            :name="'hr_conflict_incident[' + attr_name + '][' + _uid + '][other]'"
            placeholder="other"
            class="item-item_no">
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>

  """
  props: ['item', 'index', 'attr_name']
#  data: ->
#    logo: null


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    removeItem: (index)->
      eventHub.$emit('remove-person', { index: index, type: @attr_name })


  computed:
    isValid: ->
      true

    classList: ->
      list = {}
      list['error']         = not @isValid
      list["item-#{@_uid}"] = true
      list

