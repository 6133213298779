eventHub = require '../../event_hub.coffee'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'credit_memo[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'credit_memo[items_attributes][' + _uid + '][id]'">

        <div class="ui field primary-color">
          <input v-model.number="item.amount"
            :name="'credit_memo[items_attributes][' + _uid + '][amount]'"
            class="item-amount ltr">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.description"
            :name="'credit_memo[items_attributes][' + _uid + '][description]'"
            class="item-description">
        </div>
      </td>
      <td>
        <div class="ui field">
          <input v-model="item.notes"
            :name="'credit_memo[items_attributes][' + _uid + '][notes]'"
            class="item-notes">
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']
  data: ->
    amount: 0
    description: null


  created: ->
    console.log ''


  renderError: (h, err)->
    # h('pre', { style: { color: 'red' }}, err.stack)
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    removeItem: (index)->
      if @item.id
        @$set(@item, '_destroy', true)
      else
        eventHub.$emit('remove-item', { index: index })


  computed:
    isValid: ->
      true

    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list

