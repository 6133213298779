$ = require 'jquery'
window.jQuery = $

_ = require 'lodash/core'
_groupBy = require 'lodash/groupBy'

Vue = require 'vue'
Big = require 'big.js'
products_store = require '../../products_store.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
UnitOfMeasureHandlers = require '../../mixins/unit_of_measure_handlers.coffee'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type='hidden' v-model='item.context'
            :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][context]'" />

        <input type="hidden" v-model="item.id"
          :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="item._destroy"
          :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][_destroy]'">

        <input type="hidden" v-model="item.product_id"
          :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][product_id]'">

        <div class="ui field">
          {{item.product_name}}
        </div>
      </td>

      <td>
        <div class="select-store-wrapper">
          <div class='ui label text store-name'> {{store_name}} </div>
          <input type="hidden" v-model="item.store_id"
          :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][store_id]'">
        </div>
      </td>

      <td>
        <input type="hidden" v-model="item.quantity"
          :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][quantity]'">

        <div class="ui field">
          <span class="num">{{round_quantity(item.quantity)}}</span>
          <input type='hidden' v-model='item.unit_of_measure_id'
            :name="'location_stock_transfer[receivable_items_attributes][' + _uid + '][unit_of_measure_id]'" />
          <span class="p-2 d-inline-block">{{product && product.unit_of_measure.name}}</span>
        </div>
      </td>
    </tr>
  """
  props: ['index', 'item']
  mixins: [inputKeyHandlers, UnitOfMeasureHandlers]
  store: store

  data: ->
    product: null
    duplicated: false
    dropdown: null
    store_name: "اختر مخزن"
    stores_list: []
    allowed_stores_list: window.user_allowed_stores
    hovered: false


  created: ->
    console.log 'I am ready'
    eventHub.$on('duplicated-items', @viewErrorHandler)
    eventHub.$on('scroll_to_current_element', @scroll_to_current_element)

    # fetch data
    @getProductData() #if @item.id


  destroyed: ->
    console.log 'Destroyed ME'

  methods:
    _el: -> $(".item-#{@_uid}")

    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    get_product: (product_id)->
      products_store.fetchItem(product_id)


    filtered_store_stock_on_hand: (product, quantity)->
      inviolable_stock = quantity * product.inviolable_stock_percent / 100
      round_quantity(quantity - inviolable_stock)


    getProductData: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @setDefaultUoM(@product.unit_of_measure)

        stock = _.find product.stores_list, {store_id: @item.store_id}
        if stock
          @stock_on_hand = stock.filtered_stock_on_hand
          @store_name = stock.store_name

        # @stock_on_hand = round_quantity(item.available_quantity)

        @stores_list = product.stores_list
        @bind_stores_popup()

      , (response)->
        console.error response


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    viewErrorHandler: (data)->
      vm = @
      found = _.find(data.items, (i)-> i.product_id is vm.item.product_id)
      @duplicated = if found then true else false


    render_store_item: (store)->
      "<li class='clearfix stores-list-item'>
      <span class='store-name-in-list'
      data-id='#{store.store_id}'
      data-name='#{store.store_name}'
      data-soh='#{store.filtered_stock_on_hand}'>
        #{store.store_code or store.store_name}
      </span>
      <span class='ui label num qty-label qty-#{@_quantity_class(store)}'>
        #{@round_quantity(store.filtered_stock_on_hand)}
      </span>
      </li>"


    store_item_click_handler: (e, $popup)->
      vm = @
      $this = $(e.currentTarget).find('.store-name-in-list')

      vm.$set vm.item, 'store_id', $this.data('id')
      vm.$set vm.item, 'location_id', null

      vm.store_name = $this.data('name')
      vm.stock_on_hand = vm.round_quantity($this.data('soh'))
      $popup.popup 'hide'


    render_stores_popup: (popup, $popup_el)->
      vm = this
      # console.log vm.stores_list
      return if vm.stores_list.length is 0

      html = "<ul class='ui middle aligned selection list sales-order-stores-list'>"

      grouped_stores_list = _groupBy vm.stores_list, 'location_id'

      # set current location if not in list
      grouped_stores_list[window.App.location_id] ?= []

      _.each grouped_stores_list, (stores, location_id)->
        # stores for current_location and no location

        if +location_id is App.location_id or location_id is "null"
          # product store for current_user
          filtered_stores = _.filter stores, (s)->
            +s.store_id in window.App.user_allowed_stores_ids
          filtered_stores_ids = _.map filtered_stores, 'store_id'

          # all stores from current location for current_user
          allowed_stores = _.filter(vm.allowed_stores_list, {'location_id': +location_id})
          empty_allowed_stores = _.filter(allowed_stores, (s)-> not(s.store_id in filtered_stores_ids))

          filtered_stores = filtered_stores.concat(empty_allowed_stores)

          html += _.map(filtered_stores, (store)->
            vm.render_store_item(store)
          ).join('') if filtered_stores.length
        else
          # Do nothing

      html += "</ul>"
      popup.html(html)

      popup.on 'click', '.stores-list-item', (e)->
        vm.store_item_click_handler(e, $popup_el)


    bind_stores_popup: ->
      return if @hovered

      vm = @
      popupLoading = '<i class="notched circle loading icon green"></i> wait...'
      $el = @_el()
      return unless $el.length

      $popup_el = $el.find('.store-name')
      @hovered = true

      $popup_el.popup
        inline: true
        on: 'click'
        exclusive: true
        hoverable: true
        html: popupLoading
        variation: 'wide'
        lastResort: 'bottom right'
        delay:
          show: 400
          hide: 400
        onShow: (el)->
          vm.render_stores_popup(this, $popup_el)


    _quantity_class: (item)->
      qty = round_quantity(item.quantity)

      reorder_quantity = item.reorder_quantity
      safty_quantity = item.safty_quantity
      max_quantity = item.max_quantity

      switch
        when qty is 0 then 'reorder_quantity'
        when qty < safty_quantity then 'lt-safty_quantity'
        when qty is safty_quantity then 'safty_quantity'
        when qty is reorder_quantity then 'reorder_quantity'
        when qty > reorder_quantity and qty < max_quantity then 'available'
        when qty is max_quantity then 'max_quantity'
        when qty > max_quantity then 'gt-max_quantity'


    scroll_to_current_element: (data)->
      el = data.el
      return unless el

      $scroll_element = $('.page-grid')
      # body_scrollTop = $('body').scrollTop()
      body_scrollTop = $scroll_element.scrollTop()
      # el_offsetTop = @_el().offset().top
      # el_offsetTop = @_el().get(0).offsetTop
      el_offsetTop = el.get(0).offsetTop

      # console.debug 'el_offsetTop', el_offsetTop
      # console.debug 'body_scrollTop', body_scrollTop

      position_on_viewport = el_offsetTop - body_scrollTop + 600
      mid_viewport = window.innerHeight/2

      # console.debug 'position_on_viewport', position_on_viewport
      # console.debug 'mid_viewport', mid_viewport

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        # $("html,body").stop().animate({
        $scroll_element.stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 1000).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


    selectContent: (e)->
      Vue.nextTick => e.target.select()


  computed:
    products: -> products_store.items


    valid_quantity: ->
      @item.quantity != ""


    valid_product: ->
      !!@item.product_id


    errors: ->
      quantity_present: @valid_quantity
      product: @valid_product


    isValid: ->
      not _.some @errors, (v, k)-> v is false

    isValidForTotal: ->
      @valid_quantity and @valid_product


    is_request: ->
      @item.context is "request"


    classList: ->
      list = {}
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['hidden']        = @item._destroy
      list["so-item"]       = true
      list["item-#{@_uid}"] = true
      list
