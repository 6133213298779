$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'

module.exports =
  template: """
    <li class="pos--cart--item d-flex flex-column" :class="{selected: selected}" @click="setAsSelected">
      <div class='d-flex flex-row justify-content-between ltr'>
        <span class="pos--cart--item-name w-75">
          {{ cart_item.product_name }}
        </span>
        <div class="pos--cart--item-price d-flex flex-column text-right">
          <span class='num'>{{ total }}</span>
          <span class="item-discount num text-muted">-{{format_money(cart_item.discount_amount, 2)}}</span>
        </div>
      </div>
      <div class="pos--cart--item-meta d-flex flex-row align-items-center ltr mt-0 text-muted">
        <span class="item-quantity num">{{cart_item.quantity}}</span>
        <span>&times;</span>
        <span class="item-retail-price num">{{format_money(cart_item.retail_price, 2)}}</span>
      </div>
    </li>
  """
  props: ['cart_item']

  store: store

  computed:
    current_cart_item: -> @$store.state.pos.current_tab.current_cart_item

    selected: -> @cart_item.product_id is @current_cart_item.product_id

    total: ->
      return 0 unless @cart_item

      t = Big(@cart_item.quantity)
        .times(@cart_item.retail_price)
        .minus(@cart_item.discount_amount)
      t.toFixed(App.settings.money_round)


  methods:
    setAsSelected: ->
      @$store.commit('selectCartItem', {product_id: @cart_item.product_id})
