$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'

module.exports =
  template: """
    <tr class="form-field">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'pos_order[items_attributes][' + _uid + '][id]'">

        <input type="hidden" v-model="item.product_id"
          :name="'pos_order[items_attributes][' + _uid + '][product_id]'">

        <div class="fied">
          <div class="ui toggle checkbox">
            <input type="checkbox"
              v-model="item.selected" value="on"
              :name="'pos_order[items_attributes][' + _uid + '][selected]'">
            <label for='pos_order_selected'></label>
          </div>
        </div>
      </td>
      <td>
        <div class="ui field item-name">{{ item.product_name }}</div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="'pos_order[items_attributes][' + _uid + '][quantity]'"
            class="input-small item-quantity ltr">
        </div>
      </td>

      <td>
        <div class='num'>{{round_money(item.unit_price)}}</div>
        <div class="ui field">
          <input type='hidden'
            v-model.number="item.unit_price"
            :name="'pos_order[items_attributes][' + _uid + '][unit_price]'">
        </div>
      </td>

      <td>
        <div class="not-returnd-quantity num">{{ not_returned_quantity }}</div>
      </td>

      <td>
        <div class="total-quantity num">{{ item.total_quantity }}</div>
      </td>

      <td>
        <div class="total num">{{ total }}</div>
      </td>
    </tr>
  """
  props: ['item']

  created: ->
    console.log 'RETURN ORDER ITEMS :: created'


  watch:
    'item.selected': (selected)->
      console.log 'SELECTED : product', @item.product_id

  computed:
    total: ->
      Big(round_quantity(@item.quantity))
        .times(round_number(@item.unit_price, 2))
        .toFixed(App.settings.money_round)

    not_returned_quantity: ->
      @item.not_returned_quantity - @item.quantity

    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0 and q <= @item.not_returned_quantity


    errors: ->
      [
        @valid_quantity
      ]


    isValid: ->
      not @errors.some (v, k)=> v is false

