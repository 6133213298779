Vue = require 'vue'

eventHub = do ->
  instance = null
  createInstance = -> new Vue()

  {
    getInstance: ->
      if not instance
        instance = createInstance()
      instance
  }

module.exports = eventHub.getInstance()
