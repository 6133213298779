import consumer from "./consumer";

window.App || (window.App = {});

App.ChatroomMessages = consumer.subscriptions.create("MessagesChannel", {
    connected() {
      console.log('Messages::Connected');
      this.scrollChatPanel();
    },

    disconnected() {},

    received(data) {
      if (data.user_id !== App.user_id) { this.addMessage(data); }
    },

    addMessage(data){
      const $chatroom = $(`[data-chatroom-id='${data.chatroom_id}']`);
      $chatroom.find('.chat-body').removeClass('hidden');
      $chatroom.find('.chat-body').append(this.renderMessage(data));
      this.scrollChatPanel($chatroom);
    },

    renderMessage(data){
      return `\
<div class="event ${ data.user_id === App.user_id ? 'owner' : 'other' }">
  <div class="label">
    <img src="/assets/employee-avatar.png">
  </div>
  <div class="content">
    <div class="summary">
      <a class="user">${ data.user }</a>
      <div class="date">
        ${ data.message_time }
      </div>
    </div>
    <div class="extra text">
      ${ data.message }
    </div>
  </div>
</div>\
`;
    },

    scrollChatPanel(chatroom = $(".chat-panel")){
      if (chatroom.length) { chatroom.scrollTop(chatroom[0].scrollHeight); }
    }
  }
);
