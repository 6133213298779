import { Controller } from "stimulus"
import { nextFrame } from "javascripts/helpers"

export default class extends Controller {
  static targets = [ "form", "input", "results", "result" ]
  static classes = [ "show", "busy", "empty" ]

  // Actions

  reset() {
    this.closeResults()
  }

  resetWhenEmpty({ target }) {
    if (target.value) return

    this.reset()
  }

  resetWhenOutside(event) {
    if (!this.element.contains(event.target)) this.closeResults()
  }

  openWhenFilled({ target }) {
    if (target.value) this.openResults()
  }

  async performSearch() {
    this.element.classList.add(this.busyClass)
    await nextFrame()
    await this.resultsTarget.loaded
    this.openResults()
  }

  // Private

  openResults() {
    this.inputTarget.setAttribute("aria-expanded", "true")
    this.element.classList.remove(this.busyClass)
    this.element.classList.add(this.showClass)
    this.element.classList.toggle(this.emptyClass, this.resultTargets.length == 0)
  }

  closeResults() {
    this.inputTarget.setAttribute("aria-expanded", "false")
    this.element.classList.remove(this.showClass, this.emptyClass)
  }
};
