import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'
import debounce from "debounce"

export default class extends Controller {
  static values = {
    whitelist: Array,
    currentValues: Array,
    limit: Number,
    remote: Boolean,
    remoteUrl: String
  }

  controller = null;

  connect () {
    this.bind_tags();
    this.set_values();
  }

  onInput = debounce((e) =>{
    let value = e.detail.value
    e.detail.tagify.whitelist = null // reset the whitelist

    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    this.controller && this.controller.abort()
    this.controller = new AbortController()

    // show loading animation and hide the suggestions dropdown
    e.detail.tagify.loading(true).dropdown.hide()

    let url = this.remoteUrlValue.replace('QUERY', value);

    fetch(url, {signal: this.controller.signal})
      .then(RES => RES.json())
      .then((newWhitelist)=>{
        e.detail.tagify.whitelist = newWhitelist.results // update whitelist Array in-place
        e.detail.tagify.loading(false).dropdown.show(value) // render the suggestions dropdown
      })
  }, 300)

  bind_tags() {
    this.tagify = new Tagify(this.element, {
      tagTextProp: 'name',
      skipInvalid: true,
      // enforceWhitelist : true,
      delimiters       : ",|،",
      whitelist        : this.whitelistValue,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      dropdown: {
        closeOnSelect: false,
        enabled: 0,
        classname: 'tags-list',
        searchKeys: ['name', 'code', 'id']  // very important to set by which keys to search for suggesttions when typing
      },

      maxTags: this.maxTags,
      mode : this.mode,

      templates: {
        // tag: this.tagTemplate,
        dropdownItem: this.suggestionItemTemplate,
        // dropdownHeader: dropdownHeaderTemplate
      },


      callbacks        : {
        add    : console.log,  // callback when adding a tag
        remove : console.log   // callback when removing a tag
      }
    })

    if (this.hasRemoteValue === true) {
      this.tagify.on('input', this.onInput)
    }
  }

  get maxTags() {
    return this.limitValue || Infinity
  }

  get mode() {
    // return this.maxTags == 1 ? "select" : null
  }

  set_values() {
    if (this.currentValuesValue) {
      this.tagify.addTags(this.currentValuesValue);
    }
  }


  suggestionItemTemplate(tagData){
    return `
        <div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item ${tagData.class ? tagData.class : ""}'
            tabindex="0"
            role="option">
            
            <span>${tagData.name}</span>
        </div>
    `
  }

  tagTemplate(tagData){
    return `
        <tag title="${tagData.name}"
                contenteditable='false'
                spellcheck='false'
                tabIndex="-1"
                class="tagify__tag ${tagData.class ? tagData.class : ""}"
                ${this.getAttributes(tagData)}>
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div>
                <span class='tagify__tag-text'>${tagData.name}</span>
            </div>
        </tag>
    `
  }


  initialize() {
    this.tagify = null;
  }

  disconnect() {
  }
};
