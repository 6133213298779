$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../../event_hub.coffee'


incident =
  data: ->
    id: window.incident.id
    witnesses: window.incident?.witnesses
    involved_persons: window.incident?.involved_persons

  components:
    'witness-item': require('./incident-witness.coffee')
    'person-item': require('./incident-person.coffee')

  created: ->
    eventHub.$on('remove-witness', @removeWitness)

    if @witnesses.length == 0
      @witnesses.push({id: null, name: null, address: null, phone: null, other: null})  

    eventHub.$on('remove-person', @removePerson)

    if @involved_persons.length == 0
      @involved_persons.push({id: null, name: null, address: null, phone: null, other: null})  

  computed:
    isValidAttributes: ->
      @name?

    isValid: ->
      @isValidAttributes
      true


  methods:
    addWitness: (event) ->
      @witnesses.push({id: null, name: null, address: null, phone: null, other: null})
      event and event.preventDefault()

    removeWitness: (data)->
      console.log('DATA', data)
      this[data.type].splice(data.index, 1)

    addPerson: (event) ->
      @involved_persons.push({id: null, name: null, address: null, phone: null, other: null})
      event and event.preventDefault()

    removePerson: (data)->
      console.log('DATA', data)
      this[data.type].splice(data.index, 1)

el = document.querySelector('.incident-details-form-wrapper')
new Vue(incident).$mount(el) if el

