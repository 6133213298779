$ = require 'jquery'
Vue = require 'vue'

products_store = require '../../products_store.coffee'

require '../../directives/ui_dropdown.coffee'


order = new Vue
  data:
    items: window.items || []
    is_ready: false

  components:
    'return-item': require('./return-order-item.coffee')


  created: ->
    el = $('.pos-return-form-wrapper')
    return unless el.length


  computed:
    total_units: -> (@items.filter (i)=> i.selected).length

    order_total: ->
      t = @items.reduce (sum, item)=>
        if item.selected and @itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum.plus(0)
      , Big(0)
      t.toFixed(App.settings.money_round)


    selectedItems: ->
      @items.filter((i)=> i.selected) or []


    itemsIsValid: ->
      not @selectedItems.some (i)=> not @itemIsValid(i)

    hasSelectedItems: ->
      @selectedItems.length > 0

    isValid: ->
      @hasSelectedItems and @itemsIsValid


  methods:
    addItem: (event)->
      item = {}
      @items.push item
      event.preventDefault()

    removeItem: (index)->
      @items.splice(index, 1)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.quantity != "" and q > 0 and
        q <= round_quantity(item.not_returned_quantity)


el = document.querySelector('.pos-return-form-wrapper')
order.$mount(el) if el
