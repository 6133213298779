Vue = require 'vue'
_ = require 'lodash/core'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'


budget_view = new Vue
  data: ->
    name: window.budget?.name
    start_date: window.budget?.start_date
    end_date: window.budget?.end_date
    responsible_id: window.budget?.responsible_id
    items: []
    errors: []
    is_ready: true

  mixins: [formSubmitMixin]

  components:
    'datepicker': require('../components/simple_date_picker.coffee')
    'budget-line': require('./components/budget-line.coffee')


  created: ->
    eventHub.$on('removeItem', @removeItem)
#    eventHub.$on('addEmptyItem', @addEmptyItem)

    if window.budget_items
      Vue.set(@, 'items', window.budget_items)

#    if @items?.length is 0
#      @addItem()


  computed:
    count: -> @active_items.length

    active_items: ->
      @items.filter((item)=> not item._destroy and (item.fi_account_id or item.cost_center_id))

    itemsIsValid: ->
      not _.some(@active_items, (i)=> not @itemIsValid(i))

    hasItems: ->
      @active_items.length >= 1


    isValidAttributes: ->
      @name and @start_date? and @end_date?

    isValid: ->
      @hasItems and @itemsIsValid and @isValidAttributes


  methods:
    addItem: (event)->
      item =
        id: null
        _destroy: null
        fi_account_id: null
        cost_center_id: null
        start_date: @start_date
        end_date: @end_date

      @items.push item
      event and event.preventDefault()


#    addEmptyItem: ()->
#      last_item = _.last(@items)
#
#      if last_item?.account_id?
#        @addItem()


    removeItem: (data)->
      console.log('Remove Index', data.index)
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      true


el = document.querySelector('.fi-budget-form-wrapper')
budget_view.$mount(el) if el
