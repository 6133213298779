$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'
require '../directives/date_picker.coffee'


contract =
  data: ->
    signees: window.signees || []
    is_ready: false
    contact_gid: window.contact_gid
    name: window.contract?.name
    from_date: window.contract?.from_date
    to_date: window.contract?.to_date
    representative: window.contract?.representative

  components:
    'signee': require('./signee.coffee')


  created: ->
    eventHub.$on('remove-item', @removeItem)

    unless @contact_gid
      @signees.push({amount: 0, description: ''})


  computed:
    signees_count: -> @signees.length

    signeesIsValid: ->
      not @signees_count.some (i)=> not @itemIsValid(i)

    hasItems: ->
      @signees_count.length > 0

    isValidContact: ->
      @contact_id != null and @contact_id isnt ""


    isValidAttributes: ->
      @isValidContact

    isValid: ->
      @isValidAttributes and @hasItems
      true


  methods:
    addItem: (event)->
      item = {name: null, logo: null}
      @signees.push item
      event and event.preventDefault()


    removeItem: (data)->
      @signees.splice(data.index, 1)


    itemIsValid: (item)->
      item.name isnt null and item.name != ""


  events:
    'remove-item': (index)-> @removeItem(index)


el = document.querySelector('.contract-form-wrapper')
new Vue(contract).$mount(el) if el

