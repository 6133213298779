$ = require 'jquery'
window.jQuery = $
Vue = require 'vue'
Big = require 'big.js'
_ = require 'lodash/core'
products_store = require '../products_store.coffee'
products_cache = require '../../products_caching.coffee'
eventHub = require '../event_hub.coffee'
UnitOfMeasureHandlers = require '../mixins/unit_of_measure_handlers.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'general_sales_return[items_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="item._destroy"
          :name="'general_sales_return[items_attributes][' + _uid + '][_destroy]'">

        <div class="ui field" v-if='!product' key='products-dropdown'>
            <ui-dropdown
              :products='products'
              v-model='item.product_id'
              :name="'general_sales_return[items_attributes][' + _uid + '][product_id]'"></ui-dropdown>
        </div>

        <div class="ui field" key='product-name' v-else>
            <input type="hidden" v-model="item.product_id"
              :name="'general_sales_return[items_attributes][' + _uid + '][product_id]'">
            <span class='product-name p-2'>{{ product.name }}</span>
        </div>

      </td>

      <td>
        <div class='two fields mb-0'>
          <div class="ui field" :class="{ 'error': !valid_quantity }">
            <input
              v-model.number="item.quantity"
              :name="'general_sales_return[items_attributes][' + _uid + '][quantity]'"
              class="input-small item-quantity ltr">
          </div>

          <div class="ui field" >
              <units-dropdown
                placeholder='UoM'
                v-model='item.unit_of_measure_id'
                :name="'general_sales_return[items_attributes][' + _uid + '][unit_of_measure_id]'"
                :uid='_uid'
                :product_id='item.product_id'
                @selected-uom='setUoM'
              ></units-dropdown>
            </div>
          </div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_unit_price }">
          <input
            v-model.number="item.unit_price"
            :name="'general_sales_return[items_attributes][' + _uid + '][unit_price]'"
            class="input-small item-unit_price ltr">
        </div>
      </td>

      <td>
        <div class="total num">{{ total }}</div>
      </td>

      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button js-btn-remove" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  components:
    'ui-dropdown': require('./products_ui_dropdown.coffee')
    'units-dropdown': require('./units_dropdown.coffee')


  props: ['index', 'item']

  mixins: [UnitOfMeasureHandlers]

  data: ->
    product: null
    dropdown: null
    ready: false

  created: ->
    console.log 'RETURN ITEM:: I am ready'

#    eventHub.$on('change-product-id', @change_product_id)
    @getProductData()

  destroyed: ->
    console.log 'Destroyed ME'


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Item!"


  watch:
    'unit_of_measure': ()->
      @updateUnitPrice(@product.retail_price)

    'item.product_id': (newID, oldID)->
      if oldID is null
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})

      if parseInt(newID) isnt parseInt(oldID)
        @$set(@item, 'product_id', newID)
        @getProductData()
        @focusOnQuantity()


  computed:
    products: ->
      products_cache.products

    total: ->
      Big(round_quantity(@item.quantity))
        .times(round_number(@item.unit_price, 2))
        .toFixed(App.settings.money_round)


    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0


    valid_unit_price: ->
      price = round_number(@item.unit_price, 3)
      @item.unit_price != "" and price > 0


    valid_store: ->
      @item.store_id != ""


    errors: ->
      quantity: @valid_quantity
      unit_price: @valid_unit_price
      # store_or_location: @valid_store


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    store_is_present: ->
      @item.store_id?


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["sor-item"]      = true
      list["item-#{@_uid}"] = true
      list['hidden']        = @item._destroy
      list


  methods:
    _el: -> $(".item-#{@_uid}")

    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    get_product: (product_id)->
      products_store.fetchItem(product_id)


    getProductData: ->
      return unless @item.product_id

      @get_product(@item.product_id).then (product)=>
        @product = product
        @setDefaultUoM(@product.unit_of_measure)
        @updateUnitPrice(@product.retail_price) if @item.unit_price is 0

        @item.quantity = @round_quantity @item.quantity

        @ready = true

      , (response)->
        console.error response


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    nextProduct: (e)->
      console.log 'ENTER'

      e.preventDefault()
      $next = $(".item-#{@_uid}").next()
      if $next.length
        $next.find('.selectized.product-dropdown')[0].selectize.focus()
      else
        eventHub.$emit('add-item')


    selectContent: (e)->
      Vue.nextTick => e.target.select()
