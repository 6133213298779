$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
products_store = require '../products_store.coffee'
require '../directives/ui_dropdown.coffee'
require '../directives/selectize_tags.coffee'



purchase_order_procurement = new Vue
#  el: '.purchase-order-procurement-form-wrapper'
  data:
    items: window.purchase_order_procurement_items || []
    is_ready: false
    received_at: window.purchase_order_procurement?.received_at
    store_id: window.purchase_order_procurement?.store_id
    reference: window.purchase_order_procurement?.reference
    goods_received_receipt_id: window.purchase_order_procurement?.goods_received_receipt_id
    tags: window.purchase_order_procurement?.tags
    notes: window.purchase_order_procurement?.notes
    default_tags: window.default_tags or []


  components:
    'purchase-order-procurement-item': require('./components/purchase-order-procurement-item.coffee')

  created: ->
    el = $('.purchase-order-procurement-items-wrapper')
    return unless el.length


  mounted: ->
    @bind_goods_receipts_dropdown()


  computed:
    total_units: -> (_.filter @items, (i)-> i.selected).length

    order_total: ->
      vm = @
      t = _.reduce @items, (sum, item)->
        if item.selected and vm.itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum.plus(0)
      , Big(0)
      t.toFixed(App.settings.money_round)


    selectedItems: ->
      _.filter(@items, (i)-> i.selected) or []


    isValidStore: ->
      not ["", null].includes? @store_id


    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(i)


    hasSelectedItems: ->
      @selectedItems.length > 0

    validReceivedAt: ->
      @received_at? and @received_at != ""
      true

    isValid: ->
      @validReceivedAt and @store_id? and @hasSelectedItems and @itemsIsValid



  methods:
    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.quantity != "" and q > 0 and
        q <= item.not_received_quantity



    bind_goods_receipts_dropdown: ->
      $('.js-remote-received-receipt-vue-dropdown').dropdown
        showOnFocus: true
        forceSelection: false
        placeholder: false
        match: 'text'
        minCharacters: 3
        throttle: 700
        apiSettings:
          cache: false
          url: '/search/received_receipts?q={query}'
        saveRemoteData: false



if document.querySelector('.purchase-order-procurement-form-wrapper')
  purchase_order_procurement.$mount('.purchase-order-procurement-form-wrapper')
