import { Controller } from "stimulus"

export default class extends Controller {
  // Open a new window that's 80% the size of the parent centered on that parent
  open(event) {
    event.preventDefault()

    const newWindowWidth      = window.outerWidth * 0.8
    const newWindowHeight     = window.outerHeight * 0.8
    const newWindowTopOffset  = window.screenTop + (window.outerHeight - newWindowHeight) / 2
    const newWindowLeftOffset = window.screenLeft + (window.outerWidth - newWindowWidth) / 2

    window.open(
      this.element.href, "_blank",
      "width=" + newWindowWidth + ",height=" + newWindowHeight + ",top=" + newWindowTopOffset + ",left=" + newWindowLeftOffset
    )
  }

  close() {
    window.close()
  }

  print() {
    window.print()
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  confirm(event) {
    const { message } = event.target.closest("[data-message]").dataset
    const result = window.confirm(message)
    if (!result) event.preventDefault()
  }
};
