$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
products_store = require '../products_store.coffee'
require '../directives/ui_dropdown.coffee'
require '../directives/selectize_tags.coffee'
require '../directives/date_picker.coffee'
eventHub = require '../event_hub.coffee'
ProductTaxesCalculator = require './product_taxes_calculator.coffee'

taxable_purchase_invoice = new Vue
#  el: '.taxable-purchase-invoice-form-wrapper'
  data: ->
    items: []
    errors: []
    is_ready: false
    id: window.taxable_purchase_invoice?.id
    owner_id: window.taxable_purchase_invoice?.owner_id
    contact_id: window.invoice_contact_gid
    reference: window.taxable_purchase_invoice?.reference
    issued_at: window.taxable_purchase_invoice?.issued_at
    percent_discount: window.taxable_purchase_invoice?.percent_discount
    sales_commission: window.taxable_purchase_invoice?.sales_commission
    extra_commission: window.taxable_purchase_invoice?.extra_commission
    commission_vat: window.taxable_purchase_invoice?.commission_vat
    paid_commission_vat: window.taxable_purchase_invoice?.paid_commission_vat
    sales_commission_tax: window.taxable_purchase_invoice?.sales_commission_tax
    available_goods_received_receipts: window.available_goods_received_receipts or []
    goods_received_receipts_ids: window.goods_received_receipts_ids or []
    commission_total: window.taxable_purchase_invoice?.commission_total
    discount_tax_source: window.taxable_purchase_invoice?.discount_tax_source
    discount_method: window.taxable_purchase_invoice?.discount_method
    acceptance_discount: window.taxable_purchase_invoice?.acceptance_discount
    tax_office_id: window.taxable_purchase_invoice?.tax_office_id
    commission_vat_tax_office_id: window.taxable_purchase_invoice?.commission_vat_tax_office_id
    paid_commission_vat_tax_office_id: window.taxable_purchase_invoice?.paid_commission_vat_tax_office_id



  components:
    'taxable-purchase-invoice-item': require('./components/taxable-purchase-invoice-item.coffee')


  created: ->
    el = $('.taxable-purchase-invoice-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)


    products_store.fetchSimpleItems(@).then =>
      if window.taxable_purchase_invoice_items?.length
        Vue.set(@, 'items', window.taxable_purchase_invoice_items)
      else
        @addItem()


  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})



  computed:
    count: -> @items.length

    has_supplier_tax: ->
      @discount_tax_source == 'supplier'


    items_quantity_sum: ->
      _.reduce @itemsWithProduct, (sum, item)->
        if round_number(item.quantity) > 0
          sum.plus(item.quantity)
        else
          sum
      , Big(0)


    items_quantity_discount_sum: ->
      _.reduce @itemsWithProduct, (sum, item)->
        if item.valid
          sum.plus(item.quantity_discount)
        else
          sum
      , Big(0)


    items_total_with_taxes_sum: ->
      _.reduce @itemsWithProduct, (sum, item)=>
        if item.valid
          sum.plus(@item_total_with_taxes(item))
        else
          sum
      , Big(0)


    __items_total_with_taxes_sum: ->
      _.reduce @itemsWithProduct, (sum, item)=>
        i = new ProductTaxesCalculator(item)
        if item.valid
          sum.plus(i.total_with_taxes())
        else
          sum
      , Big(0)


    items_net_total_sum: ->
      _.reduce @itemsWithProduct, (sum, item)=>
        if item.valid
          sum.plus(@item_net_total(item))
        else
          sum
      , Big(0)


#    items_total_without_taxes_sum: ->
#      _.reduce @itemsWithProduct, (sum, item)=>
#        if item.valid
#          sum.plus(@item_total_without_taxes(item))
#        else
#          sum
#      , Big(0)


    items_warranty_sum: ->
      _.reduce @itemsWithProduct, (sum, item)=>
        if item.valid
          sum.plus(@item_warranty_value(item))
        else
          sum
      , Big(0)


    items_vat_sum: ->
      _.reduce @itemsWithProduct, (sum, item)=>
        if item.valid
          sum.plus(@item_vat_value(item))
        else
          sum
      , Big(0)


    items_table_tax_sum: ->
      _.reduce @itemsWithProduct, (sum, item)=>
        if item.valid
          sum.plus(@item_table_tax_value(item))
        else
          sum
      , Big(0)


    item_totals_sum: -> @_total


    _total: ->
      Big(@items_net_total_sum.toFixed(4))
        .plus(@items_warranty_sum.toFixed(4))
        .plus(@items_table_tax_sum.toFixed(4))
        .plus(@items_vat_sum.toFixed(4))


    invoice_total: ->
      acceptance_discount = round_number(@acceptance_discount, 3)

      Big(@_total.toFixed(4))
        .minus(@commissions_sum.toFixed(4))
        .minus(@commission_vat_value.toFixed(4))
        .plus(@paid_commission_vat_value.toFixed(4))
        .plus(@sales_commission_tax_value.toFixed(4))
        .minus(@supplier_tax_value.toFixed(4))
        .minus(acceptance_discount)


    total_needed_for_commission: ->
      if @commission_total == 'total_with_taxes'
        @_total.toFixed(4)
      else if @commission_total == 'net_total'
        @items_net_total_sum.toFixed(4)


    sales_commission_value: ->
      commission = round_number(@sales_commission, 3)
      Big(commission)
        .times(@total_needed_for_commission)
        .div(100)


    extra_commission_value: ->
      commission = round_number(@extra_commission, 3)
      Big(commission)
        .times(@total_needed_for_commission)
        .div(100)

    commission_vat_value: ->
      tax = round_number(@commission_vat, 3)
      @commissions_sum
        .times(tax)
        .div(100)

    paid_commission_vat_value: ->
      if @paid_commission_vat then @commission_vat_value else Big(0)


    commissions_sum: ->
      @sales_commission_value.plus(@extra_commission_value)


    sales_commission_based_on_supplier_tax: ->
      if @has_supplier_tax
        @supplier_tax_value
      else
        @sales_commission_tax_value


    sales_commission_tax_value: ->
      return 0 if @has_supplier_tax
      commission = round_number(@sales_commission_tax, 2)

      Big(commission)
        .times(@commissions_sum)
        .div(100)


    supplier_tax_value: ->
      return 0 unless @has_supplier_tax
      commission = round_number(@sales_commission_tax, 2)

      Big(commission)
        .times(@items_net_total_sum)
        .div(100)


    itemsByRef: ->
      _.filter @$refs.taxable_invoice_item, (i)->
        i.item.product_id isnt null and not i.item._destroy


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      not _.some(@itemsWithProduct, (i)=> not @itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    validContactId: ->
      @contact_id? and @contact_id != ""


    validOwnerId: ->
      @owner_id? and @owner_id != ""


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        key = "#{item.product_id}:#{item.goods_received_receipt_id}"

        if data[key]
          data[key].count += 1
        else
          data[key] = {product_id: item.product_id, count: 1}

      _.filter data, (v, k)-> v.count > 1


    # We intentionally are disabling duplicate validation
    items_has_duplicates: ->
      @duplicated_items.length > 0
      false

    hasSalesCommissionTax: ->
      not([null, undefined, ''].includes(@sales_commission_tax)) and (@round_number(@sales_commission_tax) > 0)

    hasCommissionVat: ->
      not([null, undefined, ''].includes(@commission_vat)) and (@round_number(@commission_vat) > 0)

    isValidTaxOffice: ->
      return true unless @hasSalesCommissionTax
      not [null, undefined, ''].includes(@tax_office_id)

    isValidCommissionVatTaxOffice: ->
      return true unless @hasCommissionVat
      not [null, undefined, ''].includes(@commission_vat_tax_office_id)

    isValidPaidCommissionVatTaxOffice: ->
      return true unless @hasCommissionVat and @paid_commission_vat
      not [null, undefined, ''].includes(@paid_commission_vat_tax_office_id)

    isValidAttributes: ->
      @validContactId and @validOwnerId and @isValidTaxOffice and @isValidCommissionVatTaxOffice


    isValid: ->
      @isValidAttributes and @hasItems and @itemsIsValid \
        and not @items_has_duplicates


  methods:
    addItem: (event)->
      event and event.preventDefault()

      item =
        id: null
        _destroy: null
        quantity: 1
        unit_price: 0
        product_id: null
        warranty_amount: 0
        warranty_percent: 0
        vat: 0
        table_tax: 0
        offer_discount: 0
        quantity_discount: 0
        percent_discount: 0
        special_discount: 0
        early_payment_discount: 0
        value_discount: 0
        total: 0
        valid: true
      @items.push item


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      p = round_money(item.unit_price)

      item.product_id isnt null and
        item.quantity != "" and q > 0 and
        item.unit_price != "" and p > 0


    item_available_quantity: (item)->
      Big(round_quantity(item.quantity, 3))
        .minus(round_quantity(item.quantity_discount, 3))


    item_sub_total: (item)->
      if item.valid
        value_discount = round_number(item.value_discount, 3)
        unit_price = round_number(item.unit_price, 3)

        Big(@item_available_quantity(item))
          .times(Big(unit_price)
          .minus(value_discount))
      else
        Big(0)


    item_total_with_taxes: (item)->
      Big(@item_sub_total(item))


    item_net_total: (item)->
      Big(@item_total_without_taxes(item))


    item_sub_total_without_taxes: (item)->
      Big(@item_total_with_taxes(item))
        .times(@item_vat_factor(item))
        .times(@item_table_tax_factor(item))
        .times(@item_warranty_percent_factor(item))


    item_sub_total_without_taxes_and_discount: (item)->
      percent_discount = round_number(item.percent_discount, 3)
      special_discount = round_number(item.special_discount, 3)
      early_payment_discount = round_number(item.early_payment_discount, 3)

      discount_formula = (discount)->
        return 1 if discount < 0
        Big(1).minus(Big(discount).div(100))


      if @discount_method is 'commutative'
        @item_sub_total_without_taxes(item)
          .times(discount_formula(percent_discount))
          .times(discount_formula(special_discount))
          .times(discount_formula(early_payment_discount))

      else if @discount_method is 'complete'
        @item_sub_total_without_taxes(item).times(
          Big(1)
            .minus(Big(percent_discount).div(100))
            .minus(Big(special_discount).div(100))
            .minus(Big(early_payment_discount).div(100))
        )

      else
        0


    item_total_without_taxes: (item)->
      quantity = @item_available_quantity(item)
      warranty_amount = round_number(item.warranty_amount, 3)
      offer_discount = round_number(item.offer_discount, 3)

      @item_sub_total_without_taxes_and_discount(item)
        .minus(quantity.times(warranty_amount))
        .minus(quantity.times(offer_discount))


    item_vat_factor: (item)->
      vat = round_number(item.vat , 2)
      factor = Big(vat).div(100).plus(1)
      Big(1).div(factor)


    item_table_tax_factor: (item)->
      warranty_percent = round_number(item.warranty_percent, 2)
      table_tax = round_number(item.table_tax, 2)

      is_warranty_percent = warranty_percent > 0
      is_table_tax = table_tax > 0

      return Big(1) unless table_tax

      factor = if is_warranty_percent and is_table_tax
        Big(warranty_percent).plus(table_tax).div(100).plus(1)
      else if is_table_tax
        Big(table_tax).div(100).plus(1)
      else
        1
      Big(1).div(factor)


    item_warranty_percent_factor: (item)->
      warranty_percent = round_number(item.warranty_percent, 2)
      is_warranty = warranty_percent > 0
      is_table_tax = round_number(item.table_tax, 2) > 0

      return Big(1) if is_warranty and is_table_tax

      factor = Big(warranty_percent).div(100).plus(1)
      Big(1).div(factor)


    item_table_tax_value: (item)->
      table_tax = round_number(item.table_tax, 2)
      return Big(0) if table_tax is 0

      Big(@item_net_total(item)).times(table_tax).div(100)


    item_warranty_percent_value: (item)->
      warranty_percent = round_number(item.warranty_percent, 2)
      return Big(0) if warranty_percent is 0

      Big(@item_net_total(item))
        .times(warranty_percent).div(100)


    item_warranty_amount_value: (item)->
      warranty_amount = round_number(item.warranty_amount, 3)
      Big(warranty_amount).times(@item_available_quantity(item))


    item_warranty_value: (item)->
      if item.warranty_amount > 0
        @item_warranty_amount_value(item)
      else
        @item_warranty_percent_value(item)


    item_vat_value: (item)->
      vat = round_number(item.vat, 2)
      return Big(0) if vat is 0

      Big(@item_net_total(item))
        .plus(@item_table_tax_value(item))
        .plus(@item_warranty_value(item))
        .times(vat)
        .div(100)


    item_percent_discount_of: (value, item)->
      discount = round_number(item.percent_discount, 2)
      return Big(0) if discount <= 0

      Big(value).times(discount).div(100)


    item_early_payment_discount_of: (value, item)->
      discount = round_number(item.early_payment_discount, 3)
      return Big(0) if discount <= 0

      Big(value).times(discount).div(100)


    item_apply_percent_discount: (value, item)->
      value.minus(@item_percent_discount_of(value, item))


    item_apply_early_payment_discount: (value, item)->
      value.minus(@item_early_payment_discount_of(value, item))


el = document.querySelector('.taxable-purchase-invoice-form-wrapper')
taxable_purchase_invoice.$mount(el) if el
