window.sweetAlertConfirmBox = (message, callback, params={}) ->
  options = {
    title: message,
    text: params.text,
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: "نعم بالتأكيد!",
    cancelButtonText: "لا",
    allowEscapeKey: false,
    closeOnConfirm: params.closeOnConfirm
  }

  swal options, callback


$.rails.allowAction = (element) ->
  message = element.data("confirm")
  return true unless message

  answer = false
  callback = undefined

  if $.rails.fire(element, "confirm")
    sweetAlertConfirmBox message, ->
      callback = $.rails.fire(element, "confirm:complete", [answer])
      if callback
        oldAllowAction = $.rails.allowAction
        $.rails.allowAction = -> true

        element.trigger "click"
        $.rails.allowAction = oldAllowAction

  false
