$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'

module.exports =
  template: """
    <div class="ui xinline floating basic icon button dropdown" ref='dropdown'>
      <i class="dropdown icon"></i>
      <div class="text">{{placeholder}}</div>
      <input type='hidden' :name="name">
      <div class="menu">
        <div class="item" v-for="item in options"
          :data-value="item.value" :key="item.value">{{item.name}}</div>
      </div>
    </div>

  """
  props: ['initial_options', 'name', 'value', 'placeholder', 'uid', 'product_id']
  emits: ['input', 'selected-uom']

  data: ->
    dropdown: null
    options: @initial_options or []


  created: ->
    console.log @uid, 'UoM => created'
    eventHub.$on('refresh-dropdown', @refreshDropDown)


  mounted: ->
    Vue.nextTick => @init_dropdown()


  destroyed: ->
    console.log 'UoM::destroyed'


  watch:
    product_id: (newID, oldID)->
      @fetch_units() if @dropdown and @product_id

    value: (newID, oldID)->
      if newID != oldID
        console.log @uid, "== UoM => change newID", newID

        @setSelected(newID) if @dropdown


  methods:
    _el: -> @$el

    refreshDropDown: (data)->
      units = data.units
      uid = data.uid

      return if uid != @uid

      console.log(@uid, '== Refreshing UoM!........')

      @changeValues(units)


    changeValues: (values)->
      return unless @dropdown

      console.log(@uid, '== UoM => Changing values!........')

      Vue.nextTick =>
        @dropdown.dropdown 'change values', values
        @setSelected()


    init_dropdown: ->
      @dropdown = $(@$refs.dropdown)
      @dropdown.val(@value) # Initial value

      @bindDropdown()
      @setInitialValue()
      if @product_id
        @fetch_units()


    bindDropdown: ->
      onChange = (value, text, $choice)->
        if parseInt(value) isnt @value
          @$emit('input', value)

      @dropdown.dropdown
        showOnFocus: true
        selectOnKeydown: false
        onChange: onChange.bind(@)


    setInitialValue: ->
      return unless @value

      console.log @uid, '== UoM => Initial value'
      @setSelected()


    fetch_units: ->
      console.log(@uid, '== Fetching Units .......')

      url = "/api/unit_of_measures?product_id=#{@product_id}"

      @addLoadingIndicator()

      $.get(url, (response)=>
        @options = response.units
        @changeValues(@options)
        @removeLoadingIndicator()

      ).fail(->
        console.warn 'Failed to Units'
      )


    setSelected: ->
      console.log(@uid, '== UoM => Set value........', @value)
      @dropdown.dropdown('set selected', @value)
      @emitSelectedUoM() if @options.length > 0


    emitSelectedUoM: ->
      console.log('OPTIONS', @options)
      console.log('Emitting', @value)
      unit = @options.find((i)=> +i.value is +@value)
      @$emit('selected-uom', unit)


    addLoadingIndicator: ->
      @$refs.dropdown.classList.add('loading')

    removeLoadingIndicator: ->
      @$refs.dropdown.classList.remove('loading')


  computed:
    classList: -> {}
