$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
products_store = require '../products_store.coffee'
require '../directives/ui_dropdown.coffee'
require '../directives/selectize_tags.coffee'
require '../directives/date_picker.coffee'
eventHub = require '../event_hub.coffee'


purchase_order = new Vue
#  el: '.purchase-order-form-wrapper'
  data: ->
    items: []
    cost_items: []
    errors: []
    is_ready: false
    order_number: window.purchase_order?.order_number
    store_id: window.purchase_order?.store_id
    contact_id: null
    due_at: window.purchase_order?.due_at
    issued_at: window.purchase_order?.issued_at
    notes: null
    tags: window.purchase_order?.tags
    default_tags: window.default_tags or []
    attachment: null
    attachment_data: null

  components:
    'purchase-order-item': require('./components/purchase-order-item.coffee')
    'purchase-order-cost-item': require('./components/purchase-order-cost-item.coffee')


  created: ->
    el = $('.purchase-order-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('remove-cost-item', @removeCostItem)
    eventHub.$on('add-item', @addItem)
    eventHub.$on('add-cost-item', @addCostItem)


    @contact_id = window.contact_gid

    if window.purchase_order_cost_items
      Vue.set(@, 'cost_items', window.purchase_order_cost_items)

    products_store.fetchSimpleItems(@).then =>
      if window.purchase_order_items
        Vue.set(@, 'items', window.purchase_order_items)
      else
        @addItem()


  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})


  computed:
    count: -> @items.length

    items_total: ->
      vm = @
      t = _.reduce @itemsWithProduct, (sum, item)->
        if vm.itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum
      , Big(0)


    costs_total: ->
      t = _.reduce @cost_items, (sum, item)->
        if item and item.cost then Big(round_money(item.cost)).plus(sum) else sum
      , Big(0)


    order_total: ->
      t = @items_total.plus(@costs_total)
      t.toFixed(App.settings.money_round)


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    validContactId: ->
      @contact_id? and @contact_id != ""

    validDueAt: ->
      @due_at? and @due_at != ""

    validOrderNumber: ->
      @order_number? and @order_number != ""


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    isValidAttributes: ->
      @validDueAt and @validContactId

    isValid: ->
       @isValidAttributes and @hasItems and @itemsIsValid and not @items_has_duplicates


  methods:
    addItem: (event)->
      item =
        id: null
        _destroy: null
        quantity: 1
        unit_price: 0
        product_id: null
        unit_of_measure_id: null
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


    addCostItem: (event)->
      item =
        id: null
        _destroy: null
        name: ""
        cost: 0
      @cost_items.push item
      event and event.preventDefault()


    removeCostItem: (data)->
      @cost_items.splice(data.index, 1)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      p = round_money(item.unit_price)
      item.product_id isnt null and
        item.quantity != "" and q > 0 and
        item.unit_price != "" and p >= 0


    onFileChange: (e)->
      files = e.target.files || e.dataTransfer.files
      @attachment_data = files[0] if files
      console.log(@attachment_data)


if document.querySelector('.purchase-order-form-wrapper')
  purchase_order.$mount('.purchase-order-form-wrapper')
