$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
products_store = require './products_store.coffee'
require './directives/simple_products_dropdown.coffee'
eventHub = require './event_hub.coffee'

product_cost_equation = new Vue
#  el: '.product-cost-equation-form-wrapper'
  data: ->
    e = window.product_cost_equation
    options = e?.checked_percent_fields

    is_ready: false
    id: e?.id
    apply_discount_on: e?.apply_discount_on
    discount_method: e?.discount_method
    unit_price: e?.unit_price
    product_id: e?.product_id
    warranty_amount: e?.warranty_amount
    warranty_percent: e?.warranty_percent
    vat: e?.vat
    table_tax: e?.table_tax
    percent_discount: e?.percent_discount
    cash_discount: e?.cash_discount
    value_discount: e?.value_discount
    quantity_discount: e?.quantity_discount
    commission: e?.commission
    marketing_discount: e?.marketing_discount
    special_discount: e?.special_discount
    early_payment_discount: e?.early_payment_discount
    offer_discount: e?.offer_discount
    target_discount: e?.target_discount
    monthly_discount_percent: e?.monthly_discount_percent
    monthly_discount_cash: e?.monthly_discount_cash
    quarter_discount_percent: e?.quarter_discount_percent
    quarter_discount_cash: e?.quarter_discount_cash
    annually_discount_percent: e?.annually_discount_percent
    annually_discount_cash: e?.annually_discount_cash
    additional_discount_percent: e?.additional_discount_percent
    additional_discount_cash: e?.additional_discount_cash
    brand_discount_percent: e?.brand_discount_percent
    brand_discount_cash: e?.brand_discount_cash
    other_discount_percent: e?.other_discount_percent
    other_discount_cash: e?.other_discount_cash
    expenses_percent: e?.expenses_percent
    taxes_percent: e?.taxes_percent
    quantity_discount_option: options?.quantity_discount_option
    commission_option: options?.commission_option
    marketing_discount_option: options?.marketing_discount_option
    special_discount_option: options?.special_discount_option
    early_payment_discount_option: options?.early_payment_discount_option
    target_discount_option: options?.target_discount_option
    monthly_discount_percent_option: options?.monthly_discount_percent_option
    monthly_discount_cash_option: options?.monthly_discount_cash_option
    quarter_discount_percent_option: options?.quarter_discount_percent_option
    quarter_discount_cash_option: options?.quarter_discount_cash_option
    annually_discount_cash_option: options?.annually_discount_cash_option
    annually_discount_percent_option: options?.annually_discount_percent_option
    additional_discount_cash_option: options?.additional_discount_cash_option
    additional_discount_percent_option: options?.additional_discount_percent_option
    brand_discount_cash_option: options?.brand_discount_cash_option
    brand_discount_percent_option: options?.brand_discount_percent_option
    other_discount_cash_option: options?.other_discount_cash_option
    other_discount_percent_option: options?.other_discount_percent_option


  watch:
    product_id: -> @getProductData()


  created: ->
    el = $('.product-cost-equation-form-wrapper')
    return unless el.length

    products_store.fetchSimpleItems(@).then ->
      console.log("ITEMS FETCHED")


    eventHub.$on('change-product-id', @change_product_id)
    eventHub.$on('duplicated-items', @viewErrorHandler)
    # fetch data on edit
    # @getProductData() if @product_id



  computed:

    cost: ->
      Big(@totals_sum).minus(@y).plus(@z)


    y: ->
      quantity_discount = round_number @quantity_discount, 4
      marketing_discount = round_number @marketing_discount, 4
      special_discount = round_number @special_discount, 4

      monthly_discount_cash = round_number @monthly_discount_cash, 4
      quarter_discount_cash = round_number @quarter_discount_cash, 4
      annually_discount_cash = round_number @annually_discount_cash, 4
      additional_discount_cash = round_number @additional_discount_cash, 4
      brand_discount_cash = round_number @brand_discount_cash, 4
      other_discount_cash = round_number @other_discount_cash, 4

      Big(quantity_discount)
        .plus(marketing_discount)
        .plus(special_discount)
        .plus(@discount_of(@commission))
        .plus(@discount_of(@early_payment_discount))
        .plus(@discount_of(@target_discount))
        .plus(@discount_of(@monthly_discount_percent))
        .plus(@discount_of(@quarter_discount_percent))
        .plus(@discount_of(@annually_discount_percent))
        .plus(@discount_of(@additional_discount_percent))
        .plus(@discount_of(@brand_discount_percent))
        .plus(@discount_of(@other_discount_percent))
        .plus(monthly_discount_cash)
        .plus(quarter_discount_cash)
        .plus(annually_discount_cash)
        .plus(additional_discount_cash)
        .plus(brand_discount_cash)
        .plus(other_discount_cash)


    checked_field_values: ->
      fields = []

      quantity_discount = round_number @quantity_discount, 4
      marketing_discount = round_number @marketing_discount, 4
      special_discount = round_number @special_discount, 4

      monthly_discount_cash = round_number @monthly_discount_cash, 4
      quarter_discount_cash = round_number @quarter_discount_cash, 4
      annually_discount_cash = round_number @annually_discount_cash, 4
      additional_discount_cash = round_number @additional_discount_cash, 4
      brand_discount_cash = round_number @brand_discount_cash, 4
      other_discount_cash = round_number @other_discount_cash, 4

      fields.push(quantity_discount) if @quantity_discount_option
      fields.push(marketing_discount) if @marketing_discount_option
      fields.push(special_discount) if @special_discount_option

      fields.push(@discount_of(@commission)) if @commission_option
      fields.push(@discount_of(@target_discount)) if @target_discount_option

      if @early_payment_discount_option
        fields.push(@discount_of(@early_payment_discount))

      if @monthly_discount_percent_option
        fields.push(@discount_of(@monthly_discount_percent))
      fields.push(monthly_discount_cash) if @monthly_discount_cash_option

      if @quarter_discount_percent_option
        fields.push(@discount_of(@quarter_discount_percent))
      fields.push(quarter_discount_cash) if @quarter_discount_cash_option

      if @annually_discount_percent_option
        fields.push(@discount_of(@annually_discount_percent))
      fields.push(annually_discount_cash) if @annually_discount_cash_option

      if @additional_discount_percent_option
        fields.push(@discount_of(@additional_discount_percent))
      fields.push(additional_discount_cash) if @additional_discount_cash_option

      if @brand_discount_percent_option
        fields.push(@discount_of(@brand_discount_percent))
      fields.push(brand_discount_cash) if @brand_discount_cash_option

      if @other_discount_percent_option
        fields.push(@discount_of(@other_discount_percent))
      fields.push(other_discount_cash) if @other_discount_cash_option

      fields


    sum_of_checked_percents: ->
      _.reduce @checked_field_values, (result, value)->
        result.plus(value)
      , Big(0)


    z: ->
      expenses_percent = round_number @expenses_percent, 4
      taxes_percent = round_number @taxes_percent, 4

      @sum_of_checked_percents.times(
        Big(1).minus(Big(expenses_percent).div(100))
      ).times(
        Big(taxes_percent).div(100)
      )


    total_for_discount_percent: ->
      if @apply_discount_on is 'total_with_taxes'
        Big(@totals_sum)
      else if @apply_discount_on is 'net_total'
        Big(@net_total)
      else if @apply_discount_on is 'cost_price'
        Big(@unit_price)


    total_with_taxes: -> Big @sub_total


    net_total: -> Big @total_without_taxes


    available_quantity: -> Big 1


    totals_sum: ->
      Big(@net_total.toFixed(2))
        .plus(@vat_value.toFixed(2))
        .plus(@table_tax_value.toFixed(2))
        .plus(@warranty_value.toFixed(2))


    sub_total: ->
      value_discount = round_number(@value_discount, 4)
      unit_price = round_number(@unit_price, 4)
      Big(@available_quantity).times(
        Big(unit_price).minus(value_discount)
      )


    sub_total_without_taxes: ->
      Big(@total_with_taxes)
        .times(@vat_factor)
        .times(@table_tax_factor)
        .times(@warranty_percent_factor)


    sub_total_without_taxes_and_discount: ->
      percent_discount = round_number(@percent_discount, 2)
      cash_discount = round_number(@cash_discount, 2)

      discount_formula = (discount)->
        return 1 if discount < 0
        Big(1).minus(Big(discount).div(100))

      if @discount_method is 'commutative'
        @sub_total_without_taxes
          .times(discount_formula(percent_discount))
          .times(discount_formula(cash_discount))

      else if @discount_method is 'complete'
        @sub_total_without_taxes.times(
          Big(1)
            .minus(Big(percent_discount).div(100))
            .minus(Big(cash_discount).div(100))
        )

      else
        0


    # This is X
    total_without_taxes: ->
      warranty = round_number(@warranty_amount, 2)
      offer_discount = round_number(@offer_discount, 3)

      @sub_total_without_taxes_and_discount
        .minus(@available_quantity.times(warranty))
        .minus(offer_discount)


    vat_factor: ->
      factor = Big(round_number(@vat)).div(100).plus(1)
      Big(1).div(factor)


    warranty_percent_factor: ->
      warranty_percent = round_number(@warranty_percent)
      return Big(1) if warranty_percent > 0 and @has_table_tax

      factor = Big(warranty_percent).div(100).plus(1)
      Big(1).div(factor)


    table_tax_factor: ->
      return Big(1) unless @has_table_tax

      table_tax = round_number(@table_tax)
      warranty_percent = round_number(@warranty_percent)
      is_warranty = warranty_percent > 0

      factor = if is_warranty and @has_table_tax
        Big(warranty_percent).plus(table_tax).div(100).plus(1)
      else if @has_table_tax
        Big(table_tax).div(100).plus(1)
      else
        1

      Big(1).div(factor)


    warranty_percent_value: ->
      Big(@net_total).times(round_number(@warranty_percent)).div(100)


    warranty_amount_value: ->
      Big(round_number(@warranty_amount, 2)).times(@available_quantity)


    warranty_value: ->
      if @warranty_amount > 0
        @warranty_amount_value
      else
        @warranty_percent_value


    vat_value: ->
      return Big(0) if round_number(@vat) is 0
      Big(@net_total)
        .plus(@table_tax_value)
        .plus(@warranty_value)
        .times(@vat)
        .div(100)


    table_tax_value: ->
      Big(@net_total).times(round_number(@table_tax)).div(100)


    quantity_discount_value: ->
      Big(round_number(@quantity_discount)).times(@unit_price)


    has_table_tax: ->
      round_number(@table_tax) > 0


    percent_discounts_list: ->
      {
        commission: @discount_of(@commission),
        early_payment_discount: @discount_of(@early_payment_discount),
        target_discount: @discount_of(@target_discount),
        monthly_discount_percent: @discount_of(@monthly_discount_percent),
        quarter_discount_percent: @discount_of(@quarter_discount_percent),
        annually_discount_percent: @discount_of(@annually_discount_percent),
        additional_discount_percent: @discount_of(@additional_discount_percent),
        brand_discount_percent: @discount_of(@brand_discount_percent),
        other_discount_percent: @discount_of(@other_discount_percent),
        expenses_percent: 0,
        taxes_percent: 0
      }


    isValidAttributes: ->
      true


    valid_quantity_discount: ->
      q = round_number(@quantity_discount, 3)
      @quantity_discount != "" and q >= 0


    valid_unit_price: ->
      @unit_price != "" and round_money(@unit_price) > 0


    valid_product: ->
      @product_id isnt '' or @product_id isnt null


    errors: ->
      unit_price: @valid_unit_price
      product: @valid_product
      quantity_discount: @valid_quantity_discount


    isValid: ->
      not _.some(@errors, (v, k)-> v is false)


  methods:
    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      if data.uid is @_uid && parseInt(data.id) isnt @product_id
        @product_id = data.id
        @getProductData()


    getProductData: ->
      @get_product(@product_id).then (product)=>
        unless @id
          @unit_price = product.cost_price
          @warranty_percent = product.warranty_percent
          @warranty_amount = product.warranty_amount
          @vat = product.vat
          @table_tax = product.table_tax
          @percent_discount = product.percent_discount
          @cash_discount = product.early_payment_discount
          @value_discount = product.cash_discount
          @offer_discount = product.offer_discount

      , (response)->
        console.error response


    discount_of: (percent)->
      discount = round_number(percent, 4)
      return Big(0) if discount <= 0
      Big(@total_for_discount_percent).times(discount).div(100)


    viewErrorHandler: (data)->
      console.log data





    classList: ->
      list = {}
      list['error']         = not @isValid
      list["item-#{@_uid}"] = true
      list


    selectContent: (e)->
      Vue.nextTick => e.target.select()


if document.querySelector('.product-cost-equation-form-wrapper')
  product_cost_equation.$mount('.product-cost-equation-form-wrapper')
