$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'

PaymentNumericKeyboard = require './payment-numeric-keyboard.coffee'


module.exports =
  template: '#pos-payment-template'
  props: ['cart_item']

  store: store

  components:
    'payment-numeric-keyboard': PaymentNumericKeyboard

  data: ->
    raw_paid_amount: '0'
    paymentKeyboardKey: null
    client_id: window.default_client_id
    credit_cards: window.credit_cards


  mounted: ->
    @raw_paid_amount = @total if @payment_method?


  watch:
    client_id: (newVal)->
      @$store.commit('setPosClient', {id: @client_id})
      @$store.dispatch('fetchClient').then (data)=>
        console.info 'Client loaded.'

    paid_amount: (newVal)->
      @$store.commit('setPaidAmount', {amount: @paid_amount})

    total: ()->
      @raw_paid_amount = @total if @payment_method?

    paymentKeyboardKey: (key)->
      @pressKey(key)
      @paymentKeyboardKey = null


  computed:
    current_cart_item: -> @$store.state.pos.current_tab.current_cart_item

    paid_amount: -> round_money(@raw_paid_amount)

    remaining_amount: -> @$store.getters.remaining_amount
    change_amount: -> @$store.getters.change_amount

    payment_method: -> @$store.state.pos.current_tab.payment_method
    credit_card_id: -> @$store.state.pos.current_tab.credit_card_id

    sub_total: -> @$store.getters.orderSubTotal
    discount: -> @$store.getters.orderDiscount
    total: -> @$store.getters.orderTotal
    taxes_amount: -> @$store.getters.totalTaxes.toFixed(2)

    tab_status: -> @$store.state.pos.current_tab.status

    can_save_cart: ->
      @saving_order is false and @is_valid

    saving_order: -> @$store.state.pos.saving_order
    is_valid: ->
      @has_payment_method

    has_payment_method: -> @payment_method isnt null


  methods:
    setPaymentMethod: (method)->
      @$store.commit('setPaymentMethod', method)

      if method == 'cash'
        @setCreditCard(null)
        @raw_paid_amount = @total

    setCreditCard: (id)->
      @$store.commit('setCreditCard', id)
      @raw_paid_amount = @total

    saveCart: ->
      if @paid_amount >= @total
        @$store.dispatch('saveCart').then((data)=>
          console.log('Saving Success, doing logic')

          if @tab_status == 'finalized'
            @showReceiptScreen()
            @hidePaymentScreen()
            @setReceiptURL(data.order)

        ).catch((error)=>
          console.log('error: ', error)
          greenPOS.notify_error('Error while saving cart!')
        )

      else
        greenPOS.notify_error("Enter valid Paid Amount")


    setReceiptURL: (order)->
      @receiptURL = "/pos/orders/#{order.id}/receipt"


    pressKey: (key)->
      amount = @raw_paid_amount.toString().split('')
      amount = [] if @raw_paid_amount is '0'

#      console.info "RAW: ", @raw_paid_amount

      if key is 'DEL'
        if amount.length > 1
          amount = amount.slice(0, -1)
        else
          amount = []

      else
        amount.push(key)

      amount = amount.join('')

      console.info("AMOUNT: ", amount)
      @raw_paid_amount = if amount.length then amount else '0'


    hidePaymentScreen: -> @$emit('hide')

    showReceiptScreen: -> @$emit('show-receipt')


#    bind_clients_dropdown: ->
#      $('.js-clients-dropdown').dropdown
#        throttle: 1000
#        apiSettings:
#          cache: false
#          url: '/search/clients?q={query}'
#          debug: false
#        saveRemoteData: false
#        match: 'text'
#        minCharacters: 3
#        showOnFocus: true
#        forceSelection: false
#        selectOnKeydown: false
#        keys:
#          backspace  : 8
#          delimiter  : 188
#          deleteKey  : 46
#          enter      : 13
#          escape     : 27
#          pageUp     : false
#          pageDown   : false
#          leftArrow  : 37
#          upArrow    : 38
#          rightArrow : 39
#          downArrow  : 40
