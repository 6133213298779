$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
store = require './stores/default.coffee'
products_store = require './products_store.coffee'
eventHub = require './event_hub.coffee'

require './directives/ui_dropdown.coffee'
require './directives/date_picker.coffee'


view = new Vue
#  el: '.general-sales-return-form-wrapper'
  store: store
  data:
    items: []
    is_ready: false
    issued_at: window.general_sales_return?.issued_at
    store_id: window.general_sales_return?.store_id
    credit_note_number: window.general_sales_return?.credit_note_number
    contact_id: window.general_sales_return_contact_gid

  components:
    'general-sales-return-item': require('./components/general-sales-return-item.coffee')


  created: ->
    el = $('.general-sales-return-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)

    @contact_id = window.general_sales_return_contact_gid

    products_store.fetchSimpleItems(@).then ->
      if window.general_sales_return_items
        Vue.set(view, 'items', window.general_sales_return_items)
      else
        eventHub.$emit('add-item') if general_sales_return



  watch:
    products_is_ready: (newVal)->
      # if newVal
      #   if window.general_sales_return_items
      #     Vue.set(general_sales_return, 'items', window.general_sales_return_items)
      #   else
      #     general_sales_return.addItem() if general_sales_return


  computed:
    products_is_ready: -> @$store.state.products_is_ready

    total_units: -> (_.filter @items, (i)-> i.selected).length

    order_total: ->
      vm = @
      t = _.reduce @items, (sum, item)->
        if item.selected and vm.itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum.plus(0)
      , Big(0)
      t.toFixed(App.settings.money_round)


    selectedItems: ->
      _.filter(@items, (i)-> !i._destroy) or []


    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(i)

    hasSelectedItems: ->
      @selectedItems.length > 0

    validStore: ->
      @store_id? and @store_id != ""

    validContact: ->
      @contact_id? and @contact_id != ""


    isValid: ->
      @hasSelectedItems and @itemsIsValid and @validStore and @validContact


  methods:
    addItem: (event)->
      item = {unit_price: 0, quantity: 1, unit_of_measure_id: null}
      @items.push item
      event.preventDefault() if event

    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.quantity != "" and q > 0 # and item.store_id?




if document.querySelector('.general-sales-return-form-wrapper')
  view.$mount('.general-sales-return-form-wrapper')
