products_store = require '../../products_store.coffee'
products_cache = require '../../../products_caching.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="field_name('_destroy')">
        <input type="hidden" v-model="item.id"
          :name="field_name('id')">

        <div class="ui field">
            <ui-products-dropdown
              :products='products'
              v-model='item.product_id'
              :name="field_name('product_id')"></ui-products-dropdown>
        </div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="field_name('quantity')"
            class="input-small item-quantity ltr">
        </div>
      </td>
      <td>
        <div class="ui field" >
            <ui-dropdown
              :options='units'
              placeholder='Select unit'
              v-model='item.unit_of_measure_id'
              :name="field_name('unit_of_measure_id')"
              :uid='_uid'

            ></ui-dropdown>
        </div>
      </td>

      <td class="text-left">
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']
  data: ->
    product: null
    units: []

  components:
    'ui-dropdown': require('../../components/ui_dropdown.coffee')
    'ui-products-dropdown': require('../../components/products_ui_dropdown.coffee')

  created: ->
    console.log 'I am ready'
#    eventHub.$on('change-product-id', @change_product_id)

    # fetch data on edit
    @getProductData() if @item.id


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    field_name: (name)->
      "mrp_bill_of_material[items_attributes][#{@_uid}][#{name}]"

    getProductData: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @fetch_units()

      , (response)->
        console.error response


    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      console.log "--PID", data
      if data.uid == @_uid
        @item.product_id = data.id


    removeItem: (index)->
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })

    fetch_units: ->
      console.log('== Fetching Units .......')

      # setting default unit from product
      unless @item.unit_of_measure_id
        default_unit = @product.unit_of_measure
        @item.unit_of_measure_id = default_unit.id if default_unit

      url = "/api/unit_of_measures?product_id=#{@item.product_id}"

      $.get(url, (response)=>
        console.log 'R', response
        @units = response.units
        eventHub.$emit('refresh-dropdown', {units: response.units, uid: @_uid})

      ).fail(->
        console.warn 'Failed to Units'
      )


  watch:
    'item.product_id': (new_id, old_id)->
      if parseInt(new_id) isnt parseInt(old_id)
        @$set(@item, 'product_id', new_id)
        @getProductData()


  computed:
    products: ->
      products_cache.purchase_products

    valid_quantity: ->
      @item.quantity != ""


    valid_product: ->
      !!@item.product_id


    errors: ->
      quantity_present: @valid_quantity
      product: @valid_product


    isValid: ->
      if @item._destory
        true
      else
        not _.some @errors, (v, k)-> v is false


    classList: ->
      # list['duplicated']    = @duplicated
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list['st-item']       = true
      list["item-#{@_uid}"] = true
      list

