store = require '../../stores/default.coffee'
eventHub = require '../../event_hub.coffee'

module.exports =
  template: """
    <div class="pos--tabs d-flex">
      <div class='tabs-list d-flex'>
        <div
           class="tab"
           :class="{'active': current_tab.id == tab.id}"
           v-for="(tab, index) in tabsAsArray"
           @click.prevent="switchTab(tab.id)"
           :tab="tab"
           :key="index"
           :data-tab-id="tab.id"
           draggable="true"
        >
          <span class="ui grey circular label">{{tab.id}}</span>
          Order
        </div>
      </div>

      <div
         class="add-tab"
         @click.prevent="addNewTab"
      >Add</div>
    </div>
  """

  store: store


  mounted: ->
    @$nextTick =>
      @bindDragAndDropListener()


  computed:
    tabs: -> @$store.state.pos.tabs
    tabsAsArray: -> @tabs.toSet().toArray()
    current_tab: -> @$store.state.pos.current_tab
    pos_id: -> @$store.state.pos.pos_id
    pos_session_id: -> @$store.state.pos.pos_session_id


  methods:
    addNewTab: ->
      @$store.commit('addTab')

    closeSession: ->
      console.log('Closing Session!')
      window.location.href = "/pos/sessions/#{@pos_session_id}/close_pos"


    switchTab: (tab_id)->
      @$store.commit('setCurrentTab', {tab_id: tab_id})
      eventHub.$emit('pos-clear-breadcrumb')


    bindDragAndDropListener: ->
      document.addEventListener "dragstart", (event)=>
        console.info "Drag Started"

        return if not event.target.classList.contains('tab')

        @dragged = event.target
        event.target.style.opacity = .5
      , false

      document.addEventListener "dragend", (event)=>
        event.target.style.opacity = ""

      document.addEventListener "dragover", (event)=>
        event.preventDefault()

      document.addEventListener "drop", (event)=>
        console.info "Item Dropped"

        event.preventDefault()
        # move dragged elem to the selected drop target
        console.info "DRoped in : ", event.target.className

        return unless @dragged

        if not event.target.classList.contains("pos--tabs")
          console.info "Dropped Item", @dragged.dataset

          @$store.dispatch('removeTab', {tab_id: @dragged.dataset.tabId})
          @dragged = null
      , false



