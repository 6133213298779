$ = require 'jquery'
Vue = require 'vue'

module.exports =
#      <option value=''>Select Account</option>
  template: """
    <select :name="name" class='rtl search accounts-dropdown' ref='dropdown'>
      <option v-for="item in accounts" :value="item.value" :key="item.value">
        {{item.name}}
      </option>
    </select>
  """
  props: ['accounts', 'name', 'value', 'with_parents']
  data: ->
    dropdown: null


  created: ->
    console.log 'Accounts::DropDownComponent::created'


  mounted: ->
    Vue.nextTick =>
      @init_dropdown()


  destroyed: ->
    console.log 'Accounts::DropDownComponent::destroyed'


  watch:
    value: (newID, oldID)->
      if newID != oldID
        console.log "Accounts::DropDownComponent::change account_id", newID
        if @dropdown
          @dropdown.dropdown('set selected', newID)


  methods:
    _el: -> @$el

    init_dropdown: ->
      @dropdown = $(@$refs.dropdown)

      @dropdown.dropdown({
        match: 'text',
        fullTextSearch: true, # true, false, exact
        minCharacters: 3,

        showOnFocus: false,
        forceSelection: false,
        placeholder: false,
#        selectOnKeydown: false,

        throttle: 700,
        apiSettings: {
          cache: false,
          url: @remoteUrl
        },
        saveRemoteData: false,
        filterRemoteData: true,

        onChange: (value, text, $choice)=>
          console.log(value, text, $choice)

          if parseInt(value) isnt @value
            @$emit('input', value)
          else
            console.log 'Accounts::DropDownComponent: item_add : same id'
      })


      # Initial Setting product id
      if @value
        console.log 'Accounts::DropDownComponent::Initial Setting for account_id'
        @dropdown.dropdown('set selected', @value)


  computed:
    classList: -> {}

    remoteUrl: ->
      url = "/api/fi/accounts?q={query}&dropdown=1"
      url += if @with_parents? then "" else "&without_parents=1"
      url
