localforage = require 'localforage'
Immutable = require 'immutable'


products_cache =
  products_url: "/api/products/simple_list.json?branch_id=#{App.branch_id}"
  products_updates_url: "/api/products/simple_list_updates.json?branch_id=#{App.branch_id}"

  products_cache_key: 'products-cache'
  products_expiration_key: 'products-cache-expiration'
  products_expiration_key: 'products-cache-expiration'

  EXPIRY_UNITS: 60 * 1000 # in minutes
  currentTime: -> Math.floor((new Date().getTime()) / @EXPIRY_UNITS)

  # minutes
  cache_duration: 24 * 60 # one day
  cache_duration_time: -> (@currentTime() + @cache_duration).toString(10)

  loading: false

  products: []
  on_sale_products: []
  purchase_products: []

  loadProducts: (force_load = false)->
    if force_load
      @fetchAndCache()
    else
      @fetchOrCache()

  fetchOrCache: ()->
    @isCacheExpired().then (expired)=>
      if expired
        @fetchAndCache()
      else
        @setProductsFromCache()

  setProductsFromCache: ()->
    @getCachedProducts().then (products_list)=>
      if products_list and Object.keys(products_list).length > 0
        console.log '-- PRODUCTS_CACHE:: PRODUCTS LOADED FROM [CACHE]'
        @setProducts(Object.values(products_list))
      else
        console.warn('NO PRODUCTS IN CACHE!!')
        @fetchAndCache()

  getCachedProducts: (key = @products_cache_key)->
    localforage.getItem(key)

  getExpirationKey: (key = @products_expiration_key)->
    localforage.getItem(key)

  isCacheExpired: ()->
    @getExpirationKey().then (expr)=>
      return true unless expr

      expirationTime = parseInt(expr, 10)

      # // Check if we should actually kick item out of storage
      if @currentTime() >= expirationTime
        console.warn 'PRODUCTS_CACHE:: Products EXPIRED!'
        true
      else
        false

  setProducts: (products)->
    @purchase_products = products.filter((p)=> p.is_service is false)
    @on_sale_products = products.filter((p)=> p.on_sale is true)
    @products = @on_sale_products


  updateProducts: ->
    console.log "PRODUCTS_CACHE:: updateProducts!"
    @fetchUpdates().done (data)=>
      @addToCache(data.new_items) if data.new_items.length
      @updatedCachedItems(data.changed_items) if data.changed_items.length
      @removeCachedItems data.deleted_items if data.deleted_items.length


  fetchProducts: ->
    $.get(@products_url)
      .fail(->
        console.error "PRODUCTS_CACHE:: ERROR FETCHING PRODUCTS for cache"
      )

  fetchUpdates: ->
    $.get(@products_updates_url)
      .fail(->
        console.error "PRODUCTS_CACHE:: ERROR FETCHING PRODUCTS UPDATES!"
      )

  fetchAndCache: ->
    console.log "PRODUCTS_CACHE:: FETCHING PRODUCTS for cache"

    if @loading
      console.warn('PRODUCTS_CACHE --> Loading...')
      return

    @loading = true

    @fetchProducts().done (data)=>
      console.log "PRODUCTS_CACHE:: PRODUCTS FETCHED"

      # vue_products_store.items = products_list
      list = Immutable.OrderedMap data.products.map((i)-> [i.id, i])
      products = list.valueSeq().toArray()
      @setProducts(products)

      @loading = false

      @cacheItems(@products_cache_key, @products_expiration_key, products)


  invalidateCache: (key = @products_cache_key, expiration_key = @products_expiration_key)->
    console.log 'PRODUCTS_CACHE:: Invalidating Products Cache'

    localforage.removeItem(key).then(=>
      localforage.removeItem(expiration_key)
    ).then(=>
      @fetchAndCache()

    ).catch (err)->
      console.error "PRODUCTS_CACHE:: Error while invalidateCache", err


  flushAndLoadExpiredItems: (key = @products_cache_key, exp_key = @products_expiration_key)->
    console.log('Products Cache => flushAndLoadExpiredItems')
    @getExpirationKey(exp_key).then (expr)=>
      if expr
        expirationTime = parseInt(expr, 10)

        # // Check if we should actually kick item out of storage
        if @currentTime() >= expirationTime
          console.warn 'PRODUCTS_CACHE:: Products EXPIRED!'
#          @invalidateCache(key)
          @fetchAndCache()
        else
          console.log 'PRODUCTS_CACHE:: Products in CACHE!'
#          @updateProducts()
      else
        @fetchAndCache()


  addToCache: (products)->
    @getCachedProducts().then((products_list)=>
      return unless products_list

      current_list = Immutable.OrderedMap products_list.map((i)-> [i.id, i])
      new_products_list = Immutable.OrderedMap products.map((i)-> [i.id, i])
      new_list = current_list.concat(new_products_list)

      console.log 'PRODUCTS_CACHE:: Product cache updated with new items ', products

      products = new_list.valueSeq().toArray()
      @setProducts(products)

      @cacheItems(@products_cache_key, @products_expiration_key, products)

#      localforage.setItem(@products_cache_key, @products).then ->
#        console.log 'PRODUCTS_CACHE:: Product cache updated with new items ', products

    ).catch (err)->
      console.error "PRODUCTS_CACHE:: Error while addToCache", products, err


  updatedCachedItems: (products)->
    @getCachedProducts().then((products_list)=>
      return unless products_list

      current_list = Immutable.OrderedMap products_list.map((i)-> [i.id, i])
      updates_list = Immutable.OrderedMap products.map((i)-> [i.id, i])
      new_list = current_list.mergeDeep(updates_list)

      console.log 'PRODUCTS_CACHE:: Product cache updated with ', products

      products = new_list.valueSeq().toArray()
      @setProducts(products)

      @cacheItems(@products_cache_key, @products_expiration_key, products)

#      localforage.setItem(@products_cache_key, @products).then ->
#        console.log 'PRODUCTS_CACHE:: Product cache updated with ', products

    ).catch (err)->
      console.error "PRODUCTS_CACHE:: Error while updatedCachedItems", products, err
      window.ProductsCache.invalidateCache()


  removeCachedItems: (ids)->
    @getCachedProducts().then((products_list)=>
      return unless products_list

      current_list = Immutable.OrderedMap products_list.map((i)-> [i.id, i])
      new_list = current_list.deleteAll(ids)

      products = new_list.valueSeq().toArray()
      @setProducts(products)

      @cacheItems(@products_cache_key, @products_expiration_key, products)

#      localforage.setItem(@products_cache_key, @products).then ->
#        console.log 'PRODUCTS_CACHE:: Product cache updated without ', ids

    ).catch (err)->
      console.error "PRODUCTS_CACHE:: Error while removeCachedItems", ids, err



  cacheItems: (key, expiration_key, items)->
    localforage.setItem(key, items).then(=>
      localforage.setItem(expiration_key, @cache_duration_time())

    ).catch (err)->
      console.error "PRODUCTS_CACHE:: Error while fetchAndCacheProducts", err
      window.ProductsCache.invalidateCache(key, expiration_key)


window.ProductsCache = products_cache
module.exports = products_cache

