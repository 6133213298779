$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
store = require '../stores/default.coffee'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
require '../directives/ui_dropdown.coffee'
require '../directives/selectize_tags.coffee'
require '../directives/date_picker.coffee'


sales_order = new Vue
#  el: '.sales-order-form-wrapper'
  store: store
  data: ->
    items: []
    errors: []
    is_ready: false
    order_number: window.sales_order?.order_number
    contact_id: window.sales_order?.contact_gid
    contact_name: window.sales_order_contact_name
    contact_phone: window.sales_order_contact_phone
    issued_at: window.sales_order?.issued_at
    ship_at: window.sales_order?.ship_at
    notes: window.sales_order?.notes
    cash_discount: window.sales_order?.cash_discount
    tags: window.sales_order?.tags
    default_tags: window.default_tags or []
    contact: null
    form_action: 'save_as_draft'
    currentProduct: {}
    entity_trip_id: window.sales_order?.distribution_entity_trip_id


  components:
    'sales-order-item': require('./components/sales-order-item.coffee')


  mixins: [formSubmitMixin]

  created: ->
    el = $('.sales-order-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)
    eventHub.$on('set:currentProduct', @setCurrentProduct)

    @contact_id = window.sales_order_contact_gid
    products_store.fetchSimpleItems(@).then ->
      if window.sales_order_items
        Vue.set(sales_order, 'items', window.sales_order_items)
      else
        sales_order.addItem() if sales_order


  mounted: ->
    # Vue.nextTick =>
    @bind_contacts_dropdown()
    @bind_items_toggle_btn()
    @bind_tags_list()

    # @$store.dispatch('LOAD_PRODUCTS')
    if $('.sales-order-form-wrapper').length
      @$store.dispatch('LOAD_SALES_POLICY')




  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})

    contact_id: (newVal, oldVal)->
      @handle_contact_segment(newVal)

    products_is_ready: (newVal)->
      if newVal
        if window.sales_order_items
          Vue.set(sales_order, 'items', window.sales_order_items)
        else
          sales_order.addItem() if sales_order


  computed:
    items_count: -> @itemsWithProduct.length

    supply_order: -> @entity_trip_id isnt null

    entity_store: -> window.entity_store

    item_store_id: ->
      if @entity_store then @entity_store.id else null

    # products: -> @$store.state.products
    products_is_ready: -> @$store.state.products_is_ready
    # is_ready: -> @products_is_ready

    policy: -> @$store.state.policy


    order_sub_total: -> @items_sub_total


    order_total_discount: ->
      cash_discount = @round_money(@cash_discount)
      return 0 unless _.isNumber cash_discount

      t = Big(cash_discount).plus(@items_discount)
      t.toFixed(App.settings.money_round)


    total_after_discount: ->
      t = Big(@order_sub_total).minus(@order_total_discount)
      t.toFixed(App.settings.money_round)

    order_due_amount: ->
      Big(@total_after_discount).plus(@order_tax_amount).toFixed(App.settings.money_round)


    order_total: ->
      t = @total_after_discount
      t.toFixed(App.settings.money_round)


    order_tax_amount: ->
      vm = @
      t = _.reduce @itemsWithProduct, (sum, item)->
        if vm.itemIsValid(item)
          tax = round_money(item.tax)
          sub_total = vm.calc_item_sub_total_after_discount(item)
          tax = sub_total.times(tax).div(100)
          sum.plus(tax)
        else
          sum
      , Big(0)
      t.toFixed(App.settings.money_round)


    items_sub_total: ->
      vm = @
      t = _.reduce @itemsWithProduct, (sum, item)->
        if vm.itemIsValid(item)
          sum.plus(vm.calc_item_sub_total(item))
        else
          sum
      , Big(0)
      t.toFixed(App.settings.money_round)


    items_discount: ->
      vm = @
      t = _.reduce @itemsWithProduct, (sum, item)->
        if vm.itemIsValid(item)
          sum.plus(vm.calc_item_discount(item))
        else
          sum
      , Big(0)
      t.toFixed(App.settings.money_round)


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0

    validCashDiscount: ->
      _.isFinite @round_money(@cash_discount)

    validShipAt: ->
      @ship_at? and @ship_at != ""

    validIssuedAt: ->
      @issued_at? and @issued_at != ""

    validOrderNumber: ->
      # @order_number? and @order_number != ""
      true

    validContact: ->
      (@contact_id? and @contact_id != "") || @validNewContact


    validNewContact: ->
      @contact_name? and @contact_name != "" and
        @contact_phone? and @contact_phone != "" and @validContactPhone

    validContactPhone: ->
      @contact_phone.length > 8


    isValidAttributes: ->
      @validShipAt and @validIssuedAt and @validContact and @validCashDiscount

    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    calc_item_sub_total: (item)->
      Big(item.quantity).times(item.unit_price)


    calc_item_discount: (item)->
      discount = round_number(item.discount_percent, 2)
      return Big(0) if discount <= 0

      @calc_item_sub_total(item).times(discount).div(100)


    calc_item_sub_total_after_discount: (item)->
      @calc_item_sub_total(item).minus(@calc_item_discount(item))


    # items_has_enough_stock: ->
    #   deferred = Q.defer()

    #   $.get(settings.validation_url.replace('ID', id))
    #     .done((response)->
    #       deferred.resolve(response)
    #     ).fail(->
    #       console.error "CAN NOT VALIDATE ORDER"
    #     )

    #   deferred.promise


    addItem: (event)->
      item = {
        quantity: 1,
        unit_of_measure_id: null,
        unit_price: 0,
        tax: 0,
        discount_percent: 0,
        product_id: null,
        store_id: @item_store_id,
        valid: true
      }
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      console.log 'Index', data.index
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      item.valid


    bind_tags_list: ->
      $('.tags-list').on 'click', '.tag-item', (e)=>
        el = e.currentTarget
        e.preventDefault()
        console.log 'tag', el.textContent
        # $(el).toggleClass('active')
        @tags_directive.addItem el.textContent


    bind_contacts_dropdown: ->
      vm = @
      noResult = 'Not Found. <a href="/clients/new?name={term}&source=sales-order" data-method="get" data-remote="true" class="add-contact">Add New Contact</a>'

      $('.js-contacts-dropdown').dropdown
        throttle: 1000
        apiSettings:
          cache: false
          url: '/search/contacts?q={query}'
          debug: true
        saveRemoteData: false
        match: 'text'
        minCharacters: 3
        showOnFocus: true
        forceSelection: false
        selectOnKeydown: false
        message:
          noResults: noResult
        onChange: (value, text, $choice)->
          vm.goToFirstProductField()
        keys:
          backspace  : 8
          delimiter  : 188
          deleteKey  : 46
          enter      : 13
          escape     : 27
          pageUp     : false
          pageDown   : false
          leftArrow  : 37
          upArrow    : 38
          rightArrow : 39
          downArrow  : 40


      Vue.nextTick ->
        $('.js-contacts-dropdown').on 'keypress', '.search', (e)->
#          console.log 'shift', e.shiftKey, 'which', e.which
          if e.shiftKey and e.which is 65
            $('.js-contacts-dropdown').find('.add-contact').trigger('click')


      # $.rails.handleRemote($('.js-contacts-dropdown').find('.add-contact'))
      # $('.js-contacts-dropdown').on 'click', '.add-contact', (e)->
      #   e.preventDefault()
      #   console.log 'CLICKED!', $(@).data('term')



    bind_items_toggle_btn: ->
      $(".js-toggle-expand-items").on 'click', (e)->
        e.preventDefault()
        $wrapper = $('.so-files-wrapper')
        $wrapper.toggleClass('fullscreen')

        if $wrapper.hasClass('fullscreen')
          $(this).find('i').addClass('compress')
        else
          $(this).find('i').removeClass('compress')


    handle_contact_segment: (contact_gid)->
      return unless @contact_id

      url = "/api/contacts/#{@contact_id}"
      vm = @

      $.get(url, (response)->
        console.log 'R', response
        vm.$store.commit('SET_CONTACT', {contact: response.contact})
        vm.$store.commit('SET_CONTACT_SEGMENT', {segment: response.segment})
        # vm.handle_contact_segment_change()
      ).fail(->
        console.warn 'Failed to get Contact', vm.contact_id
      )


    handle_contact_segment_change: ->
      return unless @$store.state.segment

      segment_discount = @$store.state.segment.discount_percentage
      _.each @itemsWithProduct, (item)->
        return if item._destroy
        item.discount_percent = segment_discount


    goToPhoneField: (e)->
      e.preventDefault()
      $('#contact_phone').focus().select()
      false

    goToFirstProductField: (e)->
      e and e.preventDefault()

      return if @items[0]?.product_id

      $(".so-item").first().find('.selectized.product-dropdown')[0].selectize.focus()

    selectContent: (e)->
      Vue.nextTick => e.target.select()


    submitWithAction: (action, e)->
      return unless @isValid
      @form_action = action
      console.log action
      Vue.nextTick ->
        $('.js-btn-submit').addClass('js-disabled')
        $('.sales-order-items-form').submit()


    setCurrentProduct: (data)->
      @currentProduct = data
      if @currentProduct.description
        @currentProduct.description = @currentProduct.description.split('\n')


window.sales_order_view = sales_order

#     store.on 'items:remove', (index)->
#       store.get().items.list.splice(index, 1)


if document.querySelector('.sales-order-form-wrapper')
  sales_order.$mount('.sales-order-form-wrapper')
