import consumer from "./consumer";
import CableReady from "cable_ready";

consumer.subscriptions.create("UiUpdatesChannel", {
  connected() {
    console.log('UiUpdatesChannel::Connected')
  },

  disconnected() {

  },

  received(data) {
    console.log('UiUpdates :: DATA => ', data);

    if (data.cableReady) {
      console.log('cableReady operations', data.operations)
      CableReady.perform(data.operations)
    }

    if (App.location_id === +data.location_id) {

      switch (data.notification_type) {
        case 'issue_voucher::append_to_index':
          this.insertHtml('.issue_vouchers-list > tbody', data.html);
          break;
        case 'receipt_voucher::append_to_index':
          this.insertHtml('.receipt_vouchers-list > tbody', data.html);
          break;
        case 'debit_memo::append_to_index':
          this.insertHtml('.debit_memos-list > tbody', data.html);
          break;
        case 'credit_memo::append_to_index':
          this.insertHtml('.credit_memos-list > tbody', data.html);
          break;
        case 'services_invoice::append_to_index':
          this.insertHtml('.services_invoices-list > tbody', data.html);
          break;
      }
    }

  },

  insertHtml(selector, html, where) {
    where = where || 'afterbegin';
    document.querySelector(selector).insertAdjacentHTML(where, html)
  }
});
