$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
_ = require 'lodash/core'

module.exports =
  template: """
    <tr class="form-field">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'sales_order_invoice[items_attributes][' + _uid + '][id]'">

        <div class="fied">
          <input type="checkbox"
            v-model="item.selected" value="on"
            :name="'sales_order_invoice[items_attributes][' + _uid + '][selected]'">
        </div>
      </td>

      <td>
        <div class="ui field item-name">{{ item.product_name }}</div>
        <input
          v-model="item.product_id"
          :name="'sales_order_invoice[items_attributes][' + _uid + '][product_id]'"
          class="input-small" type='hidden'>
        <input
          v-model="item.sales_order_item_id"
          :name="'sales_order_invoice[items_attributes][' + _uid + '][sales_order_item_id]'"
          class="input-small" type='hidden'>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="'sales_order_invoice[items_attributes][' + _uid + '][quantity]'"
            class="text-center item-quantity ltr">
        </div>
      </td>


      <td class='text-center'>
        <div class="not-invoiced-quantity num">{{ not_invoiced_quantity }}</div>
      </td>

      <td class='text-center'>
        <div class="total-quantity num">{{ item.total_quantity }}</div>
      </td>


      <td>
        <div class="ui field">
          <input
            v-model.number="item.unit_price"
            :name="'sales_order_invoice[items_attributes][' + _uid + '][unit_price]'"
            class="text-center item-unit_price ltr">
        </div>
      </td>


      <td>
        <div class="ui field">
          <input type="hidden" v-model="item.tax"
            :name="'sales_order_invoice[items_attributes][' + _uid + '][tax]'">
          <div class="num text-center">%{{ item.tax }}</div>
        </div>
      </td>

      <td class='text-center'>
        <div class="total num">{{ format_money(total.toFixed(3), 3) }}</div>
      </td>
    </tr>
  """
  props: ['item', 'is_taxable']

  created: ->
    console.log 'I am ready'

  destroyed: ->
    console.log 'Destroyed ME'


  watch:
    'item.selected': (selected)->
      console.log 'SELECTED : product', @item.product_id


  computed:
    unit_price_factor: ->
      return Big(1) if @item.product_vat is 0
      Big(1).plus(Big(@item.product_vat).div(100))

    taxed_unit_price: ->
      unit_price = round_number(@item.unit_price, 3)
      Big(unit_price).div(@unit_price_factor)

    taxed_sub_total: ->
      quantity = round_quantity(@item.quantity)
      @taxed_unit_price.times(quantity)

    sub_total: ->
      quantity = round_quantity(@item.quantity)
      unit_price = round_number(@item.unit_price, 3)
      Big(quantity).times(unit_price)

    tax_factor: ->
      return Big(1) if @item.tax is 0
      Big(1).plus(Big(@item.tax).div(100))


    total: ->
      @sub_total.times(@tax_factor)


    not_invoiced_quantity: ->
      @item.not_invoiced_quantity - @item.quantity

    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0 and q <= @item.not_invoiced_quantity

    errors: ->
      quantity: @valid_quantity

    isValid: ->
      not _.some @errors, (v, k)-> v is false
