import {Controller} from "stimulus";
import $ from 'jquery';
// import 'daterangepicker';
export default class extends Controller {
  static values = {
    startDate: String,
    endDate: String,
    time: Boolean,
    // ranges: {type: Object, default: {}}
  };

  connect() {
    this.bindDateRangePicker();
  }

  bindDateRangePicker() {
    $(this.element).daterangepicker({
      template: this.daterange_picker_template,
      autoApply: true,
      timePicker: this.timeValue,
      timePickerIncrement: 10,
      opens: 'left',
      locale: {
        direction: 'rtl',
        format: this.date_format,
        applyLabel: 'تحديد',
        cancelLabel: 'إغلاق',
        fromLabel: 'من',
        toLabel: 'إلى',
        customRangeLabel: 'تحديد فترة',
        firstDay: 6
      },
      startDate: this.startDate,
      endDate: this.endDate,
      ranges: this.ranges
    }, this.callback)
  }

  callback(start, end) {
    let $el = this.element.querySelector('span.date-area')
    let formatted_date = this._format_date(start, this.date_format) + ' - ' + this._format_date(end, this.date_format)

    if ($el)
      $el.html(formatted_date)

    this.element.value = formatted_date
  }

  get startDate() {
    if (this.startDateValue)
      return moment(this.startDateValue, this.date_format).format(this.date_format)
    else
      return moment().startOf('day')
  }

  get endDate() {
    if (this.endDateValue)
      return moment(this.endDateValue, this.date_format).format(this.date_format)
    else
      return moment().endOf('day')
  }

  get ranges() {
    let ranges = {
      'اليوم': [
        moment().startOf('day'),
        moment().endOf('day')
      ],
      'أمس': [
        moment().subtract(1, 'day').startOf('day'),
        moment().subtract(1, 'day').endOf('day')
      ],
      'آخر 7 أيام': [
        moment().subtract(6, 'days').startOf('day'),
        moment().endOf('day')
      ],
      'الشهر الحالي': [
        moment().startOf('month').startOf('day'),
        moment().endOf('month').endOf('day')
      ],
      'الشهر السابق': [
        moment().subtract(1, 'month').startOf('month').startOf('day'),
        moment().subtract(1, 'month').endOf('month').endOf('day'),
      ],
      'الربع الأول': [
        moment().startOf('year').startOf('day'),
        moment().month(2).endOf('month').endOf('day')
      ],
      'الربع الثاني': [
        moment().month(3).startOf('month').startOf('day'),
        moment().month(5).endOf('month').endOf('day')
      ],
      'الربع الثالث': [
        moment().month(6).startOf('month').startOf('day'),
        moment().month(8).endOf('month').endOf('day')
      ],
      'الربع الرابع': [
        moment().month(9).startOf('month').startOf('day'),
        moment().month(11).endOf('month').endOf('day')
      ],
      'السنة الحالية': [
        moment().startOf('year').startOf('day'),
        moment().endOf('year').endOf('day')
      ],
      'السنة السابقة': [
        moment().subtract(1, 'year').startOf('year').startOf('day'),
        moment().subtract(1, 'year').endOf('year').endOf('day')
      ],
    }

    return ranges
  }

  get daterange_picker_template() {
    return `<div class="daterangepicker dropdown-menu"><div class="ranges"><div class="range_inputs"><button class="applyBtn" disabled="disabled" type="button"></button> <button class="cancelBtn" type="button"></button></div></div><div class="calendar left"><div class="daterangepicker_input ui input field"><input class="input-mini js-disabled" type="text" name="daterangepicker_start" value="" /><i class="icon calendar"></i><i class="icon time time-toggle js-time-toggle"></i><div class="calendar-time"><div></div><i class="icon time"></i></div></div><div class="calendar-table"></div></div><div class="calendar right"><div class="daterangepicker_input ui input field"><input class="input-mini js-disabled" type="text" name="daterangepicker_end" value="" /><i class="icon calendar"></i><i class="icon time time-toggle js-time-toggle"></i><div class="calendar-time"><div></div><i class="icon time"></i></div></div><div class="calendar-table"></div></div></div>`
  }

  _format_date(date, format = this.date_format) {
    return date.format(format)
  }

  get date_format() {
    if (this.timeValue == true)
      return 'DD/MM/YYYY h:mm A'
    else
      return 'DD/MM/YYYY'
  }

  get type() {
    return this.typeValue == 'notice' ? 'success' : 'error';
  }

  hide() {
    this.element.remove()
  }

  disconnect() {
  }
}
