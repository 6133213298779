Vue = require('vue')
_ = require 'lodash/core'

Vue.mixin({
  methods:
    round_number: (n, precision = 0) ->
      number = if _.isNumber n
        n.toFixed(precision)
      else if _.isString(n)
        parseFloat(n, 10).toFixed(precision)
      else if typeof n is 'object' # Big()
        n.valueOf()
      else
        console.warn "ROUND_NUMBER: Number is undefined", n
        0

      ret = parseFloat(number, 10)
      unless _.isFinite(ret)
        console.warn "ROUND_NUMBER: Number is NaN", n
      ret

    format_number: (n, precision = 0) ->
      numeral(n)
        .format('0,0.' + Array(precision + 1).join('0'))

    round_money: (n, precision = App.settings.money_round)->
      @round_number n, precision

    format_money: (n, precision = App.settings.money_round) ->
      numeral(@round_number(n, precision))
        .format('0,0.' + Array(precision + 1).join('0'))

    round_quantity: (n)->
      @round_number n, App.settings.quantity_round

    format_quantity: (n, precision = App.settings.quantity_round)->
      numeral(@round_number(n, precision))
        .format('0,0.' + Array(precesion + 1).join('0'))
})

#hasRx = (vm)->
#  unless Rx
#    console.warn(
#      '$watchAsObservable requires Rx to be present globally or ' +
#      'be passed to Vue.use() as the second argument.',
#      vm
#    )
#    return false
#
#  return true
#
#
#Vue.prototype.$watchAsObservable = (expOrFn, options)->
#  return unless hasRx()
#
#  vm = this
#  obs$ = Rx.Observable.create (observer)->
#    watch = ->
#      _unwatch = vm.$watch expOrFn, (newValue, oldValue)->
#        observer.next({ oldValue: oldValue, newValue: newValue })
#      , options
#
#    unwatch = ->
#      _unwatch && _unwatch()
#
#
#    # // if $watchAsObservable is called inside the subscriptions function,
#    # // because data hasn't been observed yet, the watcher will not work.
#    # // in that case, wait until created hook to watch.
#    if vm._data
#      watch()
#    else
#      vm.$once('hook:created', watch)
#
#    # // Returns function which disconnects the $watch expression
#    if Rx.Subscription # Rx5
#      disposable = new Rx.Subscription(unwatch)
#    else # Rx4
#      disposable = Rx.Disposable.create(unwatch)
#
#    return disposable
#
#
#  (vm._obSubscriptions || (vm._obSubscriptions = [])).push(obs$)
#  obs$

