$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'

products_store = require '../products_store.coffee'

require '../directives/ui_dropdown.coffee'


purchase_order_return = new Vue
#  el: '.purchase-order-return-form-wrapper'
  data:
    items: window.purchase_order_return_items || []
    is_ready: false
    store_id: window.purchase_order_return?.store_id
    debit_note_number: window.purchase_order_return?.debit_note_number
    voucher_number: window.purchase_order_return?.voucher_number

  components:
    'purchase-order-return-item': require('./components/purchase-order-return-item.coffee')


  created: ->
    el = $('.purchase-order-return-items-wrapper')
    return unless el.length


  computed:
    total_units: -> (_.filter @items, (i)-> i.selected).length

    order_total: ->
      vm = @
      t = _.reduce @items, (sum, item)->
        if item.selected and vm.itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum.plus(0)
      , Big(0)
      t.toFixed(App.settings.money_round)


    selectedItems: ->
      _.filter(@items, (i)-> i.selected) or []


    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(i)


    hasSelectedItems: ->
      @selectedItems.length > 0


    valid_debit_note_number: ->
      @debit_note_number? and @debit_note_number != ""


    isValid: ->
      @valid_debit_note_number and @store_id? and @hasSelectedItems and @itemsIsValid


  methods:
    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.quantity != "" and q > 0 and
        q <= round_quantity(item.not_returned_quantity)



if document.querySelector('.purchase-order-return-form-wrapper')
  purchase_order_return.$mount('.purchase-order-return-form-wrapper')
