$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
eventHub = require '../event_hub.coffee'


hr_application_form = new Vue
  data:
    questions: window.application_form_questions or []
    is_ready: true
    name: window.application_form?.name

  components:
    'application-form-question': require('./components/application-form-question.coffee')


  created: ->
    eventHub.$on('add-item', @addQuestion)


  computed:
    questionsIsValid: ->
      vm = @
      not _.some @questions, (i)-> not vm.itemIsValid(i)


    validName: ->
      @name? and @name != ""


    isValid: ->
      @questionsIsValid and @validName


  methods:
    addQuestion: (event)->
      question = {name: null, required: false, locked: false, field_type: 'question'}
      @questions.push question
      event.preventDefault() if event


    itemIsValid: (question)->
      question.name isnt "" and question.name isnt null and question.name?.length > 2



selector = '.hr-application-form-wrapper'
hr_application_form.$mount(selector) if document.querySelector(selector)
