import { Controller } from "stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["card"];
  static values = { srcElement: String, srcUrl: String, isRight: Boolean };

  connect() {
    console.log('SIDEBAR connected')

    this.backdrop = document.querySelector('.sidebar-details-backdrop');
    this.wrapper = document.querySelector('.sidebar-details-wrapper');
    this.sidebarDetails = document.querySelector('.sidebar-details-wrapper .sidebar-details');

    // this.attachBackdropEvent();
    if (this.isRightValue) {
      this.sidebarDetails.classList.add('is-right');
    }
  }

  initialize() {
  }

  async show() {

    if (this.hasSrcUrlValue) {
      const response = await get(this.srcUrlValue, {
        // responseKind: "html"
      });

      if (response.ok) {
        const html = await response.html;
        this.setSidebarContent(html)
      }

    } else if (this.hasSrcElementValue) {
      const srcElement = document.querySelector(this.srcElementValue);
      this.setSidebarContent(srcElement.innerHTML)
    }

    this.wrapper.classList.add('is-open');
  }

  setSidebarContent(content) {
    this.sidebarDetails.innerHTML = content;
  }

  hide() {
    console.log('HIDING..')
    this.wrapper.classList.remove('is-open');
    this.setSidebarContent('');
  }

  attachBackdropEvent() {
    this.backdrop.addEventListener('click', (event)=> {
      event.preventDefault();
      this.hide();
    }, false);
  }

  disconnect() {
    // this.backdrop.removeEventListener('click', (event)=> {
    //   event.preventDefault();
    //   this.hide();
    // }, false);
  }
}
