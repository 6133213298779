$ = require 'jquery'
Vue = require 'vue'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
_ = require 'lodash/core'

require '../directives/ui_dropdown.coffee'

bill_of_material = new Vue
  data:
    items: []
    operations: []
    qc_items: []
    is_ready: false
    name: window.bill?.name || null
    quantity: window.bill?.quantity || 1
    product: null
    product_id: window.bill?.product_id || null
    unit_of_measure_id: window.bill?.unit_of_measure_id || null
    units: []

  components:
    'bill-of-material-item': require('./components/bill_of_material_item.coffee')
    'bill-of-material-operation': require('./components/bill_of_material_operation.coffee')
    'bill-of-material-qc-item': require('./components/bill_of_material_qc_item.coffee')
    'ui-dropdown': require('../components/ui_dropdown.coffee')


  mixins: [formSubmitMixin]


  created: ->
    el = $('.bill-of-materials-form-wrapper')
    return unless el.length

    # fetch product data on edit
    @getProductData(@product_id) if @product_id

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('remove-operation', @removeOperation)

    @operations = window.operations
    @qc_items = window.qc_items

    products_store.fetchSimpleItems(@)
      .then =>
        if window.bill_items
          Vue.set(@, 'items', window.bill_items)
        else
          @addItem()


  computed:
    count: -> @items.length


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    isValidAttributes: ->
      @product_id isnt null and @name isnt null


    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    getProductData: (product_id)->
      @get_product(product_id).then (product)=>
        @product = product
        @fetch_units()

      , (response)->
        console.error response


    get_product: (product_id)->
      products_store.fetchItem(product_id)

    fetch_units: ->
      console.log('== Fetching Units .......')

      #     # setting default unit from product
      unless @unit_of_measure_id
        default_unit = @product.unit_of_measure
        @unit_of_measure_id = default_unit.id if default_unit

      url = "/api/unit_of_measures?product_id=#{@product_id}"

      $.get(url, (response)=>
        console.log 'R', response
        @units = response.units
        eventHub.$emit('refresh-dropdown', {units: response.units, uid: @_uid})

      ).fail(->
        console.warn 'Failed to Units'
      )

    addItem: (event)->
      item = {product_id: null, quantity: 1, _destroy: null}
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)

    addQcItem: (event)->
      item = {name: null, _destroy: null}
      @qc_items.push item
      event and event.preventDefault()

    removeQcItem: (data)->
      @qc_items.splice(data.index, 1)


    addOperation: (event)->
      position = @operations.length + 1;

      item = {
        name: null, work_center_id: null,
        duration_in_minutes: 1, description: null,
        instructions_pdf: null,
        position: position,
        _destroy: null
      }

      @operations.push item
      event and event.preventDefault()


    removeOperation: (data)->
      @operations.splice(data.index, 1)


    itemIsValid: (item)->
      q = @round_quantity(item.quantity)
      item.product_id isnt null and item.quantity != "" and q > 0


  events:
    'remove-item': (index)-> @removeItem(index)
    'remove-operation': (index)-> @removeOperation(index)
    'remove-qc': (index)-> @removeQcItem(index)

  watch:
    product_id: (id)->
      console.log('Product changed!')
      @getProductData(id)


if document.querySelector('.bill-of-materials-form-wrapper')
  bill_of_material.$mount('.bill-of-materials-form-wrapper')
