$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'
require '../directives/date_picker.coffee'
_ = require 'lodash/core'


debit_memo = new Vue
#  el: '.debit-memo-form-wrapper'
  data: ->
    is_ready: false
    tax_office_id: window.debit_memo?.tax_office_id
    owner_id: window.debit_memo?.owner_id
    contact_id: window.contact_gid
    issued_at: window.debit_memo?.issued_at
    reference: window.debit_memo?.reference
    amount: window.debit_memo?.amount


  computed:
    isValidContact: ->
      @tax_office_id != null and @tax_office_id isnt ""


    isValidAttributes: ->
      @isValidContact and @owner_id != null


    isValid: ->
      @isValidAttributes



if document.querySelector('.debit-reconciliation-form-wrapper')
  debit_memo.$mount('.debit-reconciliation-form-wrapper')
