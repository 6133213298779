Vue = require 'vue'
eventHub = require '../../event_hub.coffee'

module.exports =
  template: """
    <table class="numeric-keyboard cart-keyboard ltr">
      <tr>
        <td class="key" data-key="1" @click="pressKey(1)">1</td>
        <td class="key" data-key="2" @click="pressKey(2)">2</td>
        <td class="key" data-key="3" @click="pressKey(3)">3</td>
      </tr>
      <tr>
        <td class="key" data-key="4" @click="pressKey(4)">4</td>
        <td class="key" data-key="5" @click="pressKey(5)">5</td>
        <td class="key" data-key="6" @click="pressKey(6)">6</td>
      </tr>
      <tr>
        <td class="key" data-key="7" @click="pressKey(7)">7</td>
        <td class="key" data-key="8" @click="pressKey(8)">8</td>
        <td class="key" data-key="9" @click="pressKey(9)">9</td>
      </tr>
      <tr>
        <td class="key" data-key="0" @click="pressKey(0)">0</td>
        <td colspan="2" class="key del-cell" data-key="DEL" @click="pressKey('DEL')">DEL</td>
      </tr>
    </table>
  """

  props: ['value']


  methods:
    pressKey: (key)->
      @$emit('input', key)



