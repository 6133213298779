$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
store = require '../stores/default.coffee'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
require '../directives/ui_dropdown.coffee'


assign_order_stores = new Vue
  store: store
  data: ->
    items: []
    errors: []
    is_ready: false
    order_number: window.sales_order?.order_number
    form_action: 'save'
    currentProduct: {}


  components:
    'assign-order-store-item': require('./components/assign-order-store-item.coffee')


  mixins: [formSubmitMixin]

  created: ->
    el = $('.assign-sales-order-stores-form-wrapper')
    return unless el.length

    eventHub.$on('add-item', @addItem)
    eventHub.$on('set:currentProduct', @setCurrentProduct)

    products_store.fetchSimpleItems(@).then ->
      if window.sales_order_items
        Vue.set(assign_order_stores, 'items', window.sales_order_items)


  mounted: ->
    # do nothing



  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})

    products_is_ready: (newVal)->
      if newVal
        if window.sales_order_items
          Vue.set(assign_order_stores, 'items', window.sales_order_items)


  computed:
    items_count: -> @itemsWithProduct.length

    products_is_ready: -> @$store.state.products_is_ready

    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        key = "#{item.product_id}:#{item.store_id}"
        if data[key]
          data[key].count += 1
        else
          data[key] = {product_id: item.product_id, store_id: item.store_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0

    isValidAttributes: ->
      true

    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    duplicate_item: (item)->
      console.log('ITEM TO DUP: ', item)
      new_item = {
        quantity: 1,
        unit_of_measure_id: item.unit_of_measure_id,
        unit_price: item.unit_price,
        discount_percent: item.discount_percent,
        product_id: item.product_id,
        store_id: null,
        valid: true,
        clone: true
      }
      @items.push new_item


    remove_item: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      item.valid

    selectContent: (e)->
      Vue.nextTick => e.target.select()


    submitWithAction: (action, e)->
      return unless @isValid
      @form_action = action
      console.log action
      Vue.nextTick ->
        $('.js-btn-submit').addClass('js-disabled')
        $('.sales-order-items-form').submit()


    setCurrentProduct: (data)->
      @currentProduct = data
      if @currentProduct.description
        @currentProduct.description = @currentProduct.description.split('\n')


window.assign_order_stores = assign_order_stores

el = document.querySelector('.assign-sales-order-stores-form-wrapper')
assign_order_stores.$mount(el) if el
