$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
products_store = require '../../products_store.coffee'
products_cache = require '../../../products_caching.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
UnitOfMeasureHandlers = require '../../mixins/unit_of_measure_handlers.coffee'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <tr class="form-field order-item" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'purchase_order[items_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="item._destroy"
          :name="'purchase_order[items_attributes][' + _uid + '][_destroy]'">

        <div class="ui field" v-if='!product' key='products-dropdown'>
          <ui-dropdown
              :products='products'
              v-model='item.product_id'
              :name="'purchase_order[items_attributes][' + _uid + '][product_id]'"></ui-dropdown>
        </div>

        <div class="ui field" key='product-name' v-else>
          <input type="hidden" v-model="item.product_id"
            :name="'purchase_order[items_attributes][' + _uid + '][product_id]'">
          <span class='product-name p-2'>
            {{ product.name }}
          </span>
        </div>
      </td>

      <td>
        <div class=' fields mb-0'>
          <div class="ui field" :class="{ 'error': !valid_quantity }">
            <input v-model.number="item.quantity"
              :name="'purchase_order[items_attributes][' + _uid + '][quantity]'"
              @keydown.enter.prevent
              @keydown.up="prevItem"
              @keydown.down="nextItem"
            class="input-small item-quantity ltr">
          </div>
          <input type='hidden' v-model='item.unit_of_measure_id'
              :name="'purchase_order[items_attributes][' + _uid + '][unit_of_measure_id]'" />
          <span class="p-2 d-inline-block">{{product && product.purchase_uom.name}}</span>

        </div>
      </td>

      <td>
        <div class="stock-on-hand num">{{ stock_on_hand }}</div>
      </td>

      <td>
        <div class="stock-after num">{{ stock_after }}</div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_unit_price }">
          <input v-model="item.unit_price" :name="'purchase_order[items_attributes][' + _uid + '][unit_price]'" class="input-small item-unit-price">
        </div>
      </td>

      <td>
        <div class="total num">{{total}}</div>
      </td>


      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  mounted: ->
    console.log "POI Mounted!"

  mixins: [inputKeyHandlers, UnitOfMeasureHandlers]

  components:
    'ui-dropdown': require('../../components/products_ui_dropdown.coffee')

  props: ['index', 'item']

  data: ->
    stock_on_hand: 0
    duplicated: false
    dropdown: null
    product: null

  created: ->
    console.log 'POI: I am ready'
    eventHub.$on('duplicated-items', @viewErrorHandler)
    # fetch data on edit
    @getProductData() if @item.id


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")

    get_product: (product_id)->
      products_store.fetchItem(product_id)

    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    store_id: ->
      parseInt $('#purchase_order_store_id').val()


    getProductData: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @setDefaultUoM(@product.purchase_uom)
        @updateUnitPrice(@product.cost_price) if @item.unit_price is 0

        stock = product.stores_list.find((i) => i.store_id is +@store_id())
        @stock_on_hand = round_quantity(stock.quantity) if stock

      , (response)->
        console.error response


    viewErrorHandler: (data)->
      vm = @
      found = data.items.find((i)=> i.product_id is vm.item.product_id)
      @duplicated = if found then true else false


    scroll_to_current_element: (data)->
      el = data.el
      return unless el

      $scroll_element = $('.page-grid')
      # body_scrollTop = $('body').scrollTop()
      body_scrollTop = $scroll_element.scrollTop()
      # el_offsetTop = @_el().offset().top
      # el_offsetTop = @_el().get(0).offsetTop
      el_offsetTop = el.get(0).offsetTop

      # console.debug 'el_offsetTop', el_offsetTop
      # console.debug 'body_scrollTop', body_scrollTop

      position_on_viewport = el_offsetTop - body_scrollTop + 600
      mid_viewport = window.innerHeight/2

      # console.debug 'position_on_viewport', position_on_viewport
      # console.debug 'mid_viewport', mid_viewport

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        # $("html,body").stop().animate({
        $scroll_element.stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 1000).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


  watch:
    'unit_of_measure': ()->
      @updateUnitPrice(@product.cost_price)

    'item.product_id': (new_id, old_id)->
      if old_id is null
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})

      if parseInt(new_id) isnt parseInt(old_id)
        @$set(@item, 'product_id', new_id)
        @getProductData()
        @focusOnQuantity()


  computed:
    products: ->
      products_cache.purchase_products

    total: ->
      if @isValid
        Big(@item.quantity).times(@item.unit_price)
          .toFixed(App.settings.money_round)
      else
        0


    stock_after: ->
      Big(round_quantity(@stock_on_hand)).plus(round_quantity(@item.quantity))
        .toFixed(App.settings.quantity_round)


    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @product and @item.quantity != "" and q > 0


    valid_unit_price: ->
      @product and @item.unit_price != "" and round_money(@item.unit_price) >= 0


    valid_product: ->
      !!@item.product_id


    errors: ->
      quantity: @valid_quantity
      unit_price: @valid_unit_price
#      product: @valid_product


    isValid: ->
      if @item._destory
        true
      else
        not Object.values(@errors).some((v)=> v is false)


    classList: ->
      list = {}
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list
