products_store = require '../../products_store.coffee'
products_cache = require '../../../products_caching.coffee'
eventHub = require '../../event_hub.coffee'
debounce = require 'debounce'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="field_name('_destroy')">
        <input type="hidden" v-model="item.id"
          :name="field_name('id')">

        <div class="ui field" key='products-dropdown' v-if='!product'>
            <ui-products-dropdown
              :products='products'
              v-model='item.product_id'
              :name="field_name('product_id')"></ui-products-dropdown>
        </div>

        <div class="ui field" key='product-name' v-else>
          <input type="hidden" v-model="item.product_id"
               :name="field_name('product_id')">
          <span class='text-lg'>{{product.name}}</span>
        </div>
      </td>
      <td>
        <div class="ui field">
          <input
            v-model.number="item.to_consume_quantity"
            :name="field_name('to_consume_quantity')"
            class="input-small item-quantity ltr"
            :disabled='item.id'>
        </div>
      </td>
      <td>
        <div class="ui field" >
            <ui-dropdown
              :options='units'
              placeholder='Select unit'
              v-model='item.unit_of_measure_id'
              :name="field_name('unit_of_measure_id')"
              :uid='_uid'
              :disabled='item.id'
              @input='change_unit'
            ></ui-dropdown>
        </div>
      </td>
      <td class="text-left">
        <div class="" v-if='isDevelopment'>
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']

  data: ->
    product: null
    units: []
    isDevelopment: window.App.isDevelopment
    valid_stock: true

  components:
    'ui-dropdown': require('../../components/ui_dropdown.coffee')
    'ui-products-dropdown': require('../../components/products_ui_dropdown.coffee')


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Component [ProductionOrderComponent]!"

  created: ->

    # fetch data on edit
    @getProductData() if @item.id

    @debounced_check_stock = debounce(@check_stock, 500)


  watch:
    'item.product_id': (new_id, old_id)->
      return if parseInt(new_id) is parseInt(old_id)

      @$set(@item, 'product_id', new_id)
      @getProductData()

    'item.to_consume_quantity': ->
      @do_check_stock()

    valid: (valid)->
      @item.valid = valid


  computed:
    products: ->
      products_cache.purchase_products

    valid: ->
      [
        @item.to_consume_quantity?,
        round_quantity(@item.to_consume_quantity) > 0,
        @valid_stock
      ].every((c)=> c is true)

    classList: ->
      list = {}
      list['item']          = true
      list["item-#{@_uid}"] = true
      list['hidden']        = @item._destroy
      list['positive']        = @valid_stock and @valid
      list['negative']        = not(@valid_stock and @valid)
      list


  methods:
    field_name: (name)->
      "mrp_production_order[components_attributes][#{@_uid}][#{name}]"

    getProductData: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @fetch_units()

      , (response)->
        console.error response


    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      console.log "--PID", data
      if data.uid == @_uid
        @item.product_id = data.id


    fetch_units: ->
      console.log('== Fetching Units .......')

      # setting default unit from product
      unless @item.unit_of_measure_id
        default_unit = @product.unit_of_measure
        @item.unit_of_measure_id = default_unit.id if default_unit

      url = "/api/unit_of_measures?product_id=#{@item.product_id}"

      fetch(url, headers: App.auth_token_headers)
        .then((r) => r.json())
        .then((response)=>
          console.log 'R', response
          @units = response.units
          eventHub.$emit('refresh-dropdown', { units: response.units, uid: @_uid })

          @do_check_stock()
        )

    removeItem: (index)->
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })

    do_check_stock: ->
      if @product and round_number(@item.to_consume_quantity) > 0
        @check_stock()

    check_stock: ()->
      console.log('Checking Stock')

      data = {item: @item}

      fetch(window.check_stock_url, {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      })
        .then((r) => r.json())
        .then((result) =>
          console.log('Checkking result', result)
          @valid_stock = result.valid
          if not result.valid
            greenPOS.notify_error("Stock not available for #{@product.name}.")
        )

    change_unit: (id)->
      console.log('EVENT', id)
      @item.unit_of_measure_id = id
      @check_stock()
