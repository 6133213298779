$ = require 'jquery'
Vue = require 'vue'
products_store = require '../../products_store.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'

module.exports =
  props: ['item', 'index', 'can_edit', 'reservation_id', 'duplicated_items']

  template: """
    <tr class="form-field ui form" :class="classList" v-if='item.new_record'>
      <td>
        <input type="hidden" v-model="item.id" :name="getFieldName('id')">

        <div class="ui field">
          <template v-if='!product'>
            <select v-ui-dropdown="item.product_id"
              :name="getFieldName('product_id')"
              :data-uid="_uid"
              class='product-dropdown'></select>
          </template>

          <template v-else>
            <input type="hidden" v-model="item.product_id"
              :name="getFieldName('product_id')"
            <span class='order-item--product-name'>
              {{ product.name }}
            </span>
          </template>
        </div>

      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="getFieldName('quantity')"
            class="ltr">
        </div>
      </td>

      <td>
        <div class="ui field" :class="{}">
          <input
            v-model.number="item.unit_price"
            :name="getFieldName('unit_price')"
            class="ltr">
        </div>
      </td>

      <td class="text-left">
        <div>
          <a class="ui icon green button" @click="save" v-show='isValid'>
            Save
            <i class="icon plus"></i>
          </a>

          <a class="ui icon basic button" @click="cancel(index)">
            Cancel
            <i class="icon times"></i>
          </a>
        </div>
      </td>
    </tr>

    <tr v-else>
      <td v-if='product'>{{ product.name }}</td>
      <td>{{ format_number(item.quantity, 3) }}</td>
      <td>{{ format_number(item.unit_price, 2) }}</td>
      <td class="text-left">
        <div v-show='can_edit'>
          <a class="ui icon red button" @click.prevent="destroy(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>

  """


  data: ->
    product: null


  created: ->
    console.log 'CREATED:: sales-order-reservation-item'
    eventHub.$on('change-product-id', @change_product_id)


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    getFieldName: (name)->
      "item[#{@_uid}][#{name}]"


    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      if data.uid == @_uid
        @item.product_id = data.id


    removeItem: (index)->
      eventHub.$emit('remove-item', { index: index })


    cancel: (index)->
      @removeItem(index)


    save: ->
      # save me
      console.log("SAVING ITEM...")

      data = {
        reservation_id: @reservation_id,
        item: @item
      }

      $.ajax
        url: "/sales_order_reservation_items"
        dataType: 'json'
        type: 'POST'
        data: data,
        error: (error)-> console.error(error)
        success: (res)=>
          console.log("ITEM ADDED: ", res)

          @item.new_record = false
          @item.id = res.id
          eventHub.$emit('update-total')


    destroy: (index)->
      $.ajax
        url: "/sales_order_reservation_items/#{@item.id}"
        dataType: 'json'
        type: 'POST'
        data: { _method: "delete" },
        error: (error)-> console.error(error)
        success: (res)=>
          console.log("ITEM DELETED: ", res)
          @removeItem(index)
          eventHub.$emit('update-total')


  watch:
    'item.product_id':
      immediate: true
      handler: (new_id, old_id)->
        return unless new_id
        @get_product(new_id).then (product)=>
          @product = product
          @item.unit_price = @round_money product.cost_price

        , (response)->
          console.error response


  computed:
    valid_quantity: ->
      @item.quantity != ""


    valid_product: ->
      !!@item.product_id


    duplicated: ->
      console.log("DUPLICATED!")
      product_id = Number @item.product_id
      @duplicated_items.find (i)=> product_id is i.product_id


    errors: ->
      quantity_present: @valid_quantity
      product: @valid_product
      duplicated: @duplicated is undefined


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["item-#{@_uid}"] = true
      list

