export function visible(target) {
  return !(target.hidden || target.closest("[hidden]"))
}

export function resetInput(target) {
  target.value = ""
  target.dispatchEvent(new CustomEvent("input", { bubbles: true }))
}

export function cancel(event) {
  event.stopPropagation()
  event.preventDefault()
}

export function addToTabOrder(element) {
  element.setAttribute("tabindex", "0")
}

export function removeFromTabOrder(element) {
  element.setAttribute("tabindex", "-1")
};
