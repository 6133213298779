import CableReady from 'cable_ready'
import consumer from './consumer'

consumer.subscriptions.create('FiReportsChannel', {
  connected() {
    console.info("FiReportsChannel connected")
  },
  received (data) {
    console.log('Ops: ', data)
    if (data.cableReady) {
      console.log('operations', data.operations)
      CableReady.perform(data.operations)
    }
  }
})
