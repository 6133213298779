Big = require 'big.js'

class ProductTaxesCalculator
  constructor: (item)->
    @item = item
    # @apply_discount_on = apply_discount_on # 'total_with_taxes' # or net_total


  isValid: -> @item.valid


  available_quantity: ->
    Big(@item.quantity).minus(@item.quantity_discount)


  quantity_discount_value: ->
    quantity_discount = round_number(@item.quantity_discount)
    return Big(0) if quantity_discount is 0
    Big(quantity_discount).times(@item.unit_price)


  percent_discount_of: (value)->
    percent_discount = round_number(@item.percent_discount)
    Big(value).times(percent_discount).div(100)


  sub_total: ->
    if @isValid()
      Big(@available_quantity())
        .times(@item.unit_price)
        .toFixed(App.settings.money_round)
    else
      0


  total_with_taxes: ->
    total = Big(@sub_total())
    # if @apply_discount_on == 'total_with_taxes'
    #   total.minus(@percent_discount_of(total))
    # else
    #   total


  total_without_taxes: ->
    Big(@total_with_taxes())
      .times(@vat_factor())
      .times(@table_tax_factor())
      .times(@warranty_percent_factor())
      .minus(@warranty_amount_value())


  net_total: ->
    total = @total_without_taxes()
    # if @apply_discount_on == 'net_total'
    #   total.minus(@percent_discount_of(total))
    # else
    #   total


  vat_factor: ->
    factor = Big(@item.vat).div(100).plus(1)
    Big(1).div(factor)


  warranty_percent_factor: ->
    return Big(1) if @has_warranty_percent() and @has_table_tax()

    factor = Big(@item.warranty_percent).div(100).plus(1)
    Big(1).div(factor)


  table_tax_factor: ->
    return Big(1) unless @has_table_tax()

    factor = if @has_table_tax() and @has_warranty_percent()
      Big(@item.warranty_percent).plus(@item.table_tax).div(100).plus(1)
    else if @has_table_tax()
      Big(@item.table_tax).div(100).plus(1)
    else
      1
    Big(1).div(factor)


  warranty_percent_value: ->
    return Big(0) unless @has_warranty_percent()
    Big(@total()).times(@item.warranty_percent).div(100)


  warranty_amount_value: ->
    return Big(0) if @item.warranty_amount <= 0
    Big(@item.warranty_amount).times(@available_quantity()).div(100)


  warranty_value: ->
    if @has_warranty_percent()
      @warranty_percent_value()
    else
      @warranty_amount_value()


  vat_value: ->
    return Big(0) unless @has_vat()
    Big(@total())
      .plus(@table_tax_value())
      .plus(@warranty_value())
      .times(@item.vat).div(100)


  table_tax_value: ->
    return Big(0) unless @has_table_tax()
    Big(@total()).times(@item.table_tax).div(100)


  has_vat: ->
    round_number(@item.vat) > 0


  has_table_tax: ->
    round_number(@item.table_tax) > 0


  has_warranty_percent: ->
    round_number(@item.warranty_percent) > 0



module.exports = ProductTaxesCalculator
