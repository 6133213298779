$ = require 'jquery'
Selectize = require 'selectize'
Vue = require 'vue'

eventHub = require '../event_hub.coffee'

Vue.directive 'selectize-tags',
  params: ['tags']

  # inserted: (el)->
  #   console.log 'INSERTED', el

  componentUpdated: (el)->
    console.log 'Tags Component Updated!'

  bind: (el, binding, vnode)->
    # console.log "ITEMS inside directive", products_store.items.length
    # console.log 'EL', el, binding

    # plugins: ['dropdown_direction'],
    Vue.nextTick ->
      $dropdown = $(el).selectize
        dropdownDirection: 'auto'
        options: vnode.context.default_tags
        maxOptions: 100
        delimiter: ','
        persist: false
        valueField: 'tag'
        labelField: 'tag'
        searchField: 'tag'
        create: (input)-> {tag: input}

      $dropdown = $dropdown[0].selectize

      vnode.context.tags_directive = $dropdown

      $dropdown.on 'item_add', (val, $item)->
        console.log 'item_add', val
        eventHub.$emit('add-tag', { tag: val })
        tags = vnode.context.tags
        tags = if tags.length then tags.split(',') else []
        tags.push val
        vnode.context.tags = tags.join ','

      $dropdown.on 'item_remove', (val)->
        console.log 'item_remove', val
        eventHub.$emit('remove-tag', { tag: val })
        tags = vnode.context.tags.split(',') or []
        new_tags = _.filter tags, (i)-> i isnt val
        vnode.context.tags = new_tags.join ','

      if vnode.context.tags
        console.log 'add from directive'
        $dropdown.addItem(vnode.context.tags, true) # silent


  update: (el, binding, vnode)->
    if binding.value != binding.oldValue
      console.log "Tags update", binding.value
      el.selectize.addItem(binding.value)

  unbind: (el)->
    console.log "Tags unbind"
    el.selectize.destroy()

