Vue = require 'vue'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'fi_budget[lines_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'fi_budget[lines_attributes][' + _uid + '][id]'">

             <div class="ui field" style="min-width: 200px;">
            <accounts-dropdown
              :accounts='dropdown_accounts'
              v-model='item.fi_account_id'
              :name="'fi_budget[lines_attributes][' + _uid + '][fi_account_id]'"
              :with_parents='true'
              v-once
            ></accounts-dropdown>
        </div>
      </td>

      <td>
        <div class="ui field" style="min-width: 200px;">
            <dropdown
              :options='cost_centers'
              v-model='item.cost_center_id'
              :name="'fi_budget[lines_attributes][' + _uid + '][cost_center_id]'"
              prompt=""
            ></dropdown>
        </div>
      </td>


      <td>
        <div class='ui field' style="min-width: 115px;">
          <datepicker
            :name="'fi_budget[lines_attributes][' + _uid + '][start_date]'"
            v-model="item.start_date"></datepicker>
        </div>
      </td>

      <td>
        <div class='ui field' style="min-width: 115px;">
          <datepicker
            :name="'fi_budget[lines_attributes][' + _uid + '][end_date]'"
            v-model="item.end_date"></datepicker>
        </div>
      </td>

      <td class="text-left">
        <div class="ui field" style="min-width: 100px;">
          <input v-model.number="item.planned_amount"
            :name="'fi_budget[lines_attributes][' + _uid + '][planned_amount]'"
            class="item-amount ltr">
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']

  components:
    'datepicker': require('../../components/simple_date_picker.coffee')
    'accounts-dropdown': require('./accounts_dropdown.coffee')
    'dropdown': require('../../shared_components/ui_dropdown.coffee')


  data: ->
    accounts: window.dropdown_accounts
    cost_centers: window.cost_centers || []


  created: ->
    console.log 'BudgetLine::Created'


  renderError: (h, err)->
# h('pre', { style: { color: 'red' }}, err.stack)
    greenPOS.notify_error "Error Rendering Page!"


#  watch:
#    'item.account_id': ()->
#      eventHub.$emit('addEmptyItem')


  methods:
    removeItem: (index)->
      @$set(@item, '_destroy', true)

      unless @item.id
        eventHub.$emit('removeItem', { index: index })


  computed:
    dropdown_accounts:  ->
      if @item.id?
        @accounts
      else
        []

    isValid: ->
      true

    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list

