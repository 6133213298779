_escape = require 'lodash/escape'
ENTER_KEY = 13
chatbox = null

document.addEventListener( "DOMContentLoaded", ()->
  chatbox = document.querySelector('[data-textarea="message"]')
  return unless chatbox

  document.querySelector('textarea#chatroom_message_content')
    .addEventListener('keydown', handleChatField)
)

handleChatField = (e)->
  if event.keyCode is ENTER_KEY
    e.preventDefault()

    message = chatMessage()
    return false if message.length is 0

    submitNewMessage(message)
    resetChatField()

  handleChatPanelHeight()


submitNewMessage = (message)->
  $panel = document.querySelector(".chat-panel")
  time = new Date()
  formattedTime = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'medium' }).format(time)

  data =
    user_id: App.user_id
    message: message
    message_time: formattedTime #moment().format('DD/MM/YYYY [at] h:mm A')
    user: $panel.dataset.username
    chatroom_id: $panel.dataset.chatroomId

  App.ChatroomMessages.addMessage(data)
  document.querySelector('[data-send="message"]').click()

handleChatPanelHeight = ()->
  adjustedHeight = chatbox.clientHeight
  maxHeight = 94

  if maxHeight > adjustedHeight
    adjustedHeight = Math.max(chatbox.scrollHeight, adjustedHeight)
    if maxHeight
      adjustedHeight = Math.min(maxHeight, adjustedHeight)
    if adjustedHeight > chatbox.clientHeight
      chatbox.style.height = adjustedHeight + 8 + 'px'
  else
    chatbox.style.overflow = 'auto'

resetChatField = ()->
  chatbox.value = ''
  chatbox.style.height = '44px'

chatMessage = ()->
  message = String(document.querySelector('[data-textarea="message"]').value)
  message = _escape message.replace(/^\s+|\s+$/g, "").trim()
  message
