import { Controller } from "stimulus"
import { debounce, subscribeTo } from "javascripts/helpers"

// This is only used during tests. It sets some data-* attribute flags in <body>
// to capture when Hotwire components are initiated. It's meant to offer a mechanism to wait
// for those to Capybara.
export default class extends Controller {
  connect() {
    this.stimulusIsReady()
    this.monitorLoadOfJavascriptFiles()
    this.monitorActionCableReadiness()
  }

  // private

  stimulusIsReady() {
    this.element.setAttribute("data-stimulus-ready", true)
  }

  monitorLoadOfJavascriptFiles = debounce(() =>{
    const scripts = window.performance.getEntriesByType("resource").filter(resource => resource.name.endsWith(".js"))
    if (scripts.every(script => script.responseEnd)) {
      this.javascriptFilesWereLoaded()
    } else {
      this.monitorLoadOfJavascriptFiles()
    }
  }, 500)

  async javascriptFilesWereLoaded() {
    this.element.setAttribute("data-javascript-files-loaded", true)
  }

  monitorActionCableReadiness() {
    setTimeout(this.subscribeToHeartbeatChannel.bind(this), 1000) // Adding delay because otherwise Chrome fails to connect sometimes
  }

  subscribeToHeartbeatChannel() {
    this.subscription = subscribeTo("HeartbeatChannel", {
      connected: this.actionCableIsReady.bind(this)
    })
  }

  actionCableIsReady() {
    this.element.setAttribute("data-cable-ready", true)
  }
};
