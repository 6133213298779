window.greenPOS = window.greenPOS || {};

greenPOS.global_modal_handler = '.ui-global-modal';

window.notify = function(msg, options={}){
  const defaults = {
    text: msg,
    type: 'success', // alert, success, error, warning, information, config
    theme: 'mint',
    layout: 'topRight',
    dismissQueue: true,
    maxVisible: 0,
    timeout: 4000,
    modal: false,
    buttons: false,
    closeWith: ['click'],
    animation: {
      open: 'animated an-bounceInRight',
      close: 'animated an-bounceOutRight',
      easing: 'swing',
      speed: 500
    }
  };

  const _options = Object.assign({}, defaults, options);
  new Noty(_options).show();
};


window.show_notification = (msg, type) => window.notify(msg, {type});
window.show_notifications = (messages, type) => {
  messages.forEach(message => window.notify(message, {type}))
}


// greenPOS.notify = (msg, status='success')-> show_notification(msg, status)
greenPOS.notify = function(messages, status='success'){
  if (Array.isArray(messages)) {
    messages.forEach(msg => show_notification(msg, status));
  } else if (greenPOS.utils.isString(messages)) {
    show_notification(messages, status);
  }
};

greenPOS.notify_success = msg => greenPOS.notify(msg, 'success');
greenPOS.notify_error = msg => greenPOS.notify(msg, 'error');

greenPOS.add_data_to_dom_list = function(obj_name, data){
  const $el = $(`.${obj_name}s-list > tbody`);
  if ($el.length === 0) { return; }
  $el.append(data);
};

greenPOS.replace_data_on_dom_list = (obj_id, data) => $(obj_id).replaceWith(data);

greenPOS.hide_modal = () => $(greenPOS.global_modal_handler).modal('hide');

greenPOS.add_object_and_notify = function(obj_name, data, msg){
  greenPOS.add_data_to_dom_list(obj_name, data);
  greenPOS.hide_modal();
  greenPOS.notify_success(msg);
};

greenPOS.replace_object_and_notify = function(obj_id, data, msg){
  greenPOS.replace_data_on_dom_list(obj_id, data);
  greenPOS.hide_modal();
  greenPOS.notify_success(msg);
};
