$ = require 'jquery'
eventHub = require '../event_hub.coffee'


module.exports =
  template: """
    <div class="product-selling-price--item-wrapper">
      <div class="product-selling-price--item flex" v-bind:class="{'edit-mode': editMode}">
        <div class="product-selling-price--product-name">
          {{item.name}}
        </div>
        <div class="product-selling-price--product-price">
          <span class="num">{{item.cost_price}}</span>
        </div>
        <div class="product-selling-price--product-price">
          <span class="num">{{item.sell_price}}</span>
        </div>

        <div class="product-selling-price--product-actions" v-if="!editMode">
          <a href="#" class='ui small basic blue button js-edit-price'
            @click.prevent="editMode=true">Edit</a>
        </div>
        <div class="product-selling-price--product-form" v-if="editMode">
          <div class="ui form field xib">
            <label for=''>Sell Price</label>
            <input type="text" v-model.number="item.sell_price" />
          </div>
          <div class="ui form field xib">
            <label for=''>Low Price Limit</label>
            <input type="text" v-model.number="item.sell_price_low_limit" placeholder="low_limit" />
          </div>
          <div class="ui form field xib">
            <label for=''>High Price Limit</label>
            <input type="text" v-model.number="item.sell_price_high_limit" placeholder="high_limit" />
          </div>

          <a href="#" class='ui small green button'
            @click.prevent="savePrice">Save</a>
          <a href="#" class='ui small basic button'
            @click.prevent="editMode=false">Cancel</a>
        </div>
      </div>
    </div>
  """
  props: ['item']
  data: ->
    editMode: false


  created: ->
    console.log 'Product is ready'


  methods:
    savePrice: ->
      vm = @
      url = "/api/product_prices/#{@item.id}"
      data =
        sell_price: @item.sell_price
        sell_price_low_limit: @item.sell_price_low_limit
        sell_price_high_limit: @item.sell_price_high_limit

      $.ajax({url: url, data: data, method: 'put'})
        .done((response)->
          console.log 'Success => ', response.success

          vm.editMode = false

        ).fail(->
          console.warn 'Failed Request', url
        )


  computed:
    products: -> []
