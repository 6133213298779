$ = require 'jquery'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <div class="ui field">
          <input
            v-model="item.position"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][position]'"
            class="ltr">
        </div>
      </td>
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <input
            v-model="item.name"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][name]'"
            class="input-small item-name ltr">
        </div>
      </td>

      <td>
        <!--<select
            v-model="item.work_center_id"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][work_center_id]'"
            class='work_centers-dropdown''>
              <option
                v-for="center in work_centers"
                :value="center.id">
                {{center.name}}
              </option>
            </select>-->

          <dropdown
              :options='work_centers_options'
              v-model='item.work_center_id'
              :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][work_center_id]'"
              prompt=""
              v-once
            ></dropdown>
      </td>

      <td>
        <div class="ui field">
          <input
            v-model="item.duration_in_minutes"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][duration_in_minutes]'"
            class="input-small item-quantity ltr">
        </div>
      </td>
      <td>
        <div class="ui field">
          <textarea
            v-model="item.description"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][description]'"
            class="" rows='2'></textarea>
        </div>
      </td>
      <td>
        <div class='hidden'>
        </div>
        <div class="ui field">
           <input
            type="file"
            @change="onFileChange"
            :name="'mrp_bill_of_material[operations_attributes][' + _uid + '][instructions_pdf]'"
            class="">
        </div>
      </td>

      <td class="text-left">
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  components:
    'dropdown': require('../../shared_components/ui_dropdown.coffee')

  props: ['item', 'index']
  data: ->
    work_centers: window.work_centers || []
    attachment_data: null


  created: ->
    console.log 'operation is ready'



  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    removeItem: (index)->
      @$set(@item, '_destroy', true)
      unless @item.id
        eventHub.$emit('remove-operation', { index: index })

    onFileChange: (e)->
      files = e.target.files || e.dataTransfer.files
      @attachment_data = files[0] if files
      console.log(@attachment_data)



  computed:
    work_centers_options: ->
      work_centers.map (c)=> {name: c.name, value: c.id}


    classList: ->
      # list['duplicated']    = @duplicated
      list = {}
      list['hidden']        = @item._destroy
      list['st-item']       = true
      list["item-#{@_uid}"] = true
      list

