eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'


module.exports =
  template: '#pos-receipt-template'

  store: store

  data: ->
    client_id: null
    user_name: window.user_name


  computed:
    current_tab: -> @$store.state.pos.current_tab

    paid_amount: -> round_money(@current_tab.paid_amount)
    remaining_amount: -> @$store.getters.remaining_amount
    change_amount: -> @$store.getters.change_amount

    sub_total: -> @$store.getters.orderSubTotal
    discount: -> @$store.getters.orderDiscount
    total: -> @$store.getters.orderTotal
    total_taxes: -> @$store.getters.totalTaxes.toFixed(2)

    cartItemsAsArray: -> @$store.getters.cartItemsAsArray

    current_order_id: -> @current_tab.order?.id
    current_order_number: -> @current_tab.order?.order_number
    current_order_date: -> @current_tab.formatted_date

    receiptURL: -> "/pos/orders/#{@current_order_id}/receipt"


  methods:
    printReceipt: ->
      greenPOS.helpers.print_page url: @receiptURL


    hideReceipt: -> @$emit('hide')


    resetSession: ->
      @hideReceipt()
      @$store.dispatch('removeTab')


    nextOrder: ->
      console.info "Next Order =>"
      @resetSession()
