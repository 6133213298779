$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'
debounce = require 'debounce'


stocktaking =
  data: ->
    id: window.stocktaking.id
    store_id: window.stocktaking?.store_id
    query: null
    is_ready: false
    items: []
    history: []


  components:
    'item': require('./stocktaking-item.coffee')


  created: ->
    eventHub.$on('remove-item', @removeItem)
    @debounced_search = debounce(@search, 400)


  watch:
#    query: (query)->
#      return unless query? and query.length > 2
#      @debounced_search()

    items: ->
      console.log('ITEMS CANGED')


  computed:
    items_count: -> @items.length

    itemsIsValid: ->
#      not @items.some (i)=> not @itemIsValid(i)
      true

    hasItems: ->
      @items_count > 0

    isValidAttributes: ->
      @store_id?

    isValid: ->
      @isValidAttributes and @hasItems
      true


  methods:
    search: ->
      url = '/products/search_for_stocktaking'

      $.ajax(
        type: 'GET',
        dataType: 'json',
        url: url,
        data: {q: @query, store_id: @store_id}
      ).done (r)=>
#        console.log('response', r)
        if r.ok
          @addItem(r.product, r.inventory)
          @resetQuery()
        else
          greenPOS.notify_error(r.error)


    addItem: (product, inventory)->
      @addToHistory(product)

      found = @items.findIndex (i)-> i.product_id is product.id

      if found != -1
        item = @items[found]
        item.quantity += 1
        @items[found] = item

      else
        item = {
          product_id: product.id,
          product_name: product.name,
          quantity: 1,
          expected_quantity: inventory
        }
        @items.push item


    removeItem: (data)->
      @items.splice(data.index, 1)


    addToHistory: (product)->
      # limit history size
      @history = @history.slice(0, 10) if @history.length > 10
      # prepend item to history top
      @history.unshift({product_name: product.name, count: 1})


    resetQuery: -> @query = null

    itemIsValid: (item)->
      item.product_id? and round_quantity(item.quantity) > 0


  events:
    'remove-item': (index)-> @removeItem(index)


el = document.querySelector('.stocktaking-form-wrapper')
new Vue(stocktaking).$mount(el) if el

