$ = require 'jquery'
Vue = require 'vue'
products_store = require '../products_store'
eventHub = require '../event_hub'
_ = require 'lodash/core'

require '../directives/ui_dropdown.coffee'


stock_adjustment = new Vue
#  el: '.stock-adjustment-form-wrapper'
  data: ->
    items: []
    is_ready: false
    store_id: null
    reason: null

  components:
    'stock-adjustment-item': require('./components/stock-adjustment-item')


  created: ->
    el = $('.stock-adjustment-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    products_store.fetchSimpleItems(@).then =>
      @addItem() if @items.length is 0




  computed:
    count: -> @items.length

    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    isValidAttributes: ->
      @store_id != null and @reason != null


    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    addItem: (event)->
      item = {product_id: null, quantity: 1, price: 0, unit_of_measure_id: null}
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      q = @round_quantity(item.quantity)
      item.product_id isnt null and item.quantity != "" and q > 0


  events:
    'remove-item': (index)-> @removeItem(index)


if document.querySelector('.stock-adjustment-form-wrapper')
  stock_adjustment.$mount('.stock-adjustment-form-wrapper')
