Vue = require 'vue'
eventHub = require './event_hub.coffee'

_debounce = require 'lodash/debounce'

$ = require 'jquery'
window.jQuery = $
require 'semantic-ui-css/components/dropdown.js'

app = new Vue
#  el: '.product-selling-price-manager'
  data:
    items: []
    is_ready: false
    product_category_id: null
    q: null
    tags: null

  components:
    'product-selling-price-item': require('./components/product-selling-price.coffee')


  created: ->
    el = $('.product-selling-price-manager')
    return unless el.length

  mounted: ->
    @bind_product_categories()


  watch:
    product_category_id: (newVal, oldVal)->
      vm = @
      url = "/api/product_prices?branch_id=#{App.branch_id}&cid=#{@product_category_id}"
      $.get(
        url: url
      ).done((response)->
        vm.items = response.products
      ).fail ->
        console.warn "ERROR LOADIN", url


    q: (newVal, oldVal)->
      if newVal isnt oldVal and newVal and newVal.trim().length > 2
        @search_for_product()

    tags: (newVal, oldVal)->
      if newVal isnt oldVal and newVal and newVal.trim().length > 2
        @search_for_product()


  methods:
    bind_product_categories: ->
      vm = @
      $('#product_category_id').dropdown
        fullTextSearch: true
        showOnFocus: true
        match: 'text'
        forceSelection: false
        onChange: (value, text, $choice)->
          vm.product_category_id = value


    search_arguments: ->
      args = {
        branch_id: App.branch_id,
        q: if @q then @q.trim() else @q,
        tags: if @tags then @tags.trim() else @tags
      }

      Object.entries(args)
        .filter((i)=> i[1] != null)
        .map((i)=> i.join('='))
        .join('&')


    search_for_product: _debounce ->
      vm = @
      url = "/api/product_prices?#{@search_arguments()}"

      $.get(
        url: url
      ).done((response)->
        vm.items = response.products
      ).fail(->
        console.warn "ERROR LOADIN", url
      )
    , 500

    print: ->
      console.log('Printing')
      url = '/product_selling_price_manager'
      params = {cid: @product_category_id, q: @q}
      greenPOS.helpers.print_page({url: url, params: params})


el = document.querySelector('.product-selling-price-manager')
app.$mount('.product-selling-price-manager') if el
