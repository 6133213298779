window.greenPOS = window.greenPOS || {};
window.greenPOS.helpers = window.greenPOS.helpers || {};
window.greenPOS.utils = window.greenPOS.utils || {};


greenPOS.helpers.open_window = function(url){
  window.open(url, '',
    'letf=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');
};


greenPOS.helpers.print_with_iframe = function(url){
  const i = function() {
    iframe.contentDocument.body.focus();
    return setTimeout(function() {
        iframe.contentWindow.print();
        iframe.parentNode.removeChild(iframe);
      }
      , 200);
  };

  let iframe = document.createElement('iframe');
  iframe.style.display = "none";
//  iframe.src = url
  iframe.sandbox = 'allow-same-origin allow-scripts allow-modals';

  document.body.appendChild(iframe);

  $.get(url).done(function(response){
    iframe.addEventListener("load", i, false);
    iframe.contentDocument.write(response);
    iframe.contentDocument.close();
  });
};


greenPOS.helpers.print_page = function(data){
  const params = data.params || {};

  let {
    url
  } = data;
  url = URI(url).addSearch('print', 1);
  if (params) { url = url.addSearch(params); }

  console.log('PRINTING URI', url.toString());

  greenPOS.helpers.print_with_iframe(url.toString());
};


//####

//# FORMATTERS

//####
isNumber = n => !isNaN(parseFloat(n, 10)) && isFinite(n);
greenPOS.utils.isNumber = isNumber;


window.round_number = function(amount, precision = 0) {
  let number = typeof amount === "number" ?
    amount.toFixed(precision)
    : typeof amount === "string" ?
      parseFloat(amount, 10).toFixed(precision)
      :
      0;

  number = parseFloat(number, 10);
  if (!greenPOS.utils.isNumber(number)) { return 0; }
  return number;
};

window.round_quantity = (amount, precision = App.settings.quantity_round) => round_number(amount, precision);

window.round_money = (amount, precision = App.settings.money_round) => round_number(amount, precision);

window.format_quantity = (num, precision = App.settings.quantity_round) => numeral(round_quantity(num, precision)).format('0,0.' + Array(precision + 1).join('0'));

window.format_money = (num, precision = App.settings.money_round) => numeral(round_money(num, precision)).format('0,0.' + Array(precision + 1).join('0'));


const isString = function(value){
  const stringTag = '[object String]';

  const isObjectLike = value => (value && (typeof value === 'object')) || false;

  return (typeof value === 'string') ||
    (isObjectLike(value) && (Object.prototype.toString.call(value) === stringTag)) ||
    false;
};

greenPOS.utils.isString = isString;




/*
var errors = new Errors();

if (!(/\\S/.test(attributes.title)))
  errors.add("title", "cannot be blank.");

if (!/\\S/.test(attributes.description))
  errors.add("description", "cannot be blank.");

if (!errors.isEmpty())
  return errors;
*/
// window.Errors = function(options){
//   options || (options = {});
//   return this.on = options;
// };

//_.extend Errors.prototype, {
//  isEmpty: ()-> _.size(this.on) is 0
//  clear: ()-> this.on = {}
//  add: (attribute, error)-> this.on[attribute] = error
//  each: (callback)-> _.each this.on, callback
//  full_messages: ()->
//    return _.map this.on, (error, attribute)->
//      attribute + " " + error
//}
