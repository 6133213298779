$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'
require '../directives/date_picker.coffee'
_ = require 'lodash/core'


credit_memo = new Vue
#  el: '.credit-memo-form-wrapper'
  data: ->
    items: window.credit_memo_items || []
    tax_items: []
    is_ready: false
    contact_id: window.credit_memo_contact_gid
    owner_id: window.credit_memo?.owner_id
    issued_at: window.credit_memo?.issued_at
    reference: window.credit_memo?.reference
    vat: window.credit_memo?.vat
    paid_vat: window.credit_memo?.paid_vat
    discount_tax: window.credit_memo?.discount_tax
    reserved_amount_percent: window.credit_memo?.reserved_amount_percent
    from_purchase: window.credit_memo?.from_purchase
    related_to: window.credit_memo?.related_to
    vat_tax_office_id: window.credit_memo?.vat_tax_office_id
    paid_vat_tax_office_id: window.credit_memo?.paid_vat_tax_office_id
    discount_tax_office_id: window.credit_memo?.discount_tax_office_id

  components:
    'credit-memo-item': require('./components/credit-memo-item.coffee')
#    'credit-memo-tax-item': require('./components/credit-memo-tax-item.coffee')


  created: ->
    eventHub.$on('remove-item', @removeItem)
#    eventHub.$on('remove-tax-item', @removeTaxItem)

    unless @contact_id
      @items.push({amount: 0, description: ''})


  computed:
    items_count: -> @items.length
#    tax_count: -> @tax_items.length

    itemsWithAmount: ->
      _.filter @items, (i)->
        i.amount isnt null and i.amount > 0 and not i._destroy


    items_total: ->
      t = _.reduce @itemsWithAmount, (sum, item)->
        sum.plus(item.amount)
      , Big(0)
      t.toFixed(3)


    vat_amount: ->
      vat = round_number(@vat, 3)
      Big(@items_total).times(vat).div(100)

    paid_vat_amount: ->
      if @paid_vat then -@vat_amount else Big(0)


    discount_tax_amount: ->
      discount_tax = round_number(@discount_tax, 3)
      Big(@items_total).times(discount_tax).div(100)

    reserved_amount: ->
      percent = round_number(@reserved_amount_percent, 2)
      Big(@items_total).times(percent).div(100)


    net_total: ->
      t = Big(@items_total).plus(@vat_amount).plus(@paid_vat_amount)
        .minus(@discount_tax_amount)
        .minus(@reserved_amount)
      t.toFixed(3)


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithAmount, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithAmount.length > 0


    isValidContact: ->
      @contact_id != null and @contact_id isnt ""

    hasVat: ->
      not([null, undefined, ''].includes(@vat)) and (@round_number(@vat) > 0)

    hasDiscountTax: ->
      not([null, undefined, ''].includes(@discount_tax)) and (@round_number(@discount_tax) > 0)

    isValidVatTaxOffice: ->
      return true unless @hasVat
      not [null, undefined, ''].includes(@vat_tax_office_id)

    isValidPaidVatTaxOffice: ->
      return true unless @hasVat and @paid_vat
      not [null, undefined, ''].includes(@paid_vat_tax_office_id)

    isValidDiscountTaxOffice: ->
      return true unless @hasDiscountTax
      not [null, undefined, ''].includes(@discount_tax_office_id)

    isValidRelatedTo: ->
      not [null, undefined, ''].includes(@related_to)

    isValidAttributes: ->
      @isValidContact and @owner_id != null and
        @isValidVatTaxOffice and @isValidPaidVatTaxOffice and
        @isValidDiscountTaxOffice and @isValidRelatedTo


    isValid: ->
      @isValidAttributes and @hasItems


  methods:
    addItem: (event)->
      item = {amount: 0, description: '', notes: ''}
      @items.push item
      event and event.preventDefault()


#    addTaxItem: (event)->
#      item = {amount: 0, description: '', sign: 1}
#      @tax_items.push item
#      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


#    removeTaxItem: (data)->
#      @tax_items.splice data.index, 1


    itemIsValid: (item)->
      q = @round_quantity(item.quantity)
      item.product_id isnt null and item.quantity != "" and q isnt 0


  events:
    'remove-item': (index)-> @removeItem(index)
#    'remove-tax_item': (index)-> @removeTaxItem(index)


if document.querySelector('.credit-memo-form-wrapper')
  credit_memo.$mount('.credit-memo-form-wrapper')
