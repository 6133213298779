module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>{{item.product_name}}</td>
      <td>{{item.quantity}}</td>
      <td>{{item.unit_name}}</td>
    </tr>
  """
  props: ['item', 'index']


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Component [ProductionOrderComponent]!"


  computed:
    classList: ->
      list = {}
      list['item']       = true
      list["item-#{@_uid}"] = true
      list

