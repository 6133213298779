import 'selectize';
import 'semantic-ui-css/components/dropdown.js';

;(function() {
  let greenPOS = window.greenPOS || {};
  greenPOS.helpers = greenPOS.helpers || {};
})(window);


document.addEventListener('DOMContentLoaded', ()=> {
  greenPOS.initSemanticModal();
  greenPOS.initSemanticUIPlugins();
  greenPOS.initUIBindings();
  greenPOS.initTopSearchComponent();
  greenPOS.initDatePickerPlugin();

  greenPOS.ajaxEventHelpers.trigger_link_events();

})

greenPOS.initPlugins = function() {
  /* Selectize */

  $('.selectize').selectize({
    maxOptions: 100
  });

  $('.selectize-add').selectize({
    create: true,
    hideSelected: false,
    maxOptions: 100
  });

  $('.selectize-multiple').selectize({
    plugins: ['remove_button'],
    maxOptions: 60
  });

  $('.selectize-tags').selectize({
    plugins: ['remove_button'],
    delimiter: ',',
    create: true,
    persist: false,
    maxOptions: 60
  });
}

greenPOS.initSemanticModal = function() {

  $('.ui.basic.modal')
    .modal({
      transition: 'slide down',
    })

  let ui_modal = {
    $modal: $('.ui.modal'),
    modal_class: '.ui-global-modal',
    positive_class: '.ui.positive.button',
    cancel_class: '.ui.cancel.button',

    init: function() {
      ui_modal.handle_cancel_action();
      ui_modal.handle_positive_action();

      ui_modal.init_modal();
    },

    $: function(selector) {
      return $(ui_modal.modal_class).find(selector);
    },

    add_close_btn: function() {
      $(ui_modal.modal_class).prepend('<i class="close icon"></i>');
    },

    handle_positive_action: function() {
      ui_modal.$modal.on('click', ui_modal.positive_class, function(e) {
        e.preventDefault();
        $(this).parents('.ui.modal').find('.form').addClass('loading').submit();
      });
    },

    handle_cancel_action: function () {
      ui_modal.$modal.on('click', ui_modal.cancel_class, function(e) {
        e.preventDefault();
        $(this).parents('.ui.modal').modal('hide');
      });
    },

    handle_dropdowns: function() {
      ui_modal.$('.js-dropdown').dropdown({
        fullTextSearch: true,
        showOnFocus: false,
        placeholder: false,
        clearable: false
      });

      ui_modal.$("[data-behavior='dropdown']").each((idx, el)=> {
        $(el).dropdown({
          fullTextSearch: true,
          showOnFocus: false,
          placeholder: false,
          clearable: el.classList.contains('clearable')
        });
      });

      ui_modal.$('.js-remote-tax-offices-dropdown')
        .dropdown({
          showOnFocus: true,
          forceSelection: false,
          placeholder: false,
          match: 'text',
          minCharacters: 3,
          apiSettings: {
            cache: false,
            throttle: 700,
            url: '/search/tax_offices?q={query}'
          },
          saveRemoteData: false
        });

      ui_modal.$('.js-remote-contacts-dropdown')
        .dropdown({
          showOnFocus: true,
          forceSelection: false,
          placeholder: false,
          match: 'text',
          minCharacters: 3,
          apiSettings: {
            cache: false,
            throttle: 700,
            url: '/search/contacts?q={query}'
          },
          saveRemoteData: false
        });

      ui_modal.$("[data-behavior='contacts-dropdown']").each((idx, el)=> {
        $(el).dropdown({
          showOnFocus: true,
          forceSelection: false,
          placeholder: false,
          match: 'text',
          minCharacters: 3,
          apiSettings: {
            cache: false,
            throttle: 700,
            url: '/search/contacts?q={query}'
          },
          saveRemoteData: false,
          clearable: el.classList.contains('clearable')
        });
      });

      ui_modal.$('.js-remote-clients-dropdown')
        .dropdown({
          showOnFocus: true,
          forceSelection: false,
          placeholder: false,
          match: 'text',
          minCharacters: 3,
          apiSettings: {
            cache: false,
            throttle: 700,
            url: '/search/clients?q={query}'
          },
          saveRemoteData: false
        });

      ui_modal.$("[data-behavior='clients-dropdown']").each((idx, el)=> {
        let client_type = el.dataset.type;
        let url = '/search/clients?q={query}&type=' + client_type;

        $(el).dropdown({
          showOnFocus: true,
          forceSelection: false,
          placeholder: false,
          match: 'text',
          minCharacters: 3,
          apiSettings: {
            cache: false,
            throttle: 700,
            url: url
          },
          saveRemoteData: false,
          clearable: el.classList.contains('clearable')
        });
      });
    },

    handle_tabs: function () {
      ui_modal.$("[data-behavior='tabs'] .item").each((idx, el)=> {
        $(el).tab({})
      });
    },

    init_modal: function () {
      $(ui_modal.modal_class)
        .modal({
          autofocus: true,
          transition: 'fade up',
          // allowMultiple: true,
          closable: false,
          centered: false,
          variation: 'top aligned',
          selector: {
            close: '.close, .actions .cancel',
            approve: '.actions .positive, .actions .approve, .actions .ok',
            deny: '.actions .negative, .actions .deny, .actions .cancel'
          },
          onApprove: function() { return false; },
          onVisible: function() {
            greenPOS.ajaxEventHelpers.trigger_form_events();
            ui_modal.add_close_btn();
            ui_modal.handle_dropdowns();
            ui_modal.handle_tabs();
          }
        });
    }
  };


  ui_modal.init();

}

greenPOS.initSemanticUIPlugins = function() {


  $("[data-behavior='tabs'] .item").each((idx, el) => {
    $(el).tab({})
  });

  $('.js-progress').progress();

  $('.menu-dropdown').dropdown();

  $('.js-dropdown').dropdown({
    fullTextSearch: true,
    showOnFocus: true,
    forceSelection: false,
    placeholder: false,
    match: 'text',
  });

  $("[data-behavior='dropdown']").each((idx, el) => {
    $(el).dropdown({
      fullTextSearch: true,
      showOnFocus: true,
      forceSelection: false,
      placeholder: el.classList.contains('use-placeholder') ? 'auto' : false,
      match: 'text',
      clearable: el.classList.contains('clearable')
    });
  });

  $('.js-remote-tax-offices-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      apiSettings: {
        cache: false,
        throttle: 700,
        url: '/search/tax_offices?q={query}'
      },
      saveRemoteData: false
    });

  $('.js-remote-contacts-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      apiSettings: {
        cache: false,
        throttle: 700,
        url: '/search/contacts?q={query}'
      },
      saveRemoteData: false
    });

  $('.js-remote-suppliers-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      apiSettings: {
        cache: false,
        throttle: 700,
        url: '/search/suppliers?q={query}'
      },
      saveRemoteData: false
    });

  $('.js-remote-clients-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      apiSettings: {
        cache: false,
        throttle: 700,
        url: '/search/clients?q={query}'
      },
      saveRemoteData: false
    });

  $("[data-behavior='clients-dropdown']").each((idx, el) => {
    let client_type = el.dataset.type;
    let url = '/search/clients?q={query}&type=' + client_type;

    $(el).dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      apiSettings: {
        cache: false,
        throttle: 700,
        url: url
      },
      saveRemoteData: false,
      clearable: el.classList.contains('clearable')
    });
  });

  $('.js-remote-products-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      throttle: 700,
      apiSettings: {
        cache: false,
        url: '/search/products?q={query}'
      },
      saveRemoteData: false
    });

  $('.js-remote-mrp-products-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      throttle: 700,
      apiSettings: {
        cache: false,
        url: '/search/products?q={query}&mrp=1'
      },
      saveRemoteData: false
    });

  $('.js-remote-received-receipt-dropdown')
    .dropdown({
      showOnFocus: true,
      forceSelection: false,
      placeholder: false,
      match: 'text',
      minCharacters: 3,
      throttle: 700,
      apiSettings: {
        cache: false,
        url: '/search/received_receipts?q={query}'
      },
      saveRemoteData: false
    });


  $('.js-home-date-filter').dropdown();

  $('.js-dropdown-multiple').dropdown({
    useLabels: false,
    showOnFocus: false,
    fullTextSearch: true,
    message: {
      count: 'عدد الاختيارات {count}'
    }
  });

  $('.js-dropdown-multiple-labeled').dropdown({
    useLabels: true,
    fullTextSearch: true,
  });

  $('.js-filters.dropdown')
    .dropdown({
      action: 'hide',
    })
  ;


}

greenPOS.ajaxEventHelpers = {
  before_event: function() {
    console.log('AJAX::BEFORE');

    NProgress.start();

    let $modal = $('.ui.modal');
    $modal.find('.form').addClass('loading');
    $modal.find('.ui.positive.button').addClass('loading disabled js-disabled');
  },

  complete_event: function() {
    console.log('AJAX::COMPLETE');

    NProgress.done();

    let $modal = $('.ui.modal');
    $modal.find('.form').removeClass('loading');
    $modal.find('.ui.positive.button').removeClass('loading disabled js-disabled');
  },

  trigger_link_events: function() {
    $('a[data-remote=true]').each(function (link) {
      $(this).on('ajax:before', function (request) {
        greenPOS.ajaxEventHelpers.before_event();
      });
      $(this).on('ajax:complete.rails', function (request) {
        greenPOS.ajaxEventHelpers.complete_event();
      });
    });
  },

  trigger_form_events: function() {
    $('form[data-remote=true]').each(function (link) {
      $(this).on('ajax:before', function (request) {
        greenPOS.ajaxEventHelpers.before_event();
      });
      $(this).on('ajax:complete.rails', function (request) {
        greenPOS.ajaxEventHelpers.complete_event();
      });
    });
  }
}

greenPOS.initDatePickerPlugin = function() {
  $('.datepicker.dropdown-menu').removeClass('pull-right');
}

greenPOS.initTopSearchComponent = function() {

  $('.top-search-component')
    .search({
      apiSettings: {
        url: '/search/?q={query}',
        cache: false,
        // beforeSend: function(settings) {
        //   settings.data.search_in = 'products';
        //   return settings;
        // }
      },
      // onSelect: function(result, response) {
      //   console.log('RESULT', result);
      //   return false;
      // },
      type: 'category',
      fields: {
        categories      : 'results',     // array of categories (category view)
        categoryName    : 'name',        // name of category (category view)
        categoryResults : 'results',     // array of results (category view)

        description     : 'description', // result description
        image           : 'image',       // result image
        results         : 'results',     // array of results (standard)
        title           : 'title',       // result title
        url             : 'url',         // result url

        action          : 'action',      // "view more" object name
        actionText      : 'text',        // "view more" text
        actionURL       : 'url'          // "view more" url
      },

      minCharacters: 3,
      maxResults: 10,
      searchDelay: 700,
      selectFirstResult: true,

      cache: false,
    });

}


greenPOS.helpers.gotoURL = function (url, text, $choice) {
  console.log('args: ', arguments);

  let remote = $choice.data('remote');
  if (remote === true) {
    let $a = $('<a/>', {
      href: $choice.data('value'),
      'data-remote': true
    });
    $.rails.handleRemote($a);
  } else {
    window.location.replace(encodeURI(url));
  }
}


greenPOS.helpers.hide_header_and_footer = function() {
  $(document).ready(function(){
    $('#page-global-header').hide();
    $('#page-global-footer').hide();
  });
}

greenPOS.initUIBindings = function() {
  $('.js-print-page').on('click', function (e) {
    e.preventDefault();
    greenPOS.helpers.print_page($(this).data());
  });

  let togglePanel = ($el)=> {
    console.log('toggled');
    let $panel = $el.parents('.panel');
    $panel.toggleClass('panel-collapsed');
  }

  $('body').on('click', '.panel-toggle .panel-title', function(e) {
    e.preventDefault();
    e.stopPropagation();

    togglePanel($(this));
  });
}
