import consumer from "./consumer";

consumer.subscriptions.create("WebNotificationsChannel", {
  connected() {
    console.log('WebNotificationsChannel::Connected');
  },

  disconnected() {},

  received(data) {
    console.log('DATA', data);
    if (data.unread_messages) {
      const $menu = $('.user-menu');
      $menu.find('.label')
        .text(data.unread_messages)
        .removeClass('hidden');
    }

    if ((data.notification_type === 'user_message') && (data.sender_id !== window.App.user_id)) {
      if (data.broadcast || data.recepients.includes(String(window.App.user_id))) {
        window.notify(data.message, {
          type: 'warning',
          layout: 'top',
          timeout: false,
          modal: false,
          buttons: false,
          closeWith: ['click']
        });
      }
    }
  }
});
