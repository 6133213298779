Vue = require 'vue'
Vuex = require 'vuex'
$ = require 'jquery'
_ = require 'lodash/core'
pos = require './pos.coffee'

Vue.use(Vuex)

store = new Vuex.Store
  state: {
    contact: null,
    contact_id: null,
    segment: null,
    policy: null,
    products: [],
    products_is_ready: false,
  },


  actions: {
    LOAD_CONTACT: (context)->
      commit = context.commit

      xhr = $.get(url, (response)->
        console.log 'R', response
        commit('SET_CONTACT', {contact: response.contact})
        commit('SET_CONTACT_SEGMENT', {segment: response.segment})
      ).fail(->
        console.warn 'Failed to get Contact', url
      )
      xhr


    LOAD_SALES_POLICY: (context)->
      commit = context.commit
      branch_id = App.branch_id
      url = "/api/sales_policies/active?branch_id=#{branch_id}"
      $.get(url, (response)->
        commit('SET_SALES_POLICY', { policy: response })
      ).fail(->
        console.warn('Failed POLICY', url)
      )


    LOAD_PRODUCTS: (context)->
      commit = context.commit
      branch_id = App.branch_id
      url = "/api/products/simple_list?branch_id=#{branch_id}"
      $.get(url)
        .done((response)->
          commit('SET_PRODUCTS', { products: response })
        ).fail(->
          console.warn('FAILED URL', url)
        )

  },

  mutations: {
    SET_CONTACT: (state, data)->
      state.contact = data.contact

    SET_CONTACT_SEGMENT: (state, data)->
      state.segment = data.segment

    SET_SALES_POLICY: (state, data)->
      state.policy = data.policy

    SET_PRODUCTS: (state, data)->
      state.products = data.products
      state.products_is_ready = true

  },
  getters: {
    getProduct: (state)->
      (product_id) ->
        deferred = Q.defer()
        product = _.filter state.products, {id: product_id}

        unless product_id
          deferred.reject({})
          return deferred.promise

        deferred.resolve(product) if product

  },
  modules: {
    pos: pos,
  }


module.exports = store
