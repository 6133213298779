$ = require 'jquery'
Selectize = require 'selectize'
Vue = require 'vue'

store = require '../stores/default.coffee'
# products_store = require '../products_store.coffee'
products_cache = require '../../products_caching.coffee'

eventHub = require '../event_hub.coffee'

Vue.directive 'ui-dropdown',
  params: ['products']

  componentUpdated: (el)->
    console.log 'ProductsDD Component Updated!'

  bind: (el, binding, vnode)->
    # console.log "ITEMS inside directive", products_store.items.length
    # console.log 'EL', el, binding

    Vue.nextTick ->
      $dropdown = $(el).selectize
        plugins: ['dropdown_direction']
        dropdownDirection: 'auto'
        highlight: false
        valueField: 'id'
        labelField: 'name'
        maxOptions: 50
        options: products_cache.products #store.state.products
        searchField: ['name', 'sku', 'brand', 'tags', 'ean_code']
        searchConjunction: 'and'
        sortField: [
          {field: '$score', direction: "desc"},
          {field: "filtered_stock_on_hand", direction: "desc"}
        ]
        score: (search)->
          score = @getScoreFunction(search)
          (item)->
            q = if item.filtered_stock_on_hand <= 0
              0
            else
              item.filtered_stock_on_hand

            q = if item.is_featured
              q + 5
            else
              q + 2

            score(item) * (1 + Math.min(q / 100, 1))

        render:
          option: (item, escape)->
            qty = round_quantity(item.filtered_stock_on_hand)

            reorder_quantity = item.reorder_quantity
            safty_quantity = item.safty_quantity
            max_quantity = item.max_quantity

            _class = switch
              when qty is 0 then 'reorder_quantity'
              when qty < safty_quantity then 'lt-safty_quantity'
              when qty is safty_quantity then 'safty_quantity'
              when qty is reorder_quantity then 'reorder_quantity'
              when qty > reorder_quantity and qty < max_quantity then 'available'
              when qty is max_quantity then 'max_quantity'
              when qty > max_quantity then 'gt-max_quantity'

            "<div>" +
            "<span class='xpname'>#{escape(item.name)}</span>" +
            "<span class='tot-quantity'>
              <span class='ui label qty-#{_class} p-quantity qty-label num'>#{qty}</span>
              </span>" +
            "</div>"

      $dropdown = $dropdown[0].selectize

      vnode.context.dropdown = $dropdown

      $dropdown.on 'item_add', (val, $item)->
        console.log 'ProductsDD::item_add', val
        if parseInt(val) isnt vnode.context.item.product_id
          eventHub.$emit('change-product-id', { id: val, uid: $(el).data('uid') })
        else
          console.log 'ProductsDD: item_add : same id'

      if vnode.context.item.product_id
        console.log 'ProductsDD::add from directive'
        $dropdown.addItem(vnode.context.item.product_id, true) # silent
      # else
      #   $dropdown.focus()


  update: (el, binding, vnode)->
    if binding.value != binding.oldValue
      console.log "ProductsDD:: update", binding.value
      el.selectize.addItem(binding.value)

  unbind: (el)->
    console.log "unbind"
    el.selectize.destroy()

