import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect () {
    this.element.addEventListener("click", this.print.bind(this));
  }

  initialize() {
    if (!this.hasUrlValue) {
      console.warn('NO URL DEFINED FOR PRINT ACTION!')
    }
  }

  print(e) {
    e?.preventDefault();
    this.printWithIframe();
  }

  printWithIframe() {
    console.log('PRINTING!', this.urlWithPrintParam);

    const printInsideIframe = ()=> {
      iframe.contentDocument.body.focus()

      setTimeout(()=> {
        iframe.contentWindow.print()
        iframe.parentNode.removeChild(iframe)
      }, 200);
    };

    let iframe = document.createElement('iframe')
    iframe.style.display = "none"
    iframe.sandbox = 'allow-same-origin allow-scripts allow-modals'
    document.body.appendChild(iframe)

    fetch(this.urlWithPrintParam)
      .then((response) => response.text())
      .then((responseBody) => {
        iframe.addEventListener("load", printInsideIframe, false)
        iframe.contentDocument.write(responseBody)
        iframe.contentDocument.close()
    })

  }

  get urlWithPrintParam() {
    return URI(this.urlValue).addSearch('print', 1).toString();
  }

  disconnect() {
    this.element.removeEventListener("click", this.print);
  }
};
