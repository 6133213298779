Vue = require 'vue'
_ = require 'lodash/core'
_debounce = require 'lodash/debounce'

products_store = require './products_store.coffee'

eventHub = require './event_hub.coffee'

Fuse = require 'fuse.js'

product_price_edits = new Vue
  data: ->
    product_name: null
    is_ready: false
    items: []
    items_per_page: 100
    currentPage: 1
#    filtered_ids: []
#    filtered: []
#    max_score: 0.2
#    query_count: 100

  components:
    'product-price-item': require('./components/product-price-item.coffee')

  watch:
    product_name: (newVal, oldVal)->
      newQuery = String(newVal).trim()
      oldQuery = String(oldVal).trim()
      if newQuery isnt oldQuery and newQuery.length > 3
#        @fuzzy_search_for_product()
        @basic_search_for_product()


  created: ->
    el = $('.product-price-edits-component')
    return unless el.length

    if window.cost_items
      @items = window.cost_items
    else
      console.log('No Items')

    products_store.fetchSimpleItems(@).then =>
      console.log("ITEMS FETCHED FOR COST ITEMS")


  computed:
    products: ->
      @products_sets[@currentPage - 1]

    products_sets: ->
      chunkSize = @items_per_page
      temp = @items.slice(0)
      sets = []

      while temp.length
        sets.push(temp.splice(0, chunkSize))
      sets


    totalPages: -> Math.ceil(@items.length / @items_per_page)
    isInFirstPage: -> @currentPage is 1
    isInLastPage: -> @currentPage is @totalPages



  methods:
#    set_page: (page)->
#      @products = @products_sets[page]

    nextPage: ->
      @currentPage += 1
#      set_page(@currentPage + 1)

    prevPage: ->
      @currentPage -= 1
#      set_page(@currentPage - 1)

    basic_search_for_product: _debounce ->
      url = "/product_price_edits/search"
      $.ajax(
        url: url
        data: {q: @product_name}
        method: 'GET'

      ).done((response)=>
        console.log 'Success'
        @items = response.items

      ).fail(->
        console.warn 'Failed Request', url
      )
    , 500


#    fuzzy_search_for_product: _debounce ->
#      options = {
#        shouldSort: true,
##        threshold: 0.9,
##        distance: 0,
##        location: 0,
#
#        tokenize: true,
#        matchAllTokens: true,
#
##        findAllMatches: true,
#
#        includeScore: true,
#
#        minMatchCharLength: 4,
#        maxPatternLength: 32,
#
#        keys: [{
#          name: 'name',
#          weight: 0.9
#        },
#        {
#          name: 'sku',
#          weight: 0.3
#        }],
#        id: 'id',
#        verbose: true
#      }
#      fuse = new Fuse(products_cache.products, options)
#      @filtered = fuse.search(@product_name.trim())
#        .filter((i)=> i.score < @max_score)
#      @filtered_ids = @filtered.map((i)-> i.item)
#      @fetch_products()
#
#    , 500

#    fetch_products: ->
#      url = "/product_price_edits/list_products"
#      ids = _.slice(@filtered_ids, 0, @query_count).join(',')
#      $.ajax(
#        url: url
#        data: {ids: ids}
#        method: 'GET'
#
#      ).done((response)=>
#        console.log 'Success'
#        @items = response.items
#
#      ).fail(->
#        console.warn 'Failed Request', url
#      )


    viewErrorHandler: (data)->
      console.log data



if document.querySelector('.product-price-edits-component')
  product_price_edits.$mount('.product-price-edits-component')
