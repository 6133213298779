import consumer from "./consumer";
import CableReady from 'cable_ready';

consumer.subscriptions.create("MrpPlanningChannel", {
  connected() {
    console.log('MrpPlanningChannel::Connected')
  },

  disconnected() {

  },

  received(data) {
    console.log('data: ', data);
    if (data.cableReady) CableReady.perform(data.operations)
  }
});
