import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["dropdown", "name", "dropdownWrapper"];
  static values = { url: String, canEdit: Boolean };

  connect() {
    console.log('WorkOrder Responsible connected')
  }

  initialize() {
  }
  //
  // clickOutside(event) {
  //   console.log('OUTSIDE')
  //   event.preventDefault();
  //
  //   if (this.dropdownWrapperTarget.contains(event.target)) {
  //     return;
  //   }
  //   this.cancelEdit();
  // }

  edit() {
    if (!this.canEditValue) { return; }

    if (this.dropdownWrapperTarget) {
      this.hideName();
      this.showDropDown();
    }
  }

  cancelEdit() {
    this.hideDropDOwn();
    this.showName();
  }

  showName() {
    this.nameTarget.classList.remove("hidden");
  }

  hideName() {
    this.nameTarget.classList.add("hidden");
  }

  showDropDown() {
    this.dropdownWrapperTarget.classList.remove("hidden");
  }

  hideDropDOwn() {
    this.dropdownWrapperTarget.classList.add("hidden");
  }

  save() {
    console.log('Changed', this.urlValue);
    let data = { responsible_id: this.dropdownTarget.value };

    $.ajax({
      type: 'PUT',
      dataType: 'json',
      url: this.urlValue,
      data: data
    }).done((r)=> {
      console.log('response', r);
      window.location.reload();

      this.cancelEdit();
    });

    // this.urlValue
    // this.element
    // if (this.hasCardTarget) {
    //       this.cardTarget.classList.remove("hidden");
  }

  disconnect() {
  }
}
