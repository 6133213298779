$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
products_store = require '../../products_store.coffee'
products_cache = require '../../../products_caching.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'

module.exports =
  template: """
    <tr class="form-field order-item" :class="classList">
      <td>
        <span class='num d-inline-block pt-2'>{{item.receipt_number}}</span>
        <input type="hidden"
          v-model="item.goods_returned_receipt_id"
          :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][goods_returned_receipt_id]'">
      </td>
      <td>
        <input type="hidden"
          v-model="item.id"
          :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][id]'">
        <input type="hidden"
          v-model="item._destroy"
          :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][_destroy]'">

        <div class="ui field" v-if='!product' key='products-dropdown'>
            <ui-dropdown
              :products='products'
              v-model='item.product_id'
              :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][product_id]'"></ui-dropdown>
        </div>

        <div class="ui field" key='product-name' v-else>
            <input type="hidden" v-model="item.product_id"
              :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][product_id]'">
            <span class='order-item--product-name'>
              {{ product.name }}
            </span>
        </div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input v-model.number="item.quantity"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][quantity]'"
            @keydown.enter.prevent
            @keydown.up="prevItem"
            @keydown.down="nextItem"
          class="input-small item-quantity ltr">
        </div>
      </td>


      <td>
        <div class="ui field"
          :class="{ 'error': !valid_unit_price }">
          <input v-model="item.unit_price"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][unit_price]'"
            class="input-small item-unit-price">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.percent_discount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][percent_discount]'"
            class="input-small item-percent-discount">
        </div>
      </td>



      <td>
        <div class="ui field">
          <input v-model="item.early_payment_discount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][early_payment_discount]'"
            class="input-small item-early-payment-discount">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.special_discount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][special_discount]'"
            class="input-small item-special-discount">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.value_discount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][value_discount]'"
            class="input-small item-value_discount">
        </div>
      </td>
      <td>
        <div class="ui field">
          <input v-model="item.offer_discount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][offer_discount]'"
            class="input-small item-offer_discount">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.quantity_discount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][quantity_discount]'"
            class="input-small item-quantity-discount">
        </div>
      </td>


      <td>
        <div class="ui field">
          <input v-model="item.warranty_amount"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][warranty_amount]'"
            class="input-small item-warranty_amount">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.warranty_percent"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][warranty_percent]'"
            class="input-small item-warranty_percent">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.table_tax"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][table_tax]'"
            class="input-small item-table_tax">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.vat"
            :name="'taxable_return_purchase_invoice[items_attributes][' + _uid + '][vat]'"
            class="input-small item-vat">
        </div>
      </td>



      <td class='tax-total-col-bg'>
        <div class="total num">{{format_money(net_total.toFixed(3), 3)}}</div>
      </td>



      <td class='tax-total-col-bg'>
        <div class="ui field">
          <span class='num'>{{format_money(warranty_value.toFixed(3), 3)}}</span>
        </div>
      </td>

      <td class='tax-total-col-bg'>
        <div class="ui field">
          <span class='num'>{{format_money(table_tax_value.toFixed(3), 3)}}</span>
        </div>
      </td>

      <td class='tax-total-col-bg'>
        <div class="ui field">
          <span class='num'>{{format_money(vat_value.toFixed(3), 3)}}</span>
        </div>
      </td>

      <td class='tax-total-col-dark-bg'>
        <div class="ui field">
          <span class='num'>{{format_money(totals_sum.toFixed(3), 3)}}</span>
        </div>
      </td>


      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  mounted: ->
    console.log "POI Mounted!"


  mixins: [inputKeyHandlers]
  components:
    'ui-dropdown': require('../../components/products_ui_dropdown.coffee')

  props: [
    'index', 'item', 'commission_total', 'discount_method'
  ]

  data: ->
    stock_on_hand: 0
    duplicated: false
    dropdown: null
    product: null


  created: ->
    console.log 'POI: I am ready'
    eventHub.$on('change-product-id', @change_product_id)
    eventHub.$on('duplicated-items', @viewErrorHandler)
    # fetch data on edit
    @getProductData() if @item.id or @item.product_id


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")

    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      if data.uid is @_uid && parseInt(data.id) isnt @item.product_id
        @$set(@item, 'product_id', data.id)
        @getProductData()
        @focusOnQuantity()


    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    setItemDataFromProduct: (product)->
      @$set @item, 'valid', true

      unless @item.id
        @$set @item, 'unit_price', round_number(product.cost_price, 3)
        @$set @item, 'warranty_percent', round_number(product.warranty_percent, 3)
        @$set @item, 'warranty_amount', round_number(product.warranty_amount, 3)
        @$set @item, 'vat', round_number(product.vat, 3)
        @$set @item, 'table_tax', round_number(product.table_tax, 3)
        @$set @item, 'percent_discount', round_number(product.percent_discount, 3)
        @$set @item, 'special_discount', round_number(product.special_discount, 3)
        @$set @item, 'early_payment_discount', round_number(product.early_payment_discount, 3)
        @$set @item, 'value_discount', round_number(product.cash_discount, 3)
        @$set @item, 'offer_discount', round_number(product.offer_discount, 3)


    getProductData: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @setItemDataFromProduct(product)

      , (response)->
        console.error response


    viewErrorHandler: (data)->
      vm = @
      found = _.find(data.items, (i)-> i.product_id is vm.item.product_id)
      @duplicated = if found then true else false


    scroll_to_current_element: (data)->
      el = data.el
      return unless el

      $scroll_element = $('.page-grid')
      # body_scrollTop = $('body').scrollTop()
      body_scrollTop = $scroll_element.scrollTop()
      # el_offsetTop = @_el().offset().top
      # el_offsetTop = @_el().get(0).offsetTop
      el_offsetTop = el.get(0).offsetTop

      # console.debug 'el_offsetTop', el_offsetTop
      # console.debug 'body_scrollTop', body_scrollTop

      position_on_viewport = el_offsetTop - body_scrollTop + 600
      mid_viewport = window.innerHeight/2

      # console.debug 'position_on_viewport', position_on_viewport
      # console.debug 'mid_viewport', mid_viewport

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        # $("html,body").stop().animate({
        $scroll_element.stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 1000).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


    percent_discount_of: (value)->
      discount = round_number(@item.percent_discount, 2)
      return Big(0) if discount <= 0
      Big(value).times(discount).div(100)


    early_payment_discount_of: (value)->
      discount = round_number(@item.early_payment_discount, 3)
      return Big(0) if discount <= 0

      Big(value).times(discount).div(100)


    apply_percent_discount: (value)->
      value.minus(@percent_discount_of(value))


    apply_early_payment_discount: (value)->
      value.minus(@early_payment_discount_of(value))


    has_table_tax: ->
      round_number(@item.table_tax, 2) > 0


  watch:
    'item.product_id': (newID, oldID)->
      if oldID is null
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})

      if parseInt(newID) isnt parseInt(oldID)
        @$set(@item, 'product_id', newID)
        @getProductData()
        @focusOnQuantity()

    isValid: (v)->  @$set @item, 'valid', v


  computed:
    products: ->
      products_cache.purchase_products

    available_quantity: ->
      quantity = round_quantity(@item.quantity)
      quantity_discount = round_quantity(@item.quantity_discount)
      Big(quantity).minus(quantity_discount)


    totals_sum: ->
      Big(@net_total.toFixed(3))
        .plus(@vat_value.toFixed(3))
        .plus(@table_tax_value.toFixed(3))
        .plus(@warranty_value.toFixed(3))


    sub_total: ->
      return Big(0) unless @isValid

      value_discount = round_number(@item.value_discount, 3)
      unit_price = round_number(@item.unit_price, 3)

      Big(@available_quantity).times(
        Big(unit_price).minus(value_discount)
      )


    total_with_taxes: ->
      Big(@sub_total)


    net_total: ->
      @total_without_taxes


    sub_total_without_taxes: ->
      Big(@total_with_taxes)
        .times(@vat_factor)
        .times(@table_tax_factor)
        .times(@warranty_percent_factor)


    sub_total_without_taxes_and_discount: ->
      percent_discount = round_number(@item.percent_discount, 3)
      special_discount = round_number(@item.special_discount, 3)
      early_payment_discount = round_number(@item.early_payment_discount, 3)

      discount_formula = (discount)->
        return 1 if discount < 0
        Big(1).minus(Big(discount).div(100))


      if @discount_method is 'commutative'
        @sub_total_without_taxes
          .times(discount_formula(percent_discount))
          .times(discount_formula(special_discount))
          .times(discount_formula(early_payment_discount))

      else if @discount_method is 'complete'
        @sub_total_without_taxes.times(
          Big(1)
            .minus(Big(percent_discount).div(100))
            .minus(Big(special_discount).div(100))
            .minus(Big(early_payment_discount).div(100))
        )

      else
        0


    total_without_taxes: ->
      warranty = round_number(@item.warranty_amount, 3)
      offer_discount = round_number(@item.offer_discount, 3)

      @sub_total_without_taxes_and_discount
        .minus(@available_quantity.times(warranty))
        .minus(@available_quantity.times(offer_discount))


    vat_factor: ->
      vat = round_number(@item.vat, 2)
      factor = Big(vat).div(100).plus(1)
      Big(1).div(factor)


    warranty_percent_factor: ->
      warranty = round_number(@item.warranty_percent)
      return Big(1) if warranty > 0 and @has_table_tax()

      factor = Big(warranty).div(100).plus(1)
      Big(1).div(factor)


    table_tax_factor: ->
      return Big(1) unless @has_table_tax()

      warranty_percent = round_number(@item.warranty_percent, 2)
      table_tax = round_number(@item.table_tax, 2)
      is_warranty = warranty_percent > 0

      factor = if is_warranty and @has_table_tax()
        Big(warranty_percent).plus(table_tax).div(100).plus(1)
      else if @has_table_tax()
        Big(table_tax).div(100).plus(1)
      else
        1
      Big(1).div(factor)


    warranty_percent_value: ->
      warranty = round_number(@item.warranty_percent, 2)
      return Big(0) if warranty is 0

      Big(@net_total).times(warranty).div(100)


    warranty_amount_value: ->
      warranty = round_number(@item.warranty_amount, 2)
      Big(warranty).times(@available_quantity)


    warranty_value: ->
      if @item.warranty_amount > 0
        @warranty_amount_value
      else
        @warranty_percent_value


    vat_value: ->
      vat = round_number(@item.vat, 2)
      return Big(0) if vat is 0

      Big(@net_total)
        .plus(@table_tax_value)
        .plus(@warranty_value)
        .times(vat)
        .div(100)


    table_tax_value: ->
      return Big(0) unless @has_table_tax()
      table_tax = round_number(@item.table_tax, 2)

      Big(@net_total).times(table_tax).div(100)


    quantity_discount_value: ->
      quantity_discount = round_number(@item.quantity_discount, 3)
      unit_price = round_number(@item.unit_price, 3)
      return Big(0) if quantity_discount is 0

      Big(quantity_discount).times(unit_price)


    stock_after: ->
      Big(round_quantity(@stock_on_hand))
        .plus(round_quantity(@item.quantity))
        .toFixed(App.settings.quantity_round)


    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @product and @item.quantity != "" and q > 0


    valid_quantity_discount: ->
      q = round_number(@item.quantity_discount, 3)
      @product and @item.quantity_discount != "" and q >= 0


    valid_unit_price: ->
      @product and @item.unit_price != "" and round_money(@item.unit_price) > 0


    valid_product: ->
      !!@item.product_id


    errors: ->
      quantity: @valid_quantity
      unit_price: @valid_unit_price
#      product: @valid_product
      quantity_discount: @valid_quantity_discount


    isValid: ->
      if @item._destory
        true
      else
        not _.some(@errors, (v, k)-> v is false)


    classList: ->
      list = {}
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list
