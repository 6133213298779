// import { cable } from "@hotwired/turbo-rails"
// import { isIosApp } from "helpers/platform_helpers"

export async function subscribeTo(channelName, mixin) {
  // if (isIosApp) return
  // return await cable.subscribeTo(channelName, mixin)
}

// export async function getCableConsumer() {
//   return await cable.getConsumer()
// };
