$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
formSubmitMixin = require '../mixins/form_submit.coffee'
products_store = require '../products_store.coffee'

require '../directives/ui_dropdown.coffee'


sales_order_fulfillment = new Vue
#  el: '.sales-order-fulfillment-form-wrapper'
  data:
    items: window.sales_order_fulfillment_items || []
    is_ready: false
    packed_at: window.sales_order_fulfillment?.packed_at
    store_id: window.sales_order_fulfillment?.store_id
    location_id: window.sales_order_fulfillment?.location_id


  components:
    'sales-order-fulfillment-item': require('./components/sales-order-fulfillment-item.coffee')

  mixins: [formSubmitMixin]


  created: ->
    el = $('.sales-order-fulfillment-items-wrapper')
    return unless el.length


  mounted: ->
    Vue.nextTick @bind_enter_to_save


  computed:
    total_units: -> (_.filter @items, (i)-> i.selected).length

    order_total: ->
      vm = @
      t = _.reduce @items, (sum, item)->
        if item.selected and vm.itemIsValid(item)
          total = Big(round_quantity(item.quantity)).times(round_money(item.unit_price))
          sum.plus(total)
        else
          sum.plus(0)
      , Big(0)
      t.toFixed(App.settings.money_round)


    selectedItems: ->
      _.filter(@items, (i)-> i.selected) or []


    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(vm, i)


    hasSelectedItems: ->
      @selectedItems.length > 0


    validPackedAt: ->
      @packed_at? and @packed_at != ""


    validStoreOrLocation: ->
      (@store_id? and @store_id != "") or (@location_id? and @location_id != "")


    isValid: ->
      @validPackedAt and @hasSelectedItems and @itemsIsValid


  methods:
    addItem: (event)->
      item = {}
      @items.push item
      event.preventDefault()


    removeItem: (index)->
      @items.splice(index, 1)


    itemIsValid: (vm, item)->
      # q = @round_quantity(item.quantity)
      # valid_quantity = item.quantity != "" and q > 0 and
      #   q <= @round_quantity(item.not_packed_quantity)
      valid_store_or_location = vm.validStoreOrLocation or item.store_id? or item.location_id?
      # valid_quantity and valid_store_or_location
      item.valid and valid_store_or_location


    bind_enter_to_save: ->
      el = $('.sales-order-fulfillment-form-wrapper')
      if el.length
        $('body').on 'keypress', (e)=>
          if e.which is 13 and @isValid
            console.log 'enter'
            el.find('input[type="submit"]').trigger 'click'
            true


if document.querySelector('.sales-order-fulfillment-form-wrapper')
  sales_order_fulfillment.$mount('.sales-order-fulfillment-form-wrapper')
