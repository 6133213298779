$ = require 'jquery'
# require 'bootstrap-datepicker' # loaded from Rails asset pipeline
Vue = require 'vue'
eventHub = require '../event_hub.coffee'

Vue.directive 'date-picker',
  params: ['date']

  # inserted: (el)->
  #   console.log 'INSERTED', el

  componentUpdated: (el)->
    console.log 'DatePicker Component Updated!'


  bind: (el, binding, vnode)->
    Vue.nextTick ->
      $el = $(el)
      attr_name = $el.data('attrName')
#      date_format = $el.data('dateFormat')

      $el.datepicker(
        format: 'yyyy-mm-dd'
        weekStart: 6
        todayBtn: false
        todayHighlight: true
        language: 'ar'
        startView: 0
      ).on 'changeDate', (e)->
        $(@).datepicker('hide')
        console.log 'date', e.date
        data = {}
        data[attr_name] = e.format
        eventHub.$emit('change-date', data)
        Vue.set(vnode.context, attr_name, e.format())

      # initial value
      date = vnode.context[attr_name]
      if date
        console.log 'DatePicker:: setting base date ', date
        $(el).datepicker('update', date)


  update: (el, binding, vnode)->
    if binding.value != binding.oldValue
      console.log "DatePicker update", binding.value
      $(el).datepicker('update', binding.value)


  unbind: (el)->
    console.log "DatePicker unbind"
    $(el).datepicker('destroy')

