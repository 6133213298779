import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  initialize() {
    this.dropdown = null;
  }

  connect () {
    this.bind_dropdown();
  }

  bind_dropdown() {
    this.dropdown = $(this.element).dropdown({
      match: 'text',
      fullTextSearch: 'exact',
      minCharacters: 3,

      showOnFocus: false,
      forceSelection: false,
      placeholder: false,

      throttle: 700,
      apiSettings: {
        cache: false,
        url: this.remoteUrl
      },
      saveRemoteData: false,
      filterRemoteData: true
    })
  }

  get remoteUrl() {
    let url = "/api/fi/accounts?q={query}&dropdown=1";

    if (this.removeParentAccounts) {
      url += "&without_parents=1"
    }

    return url
  }

  get removeParentAccounts() {
    return false
  }

  disconnect() {
  }
};
