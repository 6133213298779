$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'

module.exports =
  template: """
    <div class="pos--product--item" @click="addToCart" draggable='false'>
      <div class="item-image">
        <div class="item-price-tag num">{{format_money(item.retail_price, 2)}}</div>
        <img :src="item.image" />
      </div>
      <div class="item-name">
        {{ item.name }}
      </div>
    </div>
  """
  props: ['item']
  store: store



  methods:
    addToCart: ->
      console.log('STOCK_QUANTITY', @item.stock_quantity)

      if @item.stock_quantity > 0
        @$store.commit('addToCart', {item: @item})
      else
        greenPOS.notify_error("No stock quantity for (#{@item.name})")


