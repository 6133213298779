$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../event_hub.coffee'
_ = require 'lodash/core'

module.exports =
  template: "#product-cost-inputs-row"
  props: ['item', 'index']
  data: ->
    edit_mode: false
    product_attrs: {
      cost_price: 0,
      warranty_amount: 0,
      warranty_percent: 0,
      table_tax: 0,
      vat: 0,
      percent_discount: 0,
      early_payment_discount: 0,
      special_discount: 0,
      cash_discount: 0,
      offer_discount: 0,
    }


  created: ->
    console.log ''


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    save: ->
      url = "/product_price_edits/update_product?id=#{@item.id}"
      $.ajax(
        url: url
        data: { product_attrs: @item }
        method: 'put'
      ).done((response)=>
        console.log 'Success'
        @edit_mode = false
      ).fail(->
        console.warn 'Failed Request', url
      )


    edit: ->
      console.log "EDITING"
      @edit_mode = true
      Vue.nextTick =>
        @$el.querySelector('.print-input input').select()


    cancel: ->
      @edit_mode = false


    selectContent: (e)->
      Vue.nextTick => e.target.select()


  computed: {}
