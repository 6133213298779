$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
Q = require 'q'
_ = require 'lodash/core'
_groupBy = require 'lodash/groupBy'
_uniqBy = require 'lodash/uniqBy'
products_store = require '../../products_store.coffee'


window.jQuery = $
require 'semantic-ui-css/components/transition.js'
require 'semantic-ui-css/components/popup.js'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'sales_order_return[items_attributes][' + _uid + '][id]'">

        <div class="fied">
          <input type="checkbox"
            v-model="item.selected" value="on"
            :name="'sales_order_return[items_attributes][' + _uid + '][selected]'">
        </div>
      </td>
      <td>
        <div class="ui field item-name">{{ item.product_name }}</div>
        <input
          v-model="item.sales_order_item_id"
          :name="'sales_order_return[items_attributes][' + _uid + '][sales_order_item_id]'"
          class="input-small" type='hidden'>

        <input type="hidden" v-model="item.store_id" v-if='supply_order'
          :name="'sales_order_return[items_attributes][' + _uid + '][store_id]'">
      </td>

      <td v-if="show_store_field">
        <div class="select-store-wrapper">
          <div class='ui label text store-name'
            :class="{'blue': store_is_present}"
            @click="bind_product_popup"> {{store_name}} </div>
          <input type="hidden" v-model="item.store_id"
          :name="'sales_order_return[items_attributes][' + _uid + '][store_id]'">
        </div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="'sales_order_return[items_attributes][' + _uid + '][quantity]'"
            class="input-small item-quantity ltr">
        </div>
      </td>

      <td>
        <div class="not-returnd-quantity num">{{ not_returned_quantity }}</div>
      </td>

      <td>
        <div class="total-quantity num">{{ item.total_quantity }}</div>
      </td>

      <td>
        <div class="total num">{{ total }}</div>
      </td>
    </tr>
  """
  props: ['item', 'supply_order']
  data: ->
    store_name: "اختر مخزن"
    stores_list: []

  created: ->
    console.log 'I am ready'

  destroyed: ->
    console.log 'Destroyed ME'


  watch:
    'item.selected': (selected)->
      console.log 'SELECTED', selected
      console.log 'SELECTED : product', @item.product_id

  computed:
    show_store_field: ->
      !(@item.id or @supply_order)


    total: ->
      Big(round_quantity(@item.quantity))
        .times(round_number(@item.unit_price, 2))
        .toFixed(App.settings.money_round)

    not_returned_quantity: ->
      @item.not_returned_quantity - @item.quantity

    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0 and q <= @item.not_returned_quantity


    valid_store: ->
      @item.store_id != ""


    errors: ->
      quantity: @valid_quantity
      store_or_location: @valid_store


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    store_is_present: ->
      @item.store_id?


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["sor-item"]      = true
      list["item-#{@_uid}"] = true
      list


  methods:
    fetch_defects_stores: ->
      deferred = Q.defer()

      $.get("/api/stores/defects?location_id=#{window.App.location_id}")
        .done((response)->
          deferred.resolve(response)
        ).fail((error)->
          console.error "CAN NOT FETCH defects_stores"
          deferred.reject(error)
        )

      deferred.promise


    render_store_item: (store)->
      "<li class='clearfix stores-list-item'>
      <span class='store-name-in-list'
      data-id='#{store.store_id}'
      data-name='#{store.store_name}'>
        #{store.store_code or store.store_name}
      </span>
      </li>"


    render_stores_popup: (popup, $popup_el)->
      vm = this

      fetch_product = products_store.fetchItem(vm.item.product_id)
      defects_stores = vm.fetch_defects_stores()

      Q.spread [fetch_product, defects_stores], (product, defects_stores)->
        # products_store.fetchItem(vm.item.product_id).then (product)->
        vm.stores_list = product.stores_list
        return if vm.stores_list.length is 0

        html = "<ul class='ui middle aligned selection list sales-order-stores-list'>"

        grouped_stores_list = _groupBy vm.stores_list, 'location_id'

        _.each grouped_stores_list, (stores, location_id)->
          if +location_id is App.location_id
            stores = stores.concat(defects_stores)

          stores = _uniqBy stores, 'store_id'

          # stores for current_location and no location
          if +location_id is App.location_id or location_id is "null"
            filtered_stores = _.filter stores, (s)->
              +s.store_id in window.App.user_allowed_stores_ids

            if filtered_stores.length
              _.each filtered_stores, (store)->
                html += vm.render_store_item(store)

          else
            # Do nothing

        html += "</ul>"
        popup.html(html)

        popup.on 'click', '.stores-list-item', (e)->
          $this = $(@).find('.store-name-in-list')

          vm.$set vm.item, 'store_id', $this.data('id')

          vm.store_name = $this.data('name')
          $popup_el.popup 'hide'

      , (response)->
        console.error "Can not load Data!"
        popup.html('error')


    bind_product_popup: ->
      return if @hovered

      vm = @
      popupLoading = '<i class="notched circle loading icon green"></i> wait...'
      $el = $(vm.$parent.$el).find(".item-#{@_uid}")
      return unless $el.length
      $popup_el = $el.find('.store-name')

      @hovered = true

      $popup_el.popup
        inline: true
        on: 'click'
        exclusive: true
        hoverable: true
        position: 'bottom left'
        html: popupLoading
        variation: 'wide'
        lastResort: 'bottom right'
        delay:
          show: 400
          hide: 400
        onShow: (el)->
          vm.render_stores_popup(this, $popup_el)

      $popup_el.popup 'show'
