Vue = require 'vue'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'fi_record_transaction[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'fi_record_transaction[items_attributes][' + _uid + '][id]'">

        <div class="ui field" style="min-width: 200px;">
          <input v-model="item.description"s
            :name="'fi_record_transaction[items_attributes][' + _uid + '][description]'"
            class="">
        </div>
      </td>

      <td>
        <div class="ui field" style="min-width: 200px;">
            <accounts-dropdown
              :accounts='dropdown_accounts'
              v-model='item.account_id'
              :name="'fi_record_transaction[items_attributes][' + _uid + '][account_id]'"
              v-once
            ></accounts-dropdown>
        </div>
      </td>

      <td class="text-left">
        <div class="ui field" style="min-width: 100px;">
          <input v-model.number="item.debit_amount"
            :name="'fi_record_transaction[items_attributes][' + _uid + '][debit_amount]'"
            class="item-amount ltr">
        </div>
      </td>

      <td class="text-left">
        <div class="ui field" style="min-width: 100px;">
          <input v-model.number="item.credit_amount"
            :name="'fi_record_transaction[items_attributes][' + _uid + '][credit_amount]'"
            class="item-amount ltr">
        </div>
      </td>

      <td class='text-left'>
        <span class='num'>{{tax_amount}}</span>
      </td>

      <td>
        <div class='ui field'>
          <dropdown
              :options='tax_type_options'
              v-model='item.tax_type_id'
              :name="'fi_record_transaction[items_attributes][' + _uid + '][tax_type_id]'"
              prompt=""
              v-once
            ></dropdown>
        </div>
      </td>

      <td>
        <div class='ui field' style="min-width: 115px;">
          <datepicker
            :name="'fi_record_transaction[items_attributes][' + _uid + '][issued_at]'"
            v-model="item.issued_at"></datepicker>
        </div>
      </td>

      <td>
        <div class="ui field" style="min-width: 200px;">
            <dropdown
              :options='cost_centers'
              v-model='item.cost_center_id'
              :name="'fi_record_transaction[items_attributes][' + _uid + '][cost_center_id]'"
              prompt=""
              v-once
            ></dropdown>
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index', 'tax_types']

  components:
    'datepicker': require('../../components/simple_date_picker.coffee')
    'accounts-dropdown': require('./accounts_dropdown.coffee')
    'dropdown': require('../../shared_components/ui_dropdown.coffee')


  data: ->
    accounts: window.dropdown_accounts
    cost_centers: window.cost_centers || []


  created: ->
    console.log 'AccountItem::Created'


  mounted: ->
    console.log 'AccountItem => Assing data'
#    tags_field = document.querySelector('.tags-field')
#    tags_field.dataset.tagsCurrentValuesValue = this.cost_centers if tags_field
#    Vue.nextTick =>


  renderError: (h, err)->
# h('pre', { style: { color: 'red' }}, err.stack)
    greenPOS.notify_error "Error Rendering Page!"


  watch:
    'item.account_id': ()->
      eventHub.$emit('addEmptyItem')

    'item.tax_type_id': ->
      @item.tax_amount = @tax_amount.valueOf()
#      @item.debit_amount = @debit_with_tax.valueOf()
#      @item.credit_amount = @credit_with_tax.valueOf()



  methods:
    removeItem: (index)->
      @$set(@item, '_destroy', true)

      unless @item.id
        eventHub.$emit('removeItem', { index: index })


  computed:
    amount: ->
      debit = round_number(@item.debit_amount, 3)
      credit = round_number(@item.credit_amount, 3)
      if debit > 0 then debit else credit

    tax_rate: ->
      return Big(0) unless @item.tax_type_id?

      type = @tax_types.find (t)=> +t.id is +@item.tax_type_id
      Big(type?.tax_rate or 0).div(100)

    tax_amount: ->
      Big(@amount).times(@tax_rate)

    amount: ->
      if @item.debit_amount > 0
        @item.debit_amount
      else if @item.credit_amount > 0
        @item.credit_amount
      else
        0

    amount_with_tax: ->
      Big(@amount).times(@tax_rate.plus(1))

#    debit_with_tax: ->
#      Big(@item.debit_amount).times(@tax_rate.plus(1))

#    credit_with_tax: ->
#      Big(@item.credit_amount).times(@tax_rate.plus(1))

    tax_type_options: ->
      @tax_types.map (t)-> {name: t.code, value: t.id}

    dropdown_accounts:  ->
      if @item.id?
        @accounts
      else
        []

    isValid: ->
      true

    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list

