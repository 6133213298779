eventHub = require '../event_hub.coffee'

module.exports =
  template: """
    <tr class="form-field" :class="classList" v-if="item.item_type == 'section'">
      <td>
        <input type="hidden" v-model="item.item_type"
            :name="'project_clearance[items_attributes][' + _uid + '][item_type]'">
        <input type="hidden" v-model="item._destroy"
            :name="'project_clearance[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'project_clearance[items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <input  v-model="item.item_no"
            :name="'project_clearance[items_attributes][' + _uid + '][item_no]'"
            class="item-item_no">
        </div>

      </td>

      <td colspan='8'>
        <div class="ui field">
          <input v-model="item.description"
            :name="'project_clearance[items_attributes][' + _uid + '][description]'"
            class="font-weight-500 fs-5 item-description">
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>

    <tr class="form-field" :class="classList" v-else-if="item.item_type == 'normal'">
      <td>
        <input type="hidden" v-model="item.item_type"
            :name="'project_clearance[items_attributes][' + _uid + '][item_type]'">
        <input type="hidden" v-model="item._destroy"
            :name="'project_clearance[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'project_clearance[items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <input  v-model="item.item_no"
            :name="'project_clearance[items_attributes][' + _uid + '][item_no]'"
            class="item-item_no">
        </div>

      </td>

      <td>
        <div class="ui field">
          <input v-model="item.description"
            :name="'project_clearance[items_attributes][' + _uid + '][description]'"
            class="item-description">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model="item.unit"
            :name="'project_clearance[items_attributes][' + _uid + '][unit]'"
            class="">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model.number="item.contract_quantity"
            :name="'project_clearance[items_attributes][' + _uid + '][contract_quantity]'"
            class=" ltr">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model.number="item.unit_price"
            :name="'project_clearance[items_attributes][' + _uid + '][unit_price]'"
            class=" ltr">
        </div>
      </td>


      <td>
        <div class="ui field">
          <input v-model.number="item.prev_quantity"
            :name="'project_clearance[items_attributes][' + _uid + '][prev_quantity]'"
            class=" ltr">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input v-model.number="item.current_quantity"
            :name="'project_clearance[items_attributes][' + _uid + '][current_quantity]'"
            class=" ltr">
        </div>
      </td>


      <td>
        <div class="ui field">
          <span class='num'>{{total_quantity.valueOf()}}</span>
        </div>
      </td>

      <td>
        <div class="ui field">
          <span class='num'>{{total_amount.valueOf()}}</span>
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']
#  data: ->
#    logo: null


  renderError: (h, err)->
    # h('pre', { style: { color: 'red' }}, err.stack)
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    removeItem: (index)->
      if @item.id
        @$set(@item, '_destroy', true)
      else
        eventHub.$emit('remove-item', { index: index })


  computed:
    total_quantity: ->
      prev_quantity = round_quantity(@item.prev_quantity)
      current_quantity = round_quantity(@item.current_quantity)
      Big(prev_quantity).plus(current_quantity)

    total_amount: ->
      unit_price = round_number(@item.unit_price, 2)
      @total_quantity.times(unit_price)


    isValid: ->
      @item.description? and @item.description != ""

    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list

