store = require '../../stores/default.coffee'
_debounce = require 'lodash/debounce'

module.exports =
  template: """
    <div class="ui form">
      <div class="field">
        <div class="ui left icon input">
          <i class="search icon"></i>
          <input type="text"
                 placeholder="Search products by name or barcode"
                 v-model="searchQuery">
        </div>
      </div>
    </div>
  """

  store: store

  data: ->
    searchQuery: ""


  watch:
    searchQuery: (newVal, oldVal)->
      if newVal and newVal isnt oldVal and newVal.length > 2
        @searchProducts()


  methods:
    searchProducts: _debounce ->
      @$store.dispatch('searchProducts', {query: @searchQuery}).then (data)=>
        console.info "result", data.products.size
    , 500



