$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
store = require '../stores/default.coffee'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
require '../directives/ui_dropdown.coffee'



#  el: '.location-stock-transfer-wrapper'
location_stock_transfer = new Vue
  store: store
  data: ->
    items: []
    errors: []
    is_ready: false
    request_status: window.location_stock_transfer?.request_status
    source_location_id: window.location_stock_transfer?.source_location_id
    destination_location_id: window.location_stock_transfer?.destination_location_id
    context: window.location_stock_transfer?.context

  components:
    'location-stock-transfer-request-item': require('./components/location-stock-transfer-request-item.coffee')
    'location-stock-transfer-response-item': require('./components/location-stock-transfer-response-item.coffee')
    'location-stock-transfer-receivable-item': require('./components/location-stock-transfer-receivable-item.coffee')

  mixins: [formSubmitMixin]

  created: ->
    el = $('.location-stock-transfer-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)

    products_store.fetchSimpleItems(@).then ->
      if window.location_stock_transfer_items.length > 0
        Vue.set(location_stock_transfer, 'items', window.location_stock_transfer_items)
      else
        location_stock_transfer.addItem()


  mounted: ->
    # @$store.dispatch('LOAD_PRODUCTS')


  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})

    products_is_ready: (newVal)->
      if newVal
        if window.location_stock_transfer_items
          Vue.set(location_stock_transfer, 'items', window.location_stock_transfer_items)
        else
          location_stock_transfer.addItem() if location_stock_transfer


  computed:
    count: -> @items.length

    # products: -> @$store.state.products
    products_is_ready: -> @$store.state.products_is_ready
    # is_ready: -> @products_is_ready

    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    isValidLocations: ->
      @source_location_id isnt null and @destination_location_id isnt null


    locationsIsDifferent: ->
      @source_location_id isnt @destination_location_id


    isValidAttributes: ->
      @isValidLocations and @locationsIsDifferent


    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    addItem: (event)->
      item = {
        quantity: 1,
        store_id: null,
        product_id: null,
        unit_of_measure_id: null,
        context: 'request'
      }
      @items.push item
      event and event.preventDefault()


    addResponseItem: (event)->
      item = {
        quantity: 1,
        store_id: null,
        product_id: null,
        unit_of_measure_id: null,
        context: 'response'
      }
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      q = @round_quantity(item.quantity)
      valid = item.product_id isnt null and item.quantity != "" and q > 0
      if @validate_items_store()
        console.log "Validating store"
        valid = valid and item.store_id
      valid


    validate_items_store: ->
      @request_status isnt 'draft'


    is_request: ->
      @context is "request"


    is_response: ->
      @context is "response"


    selectContent: (e)->
      Vue.nextTick => e.target.select()



if document.querySelector('.location-stock-transfer-wrapper')
  location_stock_transfer.$mount('.location-stock-transfer-wrapper')

window.location_stock_transfer_view = location_stock_transfer
