const charts = {
  render_bar: (selector, data, title, categoryField, valueField, colTitle) => {
    AmCharts.makeChart(selector, {
      "path": "/static/amcharts/",
      "type": "serial",  //serial, pie, xy, radar, funnel, gauge, map, stock.
      "theme": "light",
      titles: [
        {
          "text": title,
          "size": 15,
          // color: '#ccc',
        }
      ],

      "legend": {  //https://docs.amcharts.com/3/javascriptcharts/AmLegend
        "horizontalGap": 10,
        "maxColumns": 2,
        "position": "bottom",
        "align": "center",
        "useGraphSettings": true,
        "markerSize": 10,

        "valueText": "[[value]]",
        "valueWidth": 100,
        "valueAlign": "left",
        "equalWidths": false,
        "periodValueText": "[[value.sum]]"
      },

      //http://docs.amcharts.com/3/javascriptcharts/ChartCursor#pan
      "balloon": {
        "fixedPosition": true
      },
      "chartCursor": {
        "cursorAlpha": 0, // Col background
        "fullWidth": false
      },

      dataProvider: data,

      "valueAxes": [{  //https://docs.amcharts.com/3/javascriptcharts/ValueAxis
        // "stackType": "regular",
        "axisAlpha": 0.5,
        "gridAlpha": 0.2,
      }],

      dataDateFormat: 'YYYY-MM-DD',
      sequencedAnimation: true,
      startAlpha: 0.1,
      startDuration: 1,

      categoryField: categoryField,

      "categoryAxis": {  //https://docs.amcharts.com/3/javascriptcharts/CategoryAxis
        "gridPosition": "start",
        "axisAlpha": 0,
        "gridAlpha": 0,
        "position": "left",
        autoWrap: true,
        parseDates: true,
        equalSpacing: true,
      },

      "graphs": [{
        "balloonText": "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
        "fillAlphas": 0.8,
        "labelText": "[[value]]",
        "labelPosition": 'inside',
        // "labelRotation": 90,
        "lineAlpha": 0.3,
        "title": colTitle, // col title
        "type": "column",
        "color": "#000000",

        valueField: valueField
      }],

      autoResize: true,
      responsive: {  //https://www.amcharts.com/tutorials/making-charts-responsive/
        enabled: true,
        rules: [
          {
            "maxWidth": 600,
            "overrides": {
              "valueAxes": {
                "labelsEnabled": false,
              },
              "categoryAxis": {
              }
            }
          },
        ],
      },
      panEventsEnabled: false,
      processTimeout: 300, // millisecond
    })
  }
}


window.Charts = charts;
