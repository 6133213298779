formSubmit =
  data: ->
    form_submitted: false
    form_submitted_on: null


  methods:
    submit: (e)->
      if @form_submitted
        console.warn "Form is already submitted!"
        notify("Form submitted, Please wait", {type: 'warning'})
        return
      else
        # @NOTE: We can enable submit action after x seconds
        @form_submitted_on = e.timeStamp

      unless @isValid
        console.log "Form is not valid"
        notify("Form is not valid, please review for details", {type: 'warning'})

      form = e.currentTarget

      form.submit()
      @form_submitted = true
      @dimForm()


    dimForm: ->
      dimmer = '<div class="ui inverted dimmer"><div class="ui medium text loader">Loading..</div></div>'
      $(@$el).append(dimmer)
      @$el.querySelector('.ui.dimmer').classList.add('active')


module.exports = formSubmit
