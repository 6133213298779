$ = require 'jquery'
Vue = require 'vue'


low_quantity_report_filter = new Vue
#  el: '.low-quantity-report-wrapper'
  data: ->
    errors: []
    user_locations: window.user_locations or []
    user_stores: window.user_stores or []
    stores_for_options: []
    store_id: window.default_store
    location_id: window.default_location


  created: ->
    el = document.querySelector('.low-quantity-report-wrapper')
    return unless el

    @set_stores_form_location(@location_id)


  mounted: ->
    @render_stores_dropdown()
    setTimeout ->
      $('.js-stores-dropdown').dropdown 'set selected', window.default_store
    , 50


  watch:
    location_id: (newVal, oldVal)->
      @clear_store() if oldVal
      @set_stores_form_location(newVal)



  computed:
    count: -> 0


  methods:
    set_stores_form_location: (location_id)->
      @stores_for_options = @user_stores.filter (s)->
        +s.location_id is +location_id


    clear_store: ->
      console.log "CLEAR STORES"
      @store_id = null
      $('.js-stores-dropdown').dropdown('clear')


    render_stores_dropdown: ->
      $('.js-stores-dropdown').dropdown
        fullTextSearch: true
        showOnFocus: true
        forceSelection: false
        placeholder: false




if document.querySelector('.low-quantity-report-wrapper')
  low_quantity_report_filter.$mount('.low-quantity-report-wrapper')
