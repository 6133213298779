$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'

module.exports =
  template: """
    <tr class="form-field order-item" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'purchase_order[cost_items_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="item._destroy"
          :name="'purchase_order[cost_items_attributes][' + _uid + '][_destroy]'">

        <div class="ui field" :class="{ 'error': !valid_name }">
          <input v-model="item.name"
            :name="'purchase_order[cost_items_attributes][' + _uid + '][name]'"
            @keydown.enter.prevent
            @keydown.up="prevItem"
            @keydown.down="nextItem"
          class="input-small item-name">
        </div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_cost }">
          <input v-model.number="item.cost" :name="'purchase_order[cost_items_attributes][' + _uid + '][cost]'" class="input-small item-unit-price">
        </div>
      </td>

      <td>
        <div class="total num">{{total}}</div>
      </td>


      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  mounted: ->
    console.log "POCI Mounted!"

  mixins: [inputKeyHandlers]
  props: ['index', 'item']
  data: -> {}

  created: ->
    console.log 'POI: I am ready'


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      eventHub.$emit('remove-cost-item', { index: index })


    scroll_to_current_element: ->
      body_scrollTop = $('body').scrollTop()
      el_offsetTop = @_el().offset().top

      position_on_viewport = el_offsetTop - body_scrollTop
      mid_viewport = window.innerHeight/2

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        $("html,body").stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 800).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


  computed:
    total: ->
      if @isValid
        Big(@item.cost).toFixed(App.settings.money_round)
      else
        0

    valid_name: ->
      @item.name? and @item.name != ""


    valid_cost: ->
      @item.cost != "" and round_money(@item.cost) > 0


    errors: ->
      name: @valid_name
      cost: @valid_cost


    isValid: ->
      if @item?._destory
        true
      else
        not _.some(@errors, (v, k)-> v is false)


    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list
