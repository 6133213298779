$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
products_store = require '../../products_store.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'

module.exports =
  template: """
    <tr class="form-field order-item" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'purchase_order_request[items_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="item._destroy"
          :name="'purchase_order_request[items_attributes][' + _uid + '][_destroy]'">

        <div class="ui field">
         <select
          v-ui-dropdown="item.product_id"
          :name="'purchase_order_request[items_attributes][' + _uid + '][product_id]'"
          :data-uid="_uid"
          class='product-dropdown'></select>
        </div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input v-model.number="item.quantity"
            :name="'purchase_order_request[items_attributes][' + _uid + '][quantity]'"
            @keydown.enter.prevent
            @keydown.up="prevItem"
            @keydown.down="nextItem"
          class="input-small item-quantity ltr">
        </div>
      </td>

      <td>
        <div class="ui field">
         <select
          :name="'purchase_order_request[items_attributes][' + _uid + '][store_id]'"
          :data-uid="_uid"
          class='store-dropdown ui dropdown'>
            <option v-for="store in stores" :value="store[1]">
              {{ store[0] }}
            </option>
        </select>
        </div>
      </td>

      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  mounted: ->
    console.log "POI Mounted!"

  mixins: [inputKeyHandlers]
  props: ['index', 'item']
  data: ->
    duplicated: false
    dropdown: null
    stores: window.stores || []

  created: ->
    console.log 'POI: I am ready'
    eventHub.$on('change-product-id', @change_product_id)
    eventHub.$on('duplicated-items', @viewErrorHandler)
    # fetch data on edit
    @getProductData() if @item.id


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")

    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      if data.uid is @_uid && parseInt(data.id) isnt @item.product_id
        @$set(@item, 'product_id', data.id)
        @getProductData()
        @focusOnQuantity()


    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    # store_id: ->
    #   parseInt $('#purchase_order_request_store_id').val()


    getProductData: ->
      @get_product(@item.product_id).then (item)=>
        # stock = _.find item.stores_list, {store_id: @store_id()}
        # @stock_on_hand = round_quantity(stock.quantity) if stock

        @scroll_to_current_element()
      , (response)->
        console.error response


    viewErrorHandler: (data)->
      vm = @
      found = _.find(data.items, (i)-> i.product_id is vm.item.product_id)
      @duplicated = if found then true else false


    scroll_to_current_element: ->
      body_scrollTop = $('body').scrollTop()
      el_offsetTop = @_el().offset().top

      position_on_viewport = el_offsetTop - body_scrollTop
      mid_viewport = window.innerHeight/2

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        $("html,body").stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 800).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


  watch:
    'item.product_id': (new_id, old_id)->
      # add new empty field
      if old_id is null
        eventHub.$emit('add-item')


  computed:
    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0


    valid_product: ->
      !!@item.product_id


    errors: ->
      quantity: @valid_quantity
      product: @valid_product


    isValid: ->
      if @item._destory
        true
      else
        not _.some(@errors, (v, k)-> v is false)


    classList: ->
      list = {}
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list
