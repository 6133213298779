$ = require 'jquery'
Vue = require 'vue'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
_ = require 'lodash/core'

require '../directives/ui_dropdown.coffee'


sales_order_reservation = new Vue
#  el: '.sales-order-reservation-component'
  data: ->
    id: window.sales_order_reservation?.id
    status: window.sales_order_reservation?.status
    items: window.sales_order_reservation_items || []
    is_ready: false

  components:
    'sales-order-reservation-item': require('./components/sales-order-reservation-item.coffee')


  created: ->
    el = $('.sales-order-reservation-component')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('update-total', @update_html_total)

    products_store.fetchSimpleItems(@).then =>
#      @$set sales_order_reservation, 'items', window.sales_order_reservation_items


  computed:
    can_edit: ->
      @status is 'open'


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null


    total: ->
      _.reduce @items, (sum, item)=>
        if @itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum.plus(0)
      , Big(0)


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    isValid: ->
      @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    addItem: ->
      item = {product_id: null, quantity: 1, unit_price: 0, new_record: true}
      @items.push item


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      q = @round_quantity(item.quantity)
      p = @round_money(item.unit_price)
      item.product_id isnt null and q isnt 0 and p isnt 0


    update_html_total: ->
      total = @format_number(@total.toFixed(2), 2)
      $('.total-value').text(total)


  events:
    'remove-item': (index)-> @removeItem(index)




if document.querySelector('.sales-order-reservation-component')
  sales_order_reservation.$mount('.sales-order-reservation-component')
