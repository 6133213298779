$ = require 'jquery'
Vue = require 'vue'
Big = require 'big.js'
_ = require 'lodash/core'
_groupBy = require 'lodash/groupBy'
products_store = require '../products_store.coffee'
inputKeyHandlers = require '../mixins/input_key_handlers.coffee'

window.jQuery = $
require 'semantic-ui-css/components/transition.js'
require 'semantic-ui-css/components/popup.js'


module.exports = Vue.extend
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'general_purchase_return_fulfillment[items_attributes][' + _uid + '][id]'">

        <input type="hidden" v-model="item.product_id"
          :name="'general_purchase_return_fulfillment[items_attributes][' + _uid + '][product_id]'">

        <input type="hidden"
          v-model="item.general_purchase_return_item_id"
          :name="'general_purchase_return_fulfillment[items_attributes][' + _uid + '][general_purchase_return_item_id]'"
          class="input-small">

        <div class="fied">
          <input type="checkbox"
            v-model="item.selected" value="on"
            :name="'general_purchase_return_fulfillment[items_attributes][' + _uid + '][selected]'">
        </div>
      </td>
      <td>
        <div class="ui field item-name">{{ item.product_name }}</div>
      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_quantity }">
          <input
            v-model.number="item.quantity"
            :name="'general_purchase_return_fulfillment[items_attributes][' + _uid + '][quantity]'"
            @keydown.enter.prevent
            @keydown.up="prevItem"
            @keydown.down="nextItem"
            class="input-small item-quantity ltr">
        </div>
      </td>

      <td class='text-center'>
        <div class="num">{{ round_quantity(not_packed_quantity) }}</div>
      </td>

      <td>
        <div class="total-quantity num">{{ round_quantity(item.total_quantity) }}</div>
      </td>

    </tr>
  """
  props: ['item', 'pack_store_id']
#  mixins: [inputKeyHandlers]

  data: ->
    hovered: false
    stores_list: []
    stock_on_hand: 0

  created: ->
    console.log 'I am ready'

#    @getProductData()


  destroyed: ->
    console.log 'Destroyed ME'


  watch:
    'isValid': (val)->
      @$set(@item, 'valid', val)

    pack_store_id: (id)->
      @set_stock()


  computed:
    not_packed_quantity: ->
      @item.not_packed_quantity - @item.quantity


    valid_quantity: ->
      q = round_number(@item.quantity, 3)
      @item.quantity != "" and q > 0 and q <= @item.not_packed_quantity


    stock_is_enough: ->
      if @item.store_id?
        @round_quantity(@stock_on_hand) >= @round_quantity(@item.quantity)
      else
        true


    errors: ->
      quantity: @valid_quantity
      stock_is_enough: @stock_is_enough


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["sof-item"]      = true
      list["item-#{@_uid}"] = true
      list


  methods:
    get_product: (product_id)->
      products_store.fetchItem(product_id)


    set_stock: ->
      return unless @item.product_id

      @get_product(@item.product_id).then (product)=>

        stores_list = product.stores_list
        return if stores_list.length is 0

        store = _.find(stores_list, (store)=> +store.store_id is +@pack_store_id)

        @stock_on_hand = if store
          @round_quantity store.filtered_stock_on_hand
        else
          0

        @ready = true

      , (response)->
        console.error response
