$ = require 'jquery'
window.jQuery = $
_ = require 'lodash/core'
_groupBy = require 'lodash/groupBy'
Vue = require 'vue'
Big = require 'big.js'
products_store = require '../products_store.coffee'
products_cache = require '../../products_caching.coffee'
inputKeyHandlers = require '../mixins/input_key_handlers.coffee'
UnitOfMeasureHandlers = require '../mixins/unit_of_measure_handlers.coffee'
eventHub = require '../event_hub.coffee'

store = require '../stores/default.coffee'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item.id"
          :name="'general_purchase_return[items_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="item._destroy"
          :name="'general_purchase_return[items_attributes][' + _uid + '][_destroy]'">

        <div class="ui field" v-if='!product' key='products-dropdown'>
            <ui-dropdown
              :products='products'
              v-model='item.product_id'
              :name="'general_purchase_return[items_attributes][' + _uid + '][product_id]'"></ui-dropdown>
        </div>

        <div class="ui field" key='product-name' v-else>
            <input type="hidden" v-model="item.product_id"
              :name="'general_purchase_return[items_attributes][' + _uid + '][product_id]'">
            <span class='product-name p-2'>
              {{ product.name }}
            </span>
        </div>
      </td>

      <!--<td>
        <div class="select-store-wrapper">
          <div class='ui label text store-name'> {{location_name || store_name}} </div>
          <input type="hidden" v-model="item.store_id"
          :name="'general_purchase_return[items_attributes][' + _uid + '][store_id]'">
          <input type="hidden" v-model="item.location_id"
          :name="'general_purchase_return[items_attributes][' + _uid + '][location_id]'">
        </div>
      </td>-->

      <td>
        <div class='two fields mb-0'>
          <div class="ui field" :class="{ 'error': !valid_quantity }">
            <input v-model.number="item.quantity"
              :name="'general_purchase_return[items_attributes][' + _uid + '][quantity]'"
              @keydown.enter.prevent="nextInput('.item-unit-price')"
              @keydown.up="prevItem('.item-quantity')"
              @keydown.down="nextItem('.item-quantity')"
              @keydown.alt.left="nextUnitPriceInput"
              @focus="selectContent"
              class="input-small item-quantity ltr">
          </div>
          <input type='hidden' v-model='item.unit_of_measure_id'
              :name="'general_purchase_return[items_attributes][' + _uid + '][unit_of_measure_id]'" />
          <span class="p-2 d-inline-block">{{product && product.unit_of_measure.name}}</span>
        </div>

      </td>

      <td>
        <div class="ui field" :class="{ 'error': !valid_unit_price }">
          <input
            v-model.number="item.unit_price"
            :name="'general_purchase_return[items_attributes][' + _uid + '][unit_price]'"
            class="input-small item-unit_price ltr">
        </div>
      </td>

      <td>
        <div class="total num">{{ total }}</div>
      </td>


      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['index', 'item']
  mixins: [inputKeyHandlers, UnitOfMeasureHandlers]
  store: store

  components:
    'ui-dropdown': require('./products_ui_dropdown.coffee')

  data: ->
    product: null
    duplicated: false
    dropdown: null
    store_name: "اختر مخزن"
    location_name: null
    stores_list: []
    hovered: false
    ready: false


  created: ->
    console.log 'I am ready'
#    eventHub.$on('change-product-id', @change_product_id)
    eventHub.$on('duplicated-items', @viewErrorHandler)
    eventHub.$on('scroll_to_current_element', @scroll_to_current_element)

    # fetch data on edit
    @getProductData()# if @item.id


  mounted: ->
    Vue.nextTick =>
      $el = @_el().find('.item-unit-price')
      $el.popup(
        on: 'manual',
        inline: true,
        content: "Invalid Price",
      ).popup 'hide'



  watch:
    'isValid': (nVal, oVal)->
      @item.valid = nVal

    'item.product_id': (newID, oldID)->
      if oldID is null
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})

      if parseInt(newID) isnt parseInt(oldID)
        @getProductData()
        @focusOnQuantity()


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Item!"


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")

    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    get_product: (product_id)->
      products_store.fetchItem(product_id)


    getProductData: ->
      return unless @item.product_id

      @get_product(@item.product_id).then (product)=>
        @product = product
        @setDefaultUoM(@product.purchase_uom)
        @updateUnitPrice(@product.cost_price) if @item.unit_price is 0

        if @item.store_id
          stock = @product.stores_list.find (s)=> s.store_id == @item.store_id
          @store_name = stock.store_name if stock

        @item.quantity = @round_quantity @item.quantity

        @ready = true

        @stores_list = @product.stores_list
        @bind_stores_popup()

      , (response)->
        console.error response


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    nextProduct: (e)->
      console.log 'ENTER'

      e.preventDefault()
      $next = $(".item-#{@_uid}").next()
      if $next.length
        $next.find('.selectized.product-dropdown')[0].selectize.focus()
      else
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})


    nextQuantityInput: (e)->
      @_el().find('.item-quantity').focus().select()
      return false


    nextInput: (item_class, e)->
      @_el().find(item_class).focus().select()
      return false


    viewErrorHandler: (data)->
      vm = @
      found = _.find(data.items, (i)-> i.product_id is vm.item.product_id)
      @duplicated = if found then true else false


    render_store_item: (store)->
      "<li class='clearfix stores-list-item'>
      <span class='store-name-in-list'
      data-id='#{store.store_id}'
      data-name='#{store.store_name}'>
        #{store.store_code or store.store_name}
      </span>
      </li>"


    render_stores_popup: (popup, $popup_el)->
      vm = this
      return if vm.stores_list.length is 0

      html = """
        <ul class='ui middle aligned selection list sales-order-stores-list'>
      """

      stores_list = _.filter vm.stores_list, (s)->
        round_quantity(s.filtered_stock_on_hand) > 0
      grouped_stores_list = _groupBy stores_list, 'location_id'

      _.each grouped_stores_list, (stores, location_id)->
        # stores form current_location or with no location
        if +location_id is App.location_id
          filtered_stores = _.filter stores, (s)->
            +s.store_id in window.App.user_allowed_stores_ids

          if filtered_stores.length
            _.each filtered_stores, (store)->
              html += vm.render_store_item(store)


        else if location_id is "null"
          filtered_stores = _.filter stores, (s)->
            +s.store_id in window.App.user_allowed_stores_ids

          _.each filtered_stores, (store)->
            html += vm.render_store_item(store)

        else if location_id isnt "null"
          # Do nothing

        else
          # Do nothing

      html += "</ul>"
      popup.html(html)

      popup.on 'click', '.stores-list-item', (e)->
        $this = $(@).find('.store-name-in-list')

        vm.$set vm.item, 'store_id', $this.data('id')
        vm.$set vm.item, 'location_id', null

        vm.store_name = $this.data('name')
        $popup_el.popup 'hide'


    bind_stores_popup: ->
      return if @hovered

      vm = @
      popupLoading = '<i class="notched circle loading icon green"></i> wait...'
      $el = @_el()
      return unless $el.length

      $popup_el = $el.find('.store-name')
      @hovered = true

      $popup_el.popup
        inline: true
        on: 'click'
        exclusive: true
        hoverable: true
        position: 'bottom left'
        html: popupLoading
        variation: 'wide'
        lastResort: 'bottom right'
        delay:
          show: 400
          hide: 400
        onShow: (el)->
          vm.render_stores_popup(this, $popup_el)

      # $popup_el.popup 'show' unless vm.item.id


    scroll_to_current_element: (data)->
      el = data.el
      return unless el

      $scroll_element = $('.page-grid')
      # body_scrollTop = $('body').scrollTop()
      body_scrollTop = $scroll_element.scrollTop()
      # el_offsetTop = @_el().offset().top
      # el_offsetTop = @_el().get(0).offsetTop
      el_offsetTop = el.get(0).offsetTop

      # console.debug 'el_offsetTop', el_offsetTop
      # console.debug 'body_scrollTop', body_scrollTop

      position_on_viewport = el_offsetTop - body_scrollTop + 600
      mid_viewport = window.innerHeight/2

      # console.debug 'position_on_viewport', position_on_viewport
      # console.debug 'mid_viewport', mid_viewport

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        # $("html,body").stop().animate({
        $scroll_element.stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 1000).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


    selectContent: (e)->
      Vue.nextTick => e.target.select()


  computed:
    products: -> products_cache.products

    total: ->
      Big(round_quantity(@item.quantity))
        .times(round_number(@item.unit_price, 2))
        .toFixed(App.settings.money_round)

    valid_quantity: ->
      @item.quantity != "" and @item.quantity > 0

    valid_unit_price: ->
      price = round_number(@item.unit_price, 3)
      @item.unit_price != "" and price > 0

    valid_product: ->
      !!@item.product_id

    errors: ->
      quantity_present: @valid_quantity
      product: @valid_product
      unit_price: @valid_unit_price


    isValid: ->
      not _.some @errors, (v, k)-> v is false



    classList: ->
      list = {}
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['low-quantity']  = @has_low_quantity
      list['hidden']        = @item._destroy
      list["so-item"]       = true
      list["item-#{@_uid}"] = true
      list
