$ = require 'jquery'
Vue = require 'vue'
require '../directives/date_picker.coffee'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'

view = new Vue
  data:
    product_id: window.production_order?.product_id || null
    bill_of_material_id: window.production_order?.bill_of_material_id || null
    factory_id: window.production_order?.factory_id || null
    quantity: window.production_order?.quantity || 1
    unit_of_measure_id: window.production_order?.unit_of_measure_id
    scheduled_at: window.scheduled_at
    responsible_id: window.production_order?.responsible_id
    batch_number: window.production_order?.batch_number
    work_orders: window.work_orders || []
    bill_of_material_name: ''
    bill_of_material: null
    order_components: window.components || []
    bom_components: []
    custom_components: []
    unit_of_measure: null
    is_ready: true
    bill_of_materials: []

  components:
    'production-component': require('./components/production_component.coffee')
    'production-custom-component': require('./components/production_custom_component.coffee')
    'ui-dropdown': require('../components/ui_dropdown.coffee')

  mixins: [formSubmitMixin]


  created: ->
    el = $('.mrp-order-form-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)

    @load_bom_on_edit()
    @init_custom_components()


  watch:
    product_id: ()->
#      @fetchBoM()
      @fetch_bill_of_materials()

    bill_of_material_id: ->
      @bom_change_actions()

    quantity: ->
      @buildProductionComponent()


  computed:
    isValidAttributes: ->
      @product_id? and @bill_of_material_id? and @factory_id?

    isValidComponents: ->
      @custom_components
        .filter( (c)=> c.product_id? )
        .every( (c)=> c.valid )

    isValid: ->
      @isValidAttributes and @isValidComponents

    submit_is_disabled: ->
      not @isValid

    components_from_bom: ->
      @order_components.filter (c)=> c.bom_component_id isnt null


  methods:
    validate_component_stock: (valid)->
      @component_stock_valid = valid

    addItem: (event)->
      item = {
        product_id: null,
        to_consume_quantity: 1,
        _destroy: null,
        bom_component_id: null,
        valid: true
      }
      @custom_components.push item
      event and event.preventDefault()

    removeItem: (data)->
      @custom_components.splice(data.index, 1)

    init_custom_components: ->
      products_store.fetchSimpleItems(@).then =>
        if window.components.length > 0
          # Vue.set(@, 'items', window.bill_items)
          @custom_components = window.components.filter (c)=> c.bom_component_id is null

    load_bom_on_edit: ->
      return unless @product_id

      @fetch_bill_of_materials().then(=>
        @bom_change_actions()
      ).catch((e)->
        console.error 'Failed to set bom'
        console.error e
      )

    bom_change_actions: ->
      bom = @bill_of_materials.find((bom)=> bom.id is +@bill_of_material_id)
      return unless bom

      @set_bom(bom)
      @buildProductionComponent()

    fetch_bill_of_materials: ->
      @bill_of_materials = []
      url = "/api/mrp/bill_of_materials?product_id=#{@product_id}"

      Q($.get(url, (response)=>
        console.log 'R', response
        @bill_of_materials = response

        eventHub.$emit('refresh-dropdown', {options: @bill_of_materials, uid: @_uid})

      ).fail(->
        console.warn 'Failed to Get BoMs '
      ))

    set_bom: (bom)->
      @bill_of_material = bom
      @bill_of_material_name = bom.name
      @bom_components = bom.components
      @unit_of_measure = bom.unit_of_measure
      @unit_of_measure_id = bom.unit_of_measure?.id

#    # Fetch
#    #   - Bom [id, name]
#    #   - BomComponents [product_name, quantity]
#    #   - Routing [id, name]
#    #   - RoutingOperations [name, work_center_name]
#    fetchBoM: ->
#      url = "/api/mrp/bill_of_materials?product_id=#{@product_id}"
#
#      $.get(url, (response)=>
#        console.log 'R', response
#
#        @bill_of_material = response
#        @bill_of_material_id = response.id
#        @bill_of_material_name = response.name
#        @bom_components = response.components
#        @unit_of_measure = response.unit_of_measure
#        @unit_of_measure_id = response.unit_of_measure?.id
#
#        @buildProductionComponent()
#
#      ).fail(->
#        console.warn 'Failed to Get BoM '
#      )


    buildProductionComponent: ->
      order_quantity = round_quantity(@quantity)

      return if order_quantity is 0

      @order_components = @bom_components.map (comp)=>
        {
          product_name: comp.product_name,
          quantity: comp.quantity * order_quantity / @bill_of_material.quantity,
          unit_name: comp.unit_name
        }


el = document.querySelector('.mrp-order-form-wrapper')
view.$mount(el) if el
