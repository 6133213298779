import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import $ from 'jquery';

function changeOrderDate({ item, to, from, oldIndex, newIndex, oldDraggableIndex, newDraggableIndex, clone, pullMode }) {
  const id = item.dataset.id
  // const currentDate = from.getAttribute('data-date')
  const newDate = to.dataset.date

  console.log('NewDate', newDate);

  // if (currentDate !== newDate) {
  //   console.log('MOved')
  // }

  let data = { date: newDate };
  // let url = item.dataset.dragUrl;
  let url = "/mrp/production_orders/:id/schedule_at".replace(':id', id)

  $.ajax({
    type: 'PUT',
    dataType: 'json',
    url: url,
    data: data
  }).done((r)=> {
    console.log('response', r);
  });
}

const sortableList = document.querySelectorAll("[data-behavior='sortable']")
sortableList.forEach((list)=> {
  // console.log('List', list);

  new Sortable(list, {
    group: 'mrp-planning',
    // ghostClass: 'bg-secondary',
    // chosenClass: 'bg-light',
    animation: 150,
    easing: 'cubic-bezier(.17,.67,.83,.67)',
    sort: false,
    filter: ".ignore-order",
    onAdd: changeOrderDate,
    // onStart: ()=> {
    //   sortableList.forEach((el)=> el.classList.add('bordered', 'border-primary'));
    // },
    // onEnd: ()=> {
    //   sortableList.forEach((el)=> el.classList.remove('bordered', 'border-primary'));
    // }
  })
})
