$ = require 'jquery'
window.jQuery = $
_ = require 'lodash/core'
Vue = require 'vue'
Big = require 'big.js'
products_store = require '../../products_store.coffee'
products_cache = require '../../../products_caching.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
UnitOfMeasureHandlers = require '../../mixins/unit_of_measure_handlers.coffee'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'


module.exports =
  template: "#sales-order-item-template"
  props: ['index', 'item']

  mixins: [inputKeyHandlers, UnitOfMeasureHandlers]

  components:
    'units-dropdown': require('../../components/units_dropdown.coffee')
    'stores-dropdown': require('./stores-dropdown.coffee')


  store: store

  data: ->
    product: null
    stock_on_hand: 0
    duplicated: false
    store_name: "المخزن"
    stores_list: []
    ready: false
    first_load_from_edit: false


  created: ->
    console.log 'I am ready'
    eventHub.$on('duplicated-items', @viewErrorHandler)

    if @item.id
      @first_load_from_edit = true

    # fetch data on edit
    @getProductData()


  mounted: ->
    # nonthing


  watch:
    'isValid': (nVal, oVal)->
      @item.valid = nVal

    'unit_of_measure': ()->
      if @first_load_from_edit
        @first_load_from_edit = false
      else
        @updateUnitPrice(@product.retail_price)



    'item.product_id': (newID, oldID)->
      if parseInt(newID) isnt parseInt(oldID)
        @$set(@item, 'product_id', newID)
        @getProductData()
        @focusOnQuantity()


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Item!"


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")

    set_store: (store_id)->
      @$set @item, 'store_id', store_id

    set_location: (location_id)->
      @$set @item, 'location_id', location_id

    set_stock_on_hand: (stock)->
      @stock_on_hand = stock


    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    get_product: (product_id)->
      options = {}
      products_store.fetchItem(product_id, options)


    filtered_store_stock_on_hand: (product, quantity)->
      inviolable_stock = quantity * product.inviolable_stock_percent / 100
      round_quantity(quantity - inviolable_stock)


    set_initial_price: ->
      if @item.id
        @item.unit_price = @round_money @item.unit_price
      else
        @updateUnitPrice(@product.retail_price) if @item.unit_price is 0


    set_initial_stock: ->
      @item.quantity = @round_quantity @item.quantity

      # do nothing if new item
      return unless @item.id

      if @item.location_id
        @store_name = @item.location_name
        @stock_on_hand = round_quantity(@item.location_stock_on_hand)
      else
        stock = @product.stores_list.find (s)=> s.store_id is +@item.store_id

        if stock
          @stock_on_hand = stock.filtered_stock_on_hand
          @store_name = stock.store_name


    getProductData: ->
      return unless @item.product_id

      @get_product(@item.product_id).then (product)=>
        @product = product
        @setDefaultUoM(@product.unit_of_measure)

        @set_initial_price()
        @set_initial_stock()

        @ready = true

        unless @product.on_sale
          msg = @product.name + ' غير متاح للبيع'
          window.notify(msg, {timeout: false, type: 'error'})

        @stores_list = @product.stores_list

        eventHub.$emit('products-loaded', {uid: @_uid})

      , (response)->
        console.error response


    removeItem: (index)->
      @$set(@item, '_destroy', true)

      if @item.clone
        eventHub.$emit('remove-item', { index: index })

    duplicate: ->
      console.log('DuplicateItem')
      @$emit('duplicate-item', @item)


    nextQuantityInput: (e)->
      @_el().find('.item-quantity').focus().select()
      return false


    nextInput: (item_class, e)->
      @_el().find(item_class).focus().select()
      return false


    viewErrorHandler: (data)->
      found = data.items.find (i)=>
        i.product_id is +@item.product_id and i.store_id is +@item.store_id
      @duplicated = if found then true else false


    selectContent: (e)->
      Vue.nextTick => e.target.select()

  computed:
    products: -> products_cache.products

    stock_on_hand_in_current_unit: ->
      return 0 unless @unit_of_measure

      @round_quantity(@calcQuantityInSelectedUom(@stock_on_hand))


    valid_quantity: ->
      @item.quantity != "" and @item.quantity > 0


    quantity_is_available: ->
      return true unless @item.store_id

      q = round_number(@item.quantity, 3)
      q > 0 and q <= @stock_on_hand_in_current_unit


    valid_product: ->
      !!@item.product_id

    on_sale: ->
      @product?.on_sale


    errors: ->
      quantity_present: @valid_quantity
      quantity_is_available: @quantity_is_available
      on_sale: @on_sale


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    isValidForTotal: ->
      @valid_quantity and @valid_product


    current_location_stock: ->
      return 0 unless @product

      stores = _.filter @product.stores_list, (s)->
        +s.location_id is App.location_id

      q = _.reduce stores, (sum, item)->
        sum.plus(item.filtered_stock_on_hand)
      , Big(0)
      q.toFixed(App.settings.quantity_round)


    has_low_quantity: ->
      @product and @current_location_stock < @item.quantity


    classList: ->
      list = {}
      list['off-sale']      = not @on_sale
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['low-quantity']  = @has_low_quantity
      list['hidden']        = @item._destroy
      list["so-item"]       = true
      list["item-#{@_uid}"] = true
      list
