$ = require 'jquery'

sideBar =
  init: ->
    $('.sidebar-details-backdrop').on 'click', (e)=> @close()

    $('.js-toggle-drawer').on 'click', (e)=>
      e.preventDefault()

      replaceWith = e.target.dataset.replacewith
      if replaceWith
        $('.sidebar-details-wrapper .sidebar-details').html($(replaceWith).html());

      @open()


  open: ->
    $('.sidebar-details-wrapper').addClass('is-open')


  close: ->
    $('.sidebar-details-wrapper').removeClass('is-open')
    $('.sidebar-details-wrapper .sidebar-details').empty()


greenPOS = window.greenPOS or {}
greenPOS.sideBar = sideBar

$ -> sideBar.init()
