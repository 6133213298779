$ = require 'jquery'
window.jQuery = $
_ = require 'lodash/core'
Vue = require 'vue'
Big = require 'big.js'
products_store = require '../../products_store.coffee'
products_cache = require '../../../products_caching.coffee'
inputKeyHandlers = require '../../mixins/input_key_handlers.coffee'
UnitOfMeasureHandlers = require '../../mixins/unit_of_measure_handlers.coffee'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'


module.exports =
  template: "#sales-order-item-template"
  props: ['index', 'item', 'supply_order']

  mixins: [inputKeyHandlers, UnitOfMeasureHandlers]

  components:
    'ui-products-dropdown': require('../../components/products_ui_dropdown.coffee')
    'units-dropdown': require('../../components/units_dropdown.coffee')
    'stores-dropdown': require('./stores-dropdown.coffee')


  store: store

  data: ->
    product: null
    stock_on_hand: 0
    duplicated: false
    dropdown: null
    store_name: "المخزن"
    location_name: null
    stores_list: []
    hovered: false
    ready: false
    has_sales_order_discount_per_item: window.App.features.sales_order_discount_per_item
    unitPriceField: null
    first_load_from_edit: false


  created: ->
    console.log 'I am ready'
#    eventHub.$on('change-product-id', @change_product_id)
    eventHub.$on('duplicated-items', @viewErrorHandler)
    eventHub.$on('scroll_to_current_element', @scroll_to_current_element)

    if @item.id
      @first_load_from_edit = true

    # fetch data on edit
    @getProductData()

    $modal = $('.ui-product-description-modal')
    Vue.nextTick =>
      $modal
        .modal({
        closable: true,
      })#.modal('attach events', e.target)



  mounted: ->
    Vue.nextTick =>
      @unitPriceField = @getUnitPriceField()
      @initUnitPricePopup()



  watch:
    'isValid': (nVal, oVal)->
      @item.valid = nVal


    'soft_valid_unit_price': (nVal, oVal)->
      $el = @unitPriceField

      if nVal is false and @ready
        console.info 'SHOW', nVal, oVal
        Vue.nextTick => $el.popup 'show'
      else
        console.info 'HIDE', nVal, oVal
        Vue.nextTick => $el.popup 'hide'


    'unit_of_measure': ()->
      if @first_load_from_edit
        @first_load_from_edit = false
      else
        @updateUnitPrice(@product.retail_price)



    'item.product_id': (newID, oldID)->
      if oldID is null
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})

      if parseInt(newID) isnt parseInt(oldID)
        @$set(@item, 'product_id', newID)
        @getProductData()
        @focusOnQuantity()


    'contact_segment': ->
      @apply_discount(@product) if @product


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Item!"


  destroyed: ->
    console.log 'Destroyed ME'


  methods:
    _el: -> $(".item-#{@_uid}")

    set_store: (store_id)->
#      console.log('STORE ..................', store_id)
      @$set @item, 'store_id', store_id

    set_location: (location_id)->
#      console.log('Location ..................', location_id)
      @$set @item, 'location_id', location_id

    set_stock_on_hand: (stock)->
      @stock_on_hand = stock


    getUnitPriceField: ->
      @_el().find('.item-unit-price')


    focusOnQuantity: ->
      @_el().find('.item-quantity').focus().select()


    get_product: (product_id)->
      options = {}
      options['distribution'] = 1 if @supply_order
      products_store.fetchItem(product_id, options)


    filtered_store_stock_on_hand: (product, quantity)->
      inviolable_stock = quantity * product.inviolable_stock_percent / 100
      round_quantity(quantity - inviolable_stock)


    getProductData: ->
      return unless @item.product_id

      @get_product(@item.product_id).then (product)=>
        @product = product
        @setDefaultUoM(@product.unit_of_measure)

        if @item.id
          @item.unit_price = @round_money @item.unit_price
        else
          @updateUnitPrice(@product.retail_price) if @item.unit_price is 0

        if @item.id
          if @item.location_id
            @store_name = @item.location_name
            @stock_on_hand = round_quantity(@item.location_stock_on_hand)
          else
            stock = _.find @product.stores_list, {store_id: @item.store_id}
            if stock
              @stock_on_hand = stock.filtered_stock_on_hand
              @store_name = stock.store_name

        if @supply_order
          stock = _.find @product.stores_list, {store_id: @item.store_id}
          if stock
            @stock_on_hand = stock.filtered_stock_on_hand
            @store_name = stock.store_name

        # Apply Discount if new item
        @apply_discount(@product) unless @item.id

        @item.quantity = @round_quantity @item.quantity

        @item.tax = @product.vat unless @item.id

        @ready = true

        unless @product.on_sale
          msg = @product.name + ' غير متاح للبيع'
          window.notify(msg, {timeout: false, type: 'error'})

        # @stock_on_hand = round_quantity(item.available_quantity)

        @stores_list = @product.stores_list

        eventHub.$emit('products-loaded', {uid: @_uid})

      , (response)->
        console.error response


    apply_discount: (product)->
      contact_discount = @contact_segment_discount()
      sales_policy_discount = @sales_policy_discount(product)
      @$set(@item, 'discount_percent', contact_discount + sales_policy_discount)


    contact_segment_discount: ->
      segment = @$store.state.segment
      if segment then segment.discount_percentage else 0


    sales_policy_discount: (product)->
      policy = @$store.state.policy
      return 0 unless policy and product

      matched = @matched_product_policy(policy, product)
      console.log 'matched', matched
      matched.percent_discount


    matched_product_policy: (policy, product)->
      zero_policy = { percent_discount: 0 }
      r = _.filter policy.items, (p)->
        p.product_id is product.id or
          product.product_category_id in p.categories_ids or
            product.id in p.products_ids
      _.last(r) or zero_policy


    removeItem: (index)->
      console.log ':index', index
      @$set(@item, '_destroy', true)
      unless @item.product_id
        eventHub.$emit('remove-item', { index: index })


    nextProduct: (e)->
      console.log 'ENTER'

      e.preventDefault()
      $next = $(".item-#{@_uid}").next()
      if $next.length
        $next.find('.selectized.product-dropdown')[0].selectize.focus()
      else
        eventHub.$emit('add-item')
        eventHub.$emit('scroll_to_current_element', {el: @_el()})


    nextUnitPriceInput: (e)->
      e.preventDefault()
      e.stopPropagation()
      @unitPriceField.focus().select()
      false


    nextQuantityInput: (e)->
      @_el().find('.item-quantity').focus().select()
      return false


    nextInput: (item_class, e)->
      @_el().find(item_class).focus().select()
      return false


    viewErrorHandler: (data)->
      vm = @
      found = _.find(data.items, (i)-> i.product_id is vm.item.product_id)
      @duplicated = if found then true else false


#    render_store_item: (store)->
#      "<li class='clearfix stores-list-item'>
#      <span class='store-name-in-list'
#      data-id='#{store.store_id}'
#      data-name='#{store.store_name}'
#      data-soh='#{store.filtered_stock_on_hand}'>
#        #{store.store_code or store.store_name}
#      </span>
#      <span class='ui label num qty-label qty-#{@_quantity_class(store)}'>
#        #{@round_quantity(store.filtered_stock_on_hand)}
#      </span>
#      </li>"
#
#
#    render_location_item: (location)->
#      "<li class='clearfix stores-list-item'>
#      <span class='store-name-in-list'
#      data-location-id='#{location.location_id}'
#      data-name='#{location.location_name}'
#      data-soh='#{location.location_quantity}'>
#        #{location.location_name}
#      </span>
#      <span class='ui label white num qty-label qty-'>
#        #{@round_quantity(location.location_quantity)}
#      </span>
#      </li>"
#
#
#    render_stores_popup: (popup, $popup_el)->
#      vm = this
#      # console.log vm.stores_list
#      return if vm.stores_list.length is 0
#
#      html = """
#        <ul class='ui middle aligned selection list sales-order-stores-list'>
#      """
#
#      stores_list = _.filter vm.stores_list, (s)->
#        round_quantity(s.filtered_stock_on_hand) > 0
#      grouped_stores_list = _groupBy stores_list, 'location_id'
#
#      _.each grouped_stores_list, (stores, location_id)->
#
#        # stores form current_location or with no location
#        if +location_id is App.location_id
#          filtered_stores = _.filter stores, (s)->
#            +s.store_id in window.App.user_allowed_stores_ids
#          filtered_stores = _.filter filtered_stores, (s)-> not s.defects
#
#          if filtered_stores.length
#            _.each filtered_stores, (store)->
#              html += vm.render_store_item(store)
#
#          else
#            location_quantity = _.reduce stores, (sum, item)->
#              sum.plus(item.filtered_stock_on_hand)
#            , Big(0)
#
#            location =
#              location_id: location_id
#              location_name: App.location_name
#              location_quantity: location_quantity.toFixed(App.settings.quantity_round)
#
#            html += vm.render_location_item(location)
#
#        else if location_id is "null"
#          filtered_stores = _.filter stores, (s)->
#            +s.store_id in window.App.user_allowed_stores_ids
#          filtered_stores = _.filter filtered_stores, (s)-> not s.defects
#
#          _.each filtered_stores, (store)->
#            html += vm.render_store_item(store)
#
#        else if location_id isnt "null"
#          location_quantity = _.reduce stores, (sum, item)->
#            sum.plus(item.filtered_stock_on_hand)
#          , Big(0)
#
#          location =
#            location_id: location_id
#            location_name: window.App.locations[location_id]
#            location_quantity: location_quantity.toFixed(App.settings.quantity_round)
#
#          html += vm.render_location_item(location)
#
#        else
#          # Do nothing
#
#      html += "</ul>"
#      popup.html(html)
#
#      popup.on 'click', '.stores-list-item', (e)->
#        $this = $(@).find('.store-name-in-list')
#        # console.log 'CLICKED', $this.data('id')
#
#        location_id = $this.data('locationId')
#        store_id = $this.data('id')
#
#        # Do nothing if clicked on current_location
#        if +location_id is App.location_id
#          $popup_el.popup 'hide'
#          return
#
#        if location_id
#          vm.set_location(location_id)
#          vm.set_store(null)
#        else
#          vm.set_store(store_id)
#          vm.set_location(null)
#
#        vm.store_name = $this.data('name')
#        vm.stock_on_hand = round_quantity($this.data('soh'))
#
#        $popup_el.popup 'hide'


#    bind_stores_popup: ->
#      return if @hovered
#
#      vm = @
#      popupLoading = '<i class="notched circle loading icon green"></i> wait...'
#      $el = @_el()
#      return unless $el.length
#
#      $popup_el = $el.find('.store-name')
#      @hovered = true
#
#      $popup_el.popup
#        inline: true
#        on: 'click'
#        exclusive: true
#        hoverable: true
#        position: 'bottom left'
#        html: popupLoading
#        variation: 'wide'
#        lastResort: 'bottom right'
#        delay:
#          show: 400
#          hide: 400
#        onShow: (el)->
#          vm.render_stores_popup(this, $popup_el)

#      #$popup_el.popup 'show' unless vm.item.id


#    _quantity_class: (item)->
#      qty = round_quantity(item.quantity)
#
#      reorder_quantity = item.reorder_quantity
#      safty_quantity = item.safty_quantity
#      max_quantity = item.max_quantity
#
#      switch
#        when qty is 0 then 'reorder_quantity'
#        when qty < safty_quantity then 'lt-safty_quantity'
#        when qty is safty_quantity then 'safty_quantity'
#        when qty is reorder_quantity then 'reorder_quantity'
#        when qty > reorder_quantity and qty < max_quantity then 'available'
#        when qty is max_quantity then 'max_quantity'
#        when qty > max_quantity then 'gt-max_quantity'


    scroll_to_current_element: (data)->
      el = data.el
      return unless el

      $scroll_element = $('.page-grid')
      # body_scrollTop = $('body').scrollTop()
      body_scrollTop = $scroll_element.scrollTop()
      # el_offsetTop = @_el().offset().top
      # el_offsetTop = @_el().get(0).offsetTop
      el_offsetTop = el.get(0).offsetTop

      # console.debug 'el_offsetTop', el_offsetTop
      # console.debug 'body_scrollTop', body_scrollTop

      position_on_viewport = el_offsetTop - body_scrollTop + 500
      mid_viewport = window.innerHeight/2

      # console.debug 'position_on_viewport', position_on_viewport
      # console.debug 'mid_viewport', mid_viewport

      if position_on_viewport > mid_viewport
        diff = position_on_viewport - mid_viewport
        # console.log "mid_viewportdiff", diff
        # $("html,body").stop().animate({
        $scroll_element.stop().animate({
          scrollTop: body_scrollTop + diff + 100
        }, 1000).eq(0) # we want function to be called just once (ref. "html,body")
        .queue (next)->
          # $.isFunction(options.complete) && options.complete.call(scroller[0]);
          next()


    selectContent: (e)->
      Vue.nextTick => e.target.select()


    initUnitPricePopup: ->
      $el = @unitPriceField
      $el.popup(
        on: 'manual',
        inline: true,
        content: "Invalid Price",
      ).popup 'hide'


    initProductPopup: (e)->
      eventHub.$emit('set:currentProduct', {
        name: this.product.name,
        description: this.product.description,
        cost_price: this.product.cost_price,
        retail_price: this.product.retail_price,
      })
      $('.ui-product-description-modal').modal('show')


  computed:
    products: ->
      if @supply_order
        window.trip_products
      else
        products_cache.products

    contact_segment: -> @$store.state.segment

    sub_total: ->
      if @isValidForTotal
        Big(@item.quantity).times(@item.unit_price)
      else
        Big(0)

    sub_total_after_discount: ->
      if @isValidForTotal
        @sub_total.minus(@discount)
      else
        Big(0)

    discount: ->
      discount = round_number(@item.discount_percent, 2)
      return 0 if discount  <= 0
      @sub_total.times(discount).div(100)


    total: ->
      if @isValidForTotal
        @sub_total_after_discount.plus(@tax_amount).toFixed(App.settings.money_round)
      else
        0


    tax_amount: ->
      tax = round_money(@item.tax)
      @sub_total_after_discount.times(tax).div(100)


    stock_on_hand_in_current_unit: ->
      return 0 unless @unit_of_measure

      @round_quantity(@calcQuantityInSelectedUom(@stock_on_hand))


    valid_quantity: ->
      @item.quantity != "" and @item.quantity > 0


    quantity_is_available: ->
      return true unless @item.store_id

      q = round_number(@item.quantity, 3)
      q > 0 and q <= @stock_on_hand_in_current_unit


    soft_valid_unit_price: ->
      return true unless @valid_product
      @valid_unit_price and
        @valid_unit_price_hard_limit


    valid_unit_price: ->
      return true unless @valid_product
      price = @round_money(@item.unit_price)
      #and @valid_unit_price_limits
      @item.unit_price != "" and price > 0


    valid_unit_price_hard_limit: ->
      return true unless @product
      return true unless App.branch_settings.use_product_price_limits_in_sales_orders

      price = @round_money(@item.unit_price)
      ref_price = @product.retail_price
      lower_price = @round_money(@product.retail_price_low_limit)
      higher_price = @round_money(@product.retail_price_high_limit)
      limit = Big(ref_price).times(0.1)

      low_limit = Big(if lower_price > 0 then lower_price else ref_price)
        .minus(limit).toFixed(2)
      high_limit = Big(if higher_price > 0 then higher_price else ref_price)
        .plus(limit).toFixed(2)

      low_limit <= price <= high_limit


    valid_unit_price_limits: ->
      return true unless @product

      price = @round_money(@item.unit_price)
      low_limit = @round_money(@product.retail_price_low_limit)
      high_limit = @round_money(@product.retail_price_high_limit)

      cond_1 = if low_limit > 0
        price >= low_limit
      else
        true

      cond_2 = if high_limit > 0
        price <= high_limit
      else
        true
      cond_1 and cond_2


    valid_discount: ->
      @item.discount_percent != "" and @round_money(@item.discount_percent) >= 0

    valid_tax: ->
      @item.tax != "" and @round_money(@item.tax) >= 0


    valid_product: ->
      !!@item.product_id

    on_sale: ->
      @product?.on_sale


    errors: ->
      quantity_present: @valid_quantity
      quantity_is_available: @quantity_is_available
      unit_price: @soft_valid_unit_price
#      product: @valid_product
      discount: @valid_discount
      tax: @valid_tax
      on_sale: @on_sale


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    isValidForTotal: ->
      @valid_quantity and @valid_unit_price and
        @valid_product and @valid_discount


    current_location_stock: ->
      return 0 unless @product

      stores = _.filter @product.stores_list, (s)->
        +s.location_id is App.location_id

      q = _.reduce stores, (sum, item)->
        sum.plus(item.filtered_stock_on_hand)
      , Big(0)
      q.toFixed(App.settings.quantity_round)


    has_low_quantity: ->
      @product and @current_location_stock < @item.quantity


    classList: ->
      list = {}
      list['off-sale']      = not @on_sale
      list['soft-error']    = not @soft_valid_unit_price
      list['error']         = not @isValid
      list['duplicated']    = @duplicated
      list['low-quantity']  = @has_low_quantity unless @supply_order
      list['hidden']        = @item._destroy
      list["so-item"]       = true
      list["item-#{@_uid}"] = true
      list
