$ = require 'jquery'
Vue = require 'vue'
eventHub = require '../../event_hub.coffee'
store = require '../../stores/default.coffee'
Immutable = require 'immutable'
_ = require 'lodash'

module.exports =
  template: """
    <div class="pos--cart">
      <ul class='pos--cart-items'>
        <cart-item
          v-for="(item, index) in cart_items_as_array"
          :cart_item="item"
          :index="index"
          :key="index"
          >
        </cart-item>
      </ul>

      <div class="pos--cart-summary">
        <div class="pos--cart-summary-line">&nbsp;</div>
        <div class='d-flex flex-column xjustify-content-between xalign-items-center'>
          <div class="pos--cart-summary-total">
            <span>Total: </span>
            <span class='num num-xxlarge'>{{total}}</span>

            <div class="fs-6 text-muted">
              <span>Taxes: </span>
              <span class='num'>{{total_taxes}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  """
  components:
    'cart-item': require('./cart_item.coffee')


  store: store

  data: ->
    panel: null


  created: ->
    console.log 'Cart is ready'


  mounted: ->
#    @panel = $(".pos--panel-inner-wrapper")
    @panel = $(".pos--cart-items")


  watch:
    'cart_items': (newVal, oldVal)->
      @scrollCartPanel()


  methods:
    scrollCartPanel: ->
      # @TODO: scroll if item is not visible
      Vue.nextTick =>
        if @panel.length
          height = @panel[0].scrollHeight
          @panel.scrollTop(height)


  computed:
    cart_items: -> @$store.state.pos.current_tab.cart_items
    total: -> @$store.getters.orderSubTotal
    total_taxes: -> @$store.getters.totalTaxes.toFixed(2)



    cart_items_as_array: ->
      @cart_items.toSet().toArray()



