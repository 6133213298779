$ = require 'jquery'
Vue = require 'vue'
store = require '../../stores/default.coffee'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <div class="pos--cat--item" @click="loadProducts" draggable='false'>
      <div class="item-image">
        <img :src="item.image"/>
      </div>
      <div class="item-name">
        {{ item.name }}
      </div>
    </div>
  """
  props: ['item']

  store: store

  data: ->
    products: []


  methods:
    loadProducts: (e)->
      @$store.dispatch('POS_LOAD_CAT_WITH_PRODUCTS', {cid: @item.id}).then (data)=>
        eventHub.$emit('pos-push-breadcrumb-category', { category: data })
        @$store.commit('set_current_category', {category: data})




  # computed:
  #   categories: -> @$store.state.product_categories
