import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  connect () {
    this.bind_tabs();
  }

  initialize() {
  }

  bind_tabs() {
    $('.js-tabs .item').tab({})
  }

  disconnect() {
  }
};
