import { Controller } from "stimulus"

export default class extends Controller {
  keyboardClick(event) {
    if ([ "Enter", " " ].includes(event.key)) {
      event.preventDefault()
      event.stopPropagation()

      this.element.click()
    }
  }
};
