eventHub = require '../event_hub.coffee'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'contract[signees_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'contract[signees_attributes][' + _uid + '][id]'">

        <div class="ui field primary-color">
          <input v-model="item.name"
            :name="'contract[signees_attributes][' + _uid + '][name]'"
            class="item-amount ltr">
        </div>
      </td>

      <td>
        <div class="ui field">
          <input type='file'
            :name="'contract[signees_attributes][' + _uid + '][logo]'"
            class="item-logo">
        </div>
      </td>

      <td>
        <div class="text-left">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']
  data: ->
    logo: null


  created: ->
    console.log ''


  renderError: (h, err)->
    # h('pre', { style: { color: 'red' }}, err.stack)
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    removeItem: (index)->
      if @item.id
        @$set(@item, '_destroy', true)
      else
        eventHub.$emit('remove-item', { index: index })


  computed:
    isValid: ->
      @item.name?

    classList: ->
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list["item-#{@_uid}"] = true
      list

