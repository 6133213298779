eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'mrp_bill_of_material[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'mrp_bill_of_material[items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <input
            v-model="item.name"
            :name="'mrp_routing[items_attributes][' + _uid + '][name]'">
        </div>
      </td>

      <td>
        <select
            v-model="item.work_center_id"
            :name="'mrp_routing[items_attributes][' + _uid + '][work_center_id]'"
            class='work_centers-dropdown'>
              <option
                v-for="center in work_centers"
                :value="center.id">
                {{center.name}}
              </option>
            </select>
      </td>

      <td>
        <div class="ui field">
            <textarea
              rows='3'
              v-model="item.description"
              :name="'mrp_routing[items_attributes][' + _uid + '][description]'">
            </textarea>
          </div>
      </td>


      <td>
        <div class="ui field">
          <input
            v-model.number="item.duration_in_minutes"
            :name="'mrp_routing[items_attributes][' + _uid + '][duration_in_minutes]'">
        </div>
      </td>


      <td class="text-left">
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['item', 'index']
  data: ->
    work_centers: window.work_centers || []


  created: ->
    console.log 'I am ready'


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:

    removeItem: (index)->
      eventHub.$emit('remove-item', { index: index })


#  watch:


  computed:

    valid_name: ->
      @item.name != ""


    valid_work_center: ->
      !!@item.work_center_id


    errors: ->
      work_center: @valid_work_center
      name: @valid_name


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    classList: ->
      # list['duplicated']    = @duplicated
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list['st-item']       = true
      list["item-#{@_uid}"] = true
      list

