$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
store = require './stores/default.coffee'

products_store = require './products_store.coffee'
eventHub = require './event_hub.coffee'
require './directives/ui_dropdown.coffee'
require './directives/date_picker.coffee'


view = new Vue
#  el: '.general-purchase-return-form-wrapper'
  store: store
  data: ->
    items: window.general_purchase_return_items
    errors: []
    is_ready: false
    debit_note_number: window.general_purchase_return?.debit_note_number
    contact_id: window.general_purchase_return?.contact_id
    issued_at: window.general_purchase_return?.issued_at
    notes: window.general_purchase_return?.notes
    contact: null


  components:
    'general-purchase-return-item': require('./components/general-purchase-return-item.coffee')


  beforeCreate: ->
    el = $('.general-purchase-return-items-wrapper')
    return unless el.length


  created: ->
    el = $('.general-purchase-return-items-wrapper')
    return unless el.length

    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)

    @contact_id = window.general_purchase_return_contact_gid
    products_store.fetchSimpleItems(@).then ->
      if window.general_purchase_return_items.length > 0
        Vue.set(view, 'items', window.general_purchase_return_items)
      else
        eventHub.$emit('add-item') if window.general_purchase_return


  mounted: ->
    # Vue.nextTick =>
    @bind_contacts_dropdown()


  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})


    products_is_ready: (newVal)->
      if newVal
        if window.general_purchase_return_items
          Vue.set(view, 'items', window.general_purchase_return_items)
        else
          @addItem() if general_purchase_return


  computed:
    items_count: -> @itemsWithProduct.length

    products_is_ready: -> @$store.state.products_is_ready


    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    validIssuedAt: ->
      @issued_at? and @issued_at != ""


    validContact: ->
      @contact_id? and @contact_id != ""


    isValidAttributes: ->
     @validIssuedAt and @validContact


    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    addItem: (event)->
      item = {
        quantity: 1,
        unit_price: 0,
        product_id: null,
        unit_of_measure_id: null,
        valid: true
      }
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      console.log 'Index', data.index
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      item.valid


    bind_contacts_dropdown: ->
      vm = @

      $('.js-gpr-contacts-dropdown').dropdown
        apiSettings: {
          cache: false,
          throttle: 700,
          url: '/search/suppliers?q={query}'
        },
        saveRemoteData: false
        match: 'text',
        minCharacters: 3,
        showOnFocus: true
        forceSelection: false
        selectOnKeydown: false
        keys:
          backspace  : 8
          delimiter  : 188
          deleteKey  : 46
          enter      : 13
          escape     : 27
          pageUp     : false
          pageDown   : false
          leftArrow  : 37
          upArrow    : 38
          rightArrow : 39
          downArrow  : 40


    selectContent: (e)->
      Vue.nextTick => e.target.select()



window.general_purchase_return_view = view


if document.querySelector('.general-purchase-return-form-wrapper')
  view.$mount('.general-purchase-return-form-wrapper')
