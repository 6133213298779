$ = require 'jquery'

App = window.App || {}
App.auth_token_headers =
  'X-User-Id': App.user_id
  'X-User-Token': App.user_auth_token


$.ajaxSetup
  headers: App.auth_token_headers

