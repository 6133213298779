$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'

products_store = require '../products_store.coffee'

require '../directives/ui_dropdown.coffee'
require '../directives/selectize_tags.coffee'



sales_order_return = new Vue
#  el: '.sales-order-return-form-wrapper'
  data:
    items: window.sales_order_return_items || []
    is_ready: false
    store_id: window.sales_order_return?.store_id
    credit_note_number: window.sales_order_return?.credit_note_number
    tags: window.sales_order_return?.tags
    default_tags: window.default_tags or []

  components:
    # 'sales-order-return-item': require('./components/sales-order-return-item.coffee')
    'sales-order-return-item': require('./components/sales-order-return-item-with-store.coffee')


  created: ->
    el = $('.sales-order-return-items-wrapper')
    return unless el.length


  computed:
    supply_order: -> window.supply_order


    total_units: -> (_.filter @items, (i)-> i.selected).length

    order_total: ->
      vm = @
      t = _.reduce @items, (sum, item)->
        if item.selected and vm.itemIsValid(item)
          sum.plus(Big(item.quantity).times(item.unit_price))
        else
          sum.plus(0)
      , Big(0)
      t.toFixed(App.settings.money_round)


    selectedItems: ->
      _.filter(@items, (i)-> i.selected) or []


    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(i)

    hasSelectedItems: ->
      @selectedItems.length > 0

    validCreditNoteNumber: ->
      @credit_note_number? and @credit_note_number != ""
      true

    validStoreID: ->
      @store_id? and @store_id != ""
      true

    isValid: ->
      @validCreditNoteNumber and @validStoreID and @hasSelectedItems and @itemsIsValid


  methods:
    addItem: (event)->
      item = {}
      @items.push item
      event.preventDefault()

    removeItem: (index)->
      @items.splice(index, 1)


    itemIsValid: (item)->
      q = round_quantity(item.quantity)
      item.quantity != "" and q > 0 and
        q <= round_quantity(item.not_returned_quantity) and item.store_id?



if document.querySelector('.sales-order-return-form-wrapper')
  sales_order_return.$mount('.sales-order-return-form-wrapper')
