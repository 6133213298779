$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'

window.jQuery = $

module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="question.id"
          :name="'hr_application_form[question_fields_attributes][' + _uid + '][id]'">
        <input type="hidden" v-model="question.field_type"
          :name="'hr_application_form[question_fields_attributes][' + _uid + '][field_type]'">
        <input type="hidden" v-model="question._destroy"
          :name="'hr_application_form[question_fields_attributes][' + _uid + '][_destroy]'">

        <div class="ui field" :class="{ 'error': !validName }">
          <input
            v-model="question.name"
            :name="'hr_application_form[question_fields_attributes][' + _uid + '][name]'"
            class="input-small">
        </div>
      </td>

      <td class='text-center'>
        <!--<div class="inline field">
          <div class="ui checkbox">
            <input
            type='checkbox'
            v-model="question.required"
            :name="'hr_application_form[question_fields_attributes][' + _uid + '][required]'"
            class="">
            <label></label>
          </div>
        </div>-->
      </td>

      <td class='text-left'>
        <div class="">
          <a class="ui basic icon button js-btn-remove" @click="removeQuestion(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """
  props: ['index', 'question']
  data: ->
    ready: false

  created: ->
    console.log 'question:: I am ready'


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering question!"


  computed:
    validName: ->
      @question.name isnt null and @question.name isnt "" and @question.name.length > 2


    errors: ->
      name: @validName


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["item-#{@_uid}"] = true
      list['hidden']        = @question._destroy
      list


  methods:
    _el: -> $(".item-#{@_uid}")

    removeQuestion: (index)->
      @$set(@question, '_destroy', true)

