$ = require 'jquery'
Vue = require 'vue'
store = require '../../stores/default.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'
_dropRight = require 'lodash/dropRight'


module.exports =
  template: """
    <div class='pos-breadcrumb d-flex'>
      <div class='breadcrumb-items d-flex' style='width: 90%'>
        <div class='breadcrumb-item home' @click.prevent="goHome">Home</div>

        <div v-for="(item, index) in items"
            :item="item"
            :index="index"
            :key="index"
            class='breadcrumb-item'
        >
          <i class="ui icon angle left"></i>
          {{item.name}}
        </div>
      </div>
      <div class='go-back' @click.prevent="goBack"  v-if="showGoBack">
        <i class="ui icon angle right"></i>
        Back
      </div>
    </div>
  """
  # props: ['items']

  store: store

  data: ->
    items: []



  created: ->
    console.log 'BreadCrumb is ready'

    eventHub.$on('pos-push-breadcrumb-category', @addToBreadcrumb)
    eventHub.$on('pos-clear-breadcrumb', @goHome)

    @items = @defaultItems()


  methods:
    defaultItems: ->
      [
        {name: 'Home', url: '/', root: true}
      ]
      []


    goHome: ->
      @items = @defaultItems()
      @setCurrentItem(null)
      @$store.commit('set_category_children_as_root')


    goBack: ->
      @items = _dropRight(@items, 1)
      item = _.last(@items)

      console.log 'last item', item

      if item
        @addToBreadcrumb(category: item.cat)
        @$store.commit('SET_POS_CATEGORY_CHILDREN', {children: item.cat.children})
        @$store.commit('SET_POS_PRODUCTS', { products: item.cat.products })

        console.log 'last item normal category', item.cat
        console.log 'last item normal category items', item.cat.children

      else
        @setCurrentItem(null)
        @$store.commit('set_category_children_as_root')

        console.log 'last item is root'



    addToBreadcrumb: (data)->
      new_item = data.category
      item =
        cat: new_item
        name: new_item.name
        id: new_item.id
        root: false

      return if @current_breadcrumb_item is new_item

      unless @current_breadcrumb_item
        @items.push item
        @setCurrentItem(new_item)
        return

      if new_item.ancestry is @current_breadcrumb_item.ancestry
        @items = _dropRight(@items, 1)
        @items.push item

      else
        new_item_level = @getItemLevel(new_item)
        current_item_level = @getItemLevel(@current_breadcrumb_item)
        if new_item_level > current_item_level
          @items.push item

        if new_item_level < current_item_level
          @items = _dropRight(@items, (current_item_level - new_item_level))
          @items.push item

      @setCurrentItem(new_item)


    setCurrentItem: (item)->
      @$store.commit('set_current_breadcrumb_item', {category: item})

#    setCurrentCategory: (data)->
#      new_item = data.category
#      item =
#        cat: new_item
#        name: new_item.name
#        id: new_item.id
#        root: false
#
#      return if @current_item is new_item
#
#      unless @current_item
#        @items.push item
#        @current_item = new_item
#        return
#
#      if new_item.ancestry is @current_item.ancestry
#        @items = _dropRight(@items, 1)
#        @items.push item
#
#      else
#        new_item_level = @getItemLevel(new_item)
#        current_item_level = @getItemLevel(@current_item)
#        if new_item_level > current_item_level
#          @items.push item
#
#        if new_item_level < current_item_level
#          @items = _dropRight(@items, (current_item_level - new_item_level))
#          @items.push item
#
#      @current_item = new_item


    getItemLevel: (item)->
      return 0 unless item.ancestry
      item.ancestry.split('/').length


  computed:
    showGoBack: -> @items.length > 0
    current_breadcrumb_item: -> @$store.state.pos.current_tab.current_breadcrumb_item
    currentCategory: -> @$store.state.pos.current_tab.current_category
