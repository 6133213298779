products_store = require '../../products_store.coffee'
eventHub = require '../../event_hub.coffee'
_ = require 'lodash/core'
UnitOfMeasureHandlers = require '../../mixins/unit_of_measure_handlers.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'stock_transfer[items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'stock_transfer[items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <select v-ui-dropdown="item.product_id"
            :name="'stock_transfer[items_attributes][' + _uid + '][product_id]'"
            :data-uid="_uid"
            class='product-dropdown'></select>
        </div>
      </td>

      <td>
        <div class='two fields mb-0'>
          <div class="ui field" :class="{ 'error': !valid_quantity }">
            <input
              v-model.number="item.quantity"
              :name="'stock_transfer[items_attributes][' + _uid + '][quantity]'"
              class="input-small item-quantity ltr">
          </div>
          <input type='hidden' v-model='item.unit_of_measure_id'
            :name="'stock_transfer[items_attributes][' + _uid + '][unit_of_measure_id]'" />
          <span class="p-2 d-inline-block">{{product && product.unit_of_measure.name}}</span>
        </div>
      </td>

      <td class="text-left">
        <div class="source-stock-on-hand num">{{source_quantity}}</div>
      </td>

      <td class="text-left">
        <div class="source-stock-after-change num">{{source_quantity_after_change}}</div>
      </td>

      <td class="text-left">
        <div class="destination-stock-on-hand num">{{destination_quantity}}</div>
      </td>

      <td class="text-left">
        <div class="destination-stock-after-change num">{{destination_quantity_after_change}}</div>
      </td>

      <td class="text-left">
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  mixins: [UnitOfMeasureHandlers]

  props: ['item', 'source_store_id', 'destination_store_id', 'index']
  data: ->
    product: null
    stores_list: []


  created: ->
    console.log 'I am ready'
    eventHub.$on('change-product-id', @change_product_id)


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering Page!"


  methods:
    get_product: (product_id)->
      products_store.fetchItem(product_id)


    change_product_id: (data)->
      if data.uid == @_uid
        @item.product_id = data.id


    removeItem: (index)->
      eventHub.$emit('remove-item', { index: index })


    update_item_data: ->
      @get_product(@item.product_id).then (product)=>
        @product = product
        @stores_list = product.stores_list
        @setDefaultUoM(@product.unit_of_measure)

      , (response)->
        console.error response


  watch:
    'item.product_id': (new_id, old_id)->
      @update_item_data()


  computed:
    source_quantity: ->
      stock = _.find @stores_list, {store_id: +@source_store_id}
      if stock
        round_quantity(stock.quantity)
      else
        0

    destination_quantity: ->
      stock = _.find @stores_list, {store_id: +@destination_store_id}
      if stock
        round_quantity(stock.quantity)
      else
        0

    source_quantity_after_change: ->
      round_quantity(@source_quantity) - round_quantity(@item.quantity)

    destination_quantity_after_change: ->
      round_quantity(@destination_quantity) + round_quantity(@item.quantity)


    valid_quantity: ->
      @item.quantity != ""


    quantity_is_available: ->
      q = @round_quantity(@source_quantity_after_change)
      q >= 0


    valid_product: ->
      !!@item.product_id


    errors: ->
      quantity_present: @valid_quantity
      quantity_is_available: @quantity_is_available
      product: @valid_product


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    classList: ->
      # list['duplicated']    = @duplicated
      list = {}
      list['error']         = not @isValid
      list['hidden']        = @item._destroy
      list['st-item']       = true
      list["item-#{@_uid}"] = true
      list

