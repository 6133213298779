$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'

products_store = require './products_store.coffee'

require './directives/ui_dropdown.coffee'


general_purchase_return_fulfillment = new Vue
#  el: '.general-purchase-return-fulfillment-form-wrapper'
  data: ->
    items: window.fulfillment_items || []
    is_ready: false
    packed_at: window.general_purchase_return_fulfillment?.packed_at
    store_id: window.general_purchase_return_fulfillment?.store_id
    voucher_number: window.general_purchase_return_fulfillment?.voucher_number
    goods_returned_receipt_id: window.general_purchase_return_fulfillment?.goods_returned_receipt_id


  components:
    'general-purchase-return-fulfillment-item': require('./components/general-purchase-return-fulfillment-item.coffee')


  created: ->
    # do nothing


  mounted: ->
    Vue.nextTick @bind_enter_to_save
    @bind_goods_receipts_dropdown()


  computed:
    total_units: -> (_.filter @items, (i)-> i.selected).length


    selectedItems: ->
      _.filter(@items, (i)-> i.selected) or []


    itemsIsValid: ->
      vm = @
      not _.some @selectedItems, (i)-> not vm.itemIsValid(i)


    hasSelectedItems: ->
      @selectedItems.length > 0


    validPackedAt: ->
      @packed_at? and @packed_at != ""


    validStore: ->
      (@store_id? and @store_id != "")


    validAttributes: ->
      @validPackedAt and @validStore


    isValid: ->
      @validAttributes and @hasSelectedItems and @itemsIsValid


  methods:
    itemIsValid: (item)-> item.valid


    bind_enter_to_save: ->
      el = $('.general-purchase-return-fulfillment-form-wrapper')
      if el.length
        $('body').on 'keypress', (e)=>
          if e.which is 13 and @isValid
            console.log 'enter'
            el.find('input[type="submit"]').trigger 'click'
            true


    bind_goods_receipts_dropdown: ->
      $('.js-remote-returned-receipt-vue-dropdown').dropdown
        showOnFocus: true
        forceSelection: false
        placeholder: false
        match: 'text'
        minCharacters: 3
        throttle: 700
        apiSettings:
          cache: false
          url: '/search/returned_receipts?q={query}'
        saveRemoteData: false



if document.querySelector('.general-purchase-return-fulfillment-form-wrapper')
  general_purchase_return_fulfillment.$mount('.general-purchase-return-fulfillment-form-wrapper')
