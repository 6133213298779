$ = require 'jquery'
eventHub = require '../../event_hub.coffee'


module.exports =
  template: """
    <tr class="form-field" :class="classList">
      <td>
        <input type="hidden" v-model="item._destroy"
            :name="'mrp_bill_of_material[qc_items_attributes][' + _uid + '][_destroy]'">
        <input type="hidden" v-model="item.id"
          :name="'mrp_bill_of_material[qc_items_attributes][' + _uid + '][id]'">

        <div class="ui field">
          <input
            placeholder='Name'
            v-model="item.name"
            :name="'mrp_bill_of_material[qc_items_attributes][' + _uid + '][name]'"
            class="input-small item-name ltr">
        </div>

</td>
<td>
        <div class="ui field">
          <input
            placeholder='Optimum'
            v-model="item.optimum"
            :name="'mrp_bill_of_material[qc_items_attributes][' + _uid + '][optimum]'"
            class="input-small item-name ltr">
        </div>
</td>
<td>
        <div class="ui field">
          <input
            placeholder='Acceptance from'
            v-model="item.acceptance_from"
            :name="'mrp_bill_of_material[qc_items_attributes][' + _uid + '][acceptance_from]'"
            class="input-small item-name ltr">
        </div>
</td>
<td>
        <div class="ui field">
          <input
            placeholder='Acceptance to'
            v-model="item.acceptance_to"
            :name="'mrp_bill_of_material[qc_items_attributes][' + _uid + '][acceptance_to]'"
            class="input-small item-name ltr">
        </div>
      </td>

      <td class="text-left">
        <div class="">
          <a class="ui basic icon button" @click="removeItem(index)">
            <i class="icon trash"></i>
          </a>
        </div>
      </td>
    </tr>
  """

  props: ['item', 'index']


  created: ->
    console.log 'QcItem is ready'



  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering QcItem!"


  methods:
    removeItem: (index)->
      @$set(@item, '_destroy', true)
      unless @item.id
        eventHub.$emit('remove-qc', { index: index })




  computed:

    classList: ->
      # list['duplicated']    = @duplicated
      list = {}
      list['hidden']        = @item._destroy
      list['st-item']       = true
      list["item-#{@_uid}"] = true
      list

