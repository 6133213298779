$ = require 'jquery'
Selectize = require 'selectize'
Vue = require 'vue'

store = require '../stores/default.coffee'
#products_cache = require '../../products_caching.coffee'

eventHub = require '../event_hub.coffee'


module.exports =
  template: """
   <select :name="name" class='product-dropdown'></select>
  """
  props: ['products', 'name', 'value']
  store: store
  data: ->
    hovered: false
    dropdown: null


  created: ->
    console.log 'DropDownComponent::created'
#    eventHub.$on('change-product-id', @change_product_id)


  mounted: ->
    Vue.nextTick =>
      @init_dropdown()


  destroyed: ->
    console.log 'DropDownComponent::destroyed'


  watch:
    value: (newID, oldID)->
      if newID != oldID
        console.log "DropDownComponent::change product_id", newID
        @dropdown and @dropdown.addItem(newID)


  methods:
    _el: -> @$el

    init_dropdown: ->
      $dropdown = $(@_el()).selectize
        plugins: ['dropdown_direction']
        dropdownDirection: 'auto'
        highlight: false
        valueField: 'id'
        labelField: 'name'
        maxOptions: 50
        options: @products
        searchField: ['name', 'sku', 'brand', 'tags', 'ean_code']
        searchConjunction: 'and'
        sortField: [
          {field: '$score', direction: "desc"},
          {field: "filtered_stock_on_hand", direction: "desc"}
        ]
        score: (search)->
          score = @getScoreFunction(search)
          (item)->
            q = if item.filtered_stock_on_hand <= 0
              0
            else
              item.filtered_stock_on_hand

            q = if item.is_featured
              q + 5
            else
              q + 2

            score(item) * (1 + Math.min(q / 100, 1))

        render:
          option: @option_template

      $dropdown = $dropdown[0].selectize

      @dropdown = $dropdown

      $dropdown.on 'item_add', (val, $item)=>
        console.log 'DropDownComponent::item_add', val

        if parseInt(val) isnt @value
#          eventHub.$emit('input', val)
          @$emit('input', val)
        else
          console.log 'DropDownComponent: item_add : same id'

      # Initial Setting product id
      if @value
        console.log 'DropDownComponent::Initial Setting for production_id'
        $dropdown.addItem(@value, true) # silent


    option_template: (item, escape)->
      qty = round_quantity(item.filtered_stock_on_hand)

      "<div>" +
        "<span class='xpname'>#{escape(item.name)}</span>" +
        "<span class='tot-quantity'>
          <span class='ui label qty-#{@item_class(item)} p-quantity qty-label num'>#{qty}</span>
                </span>" +
        "</div>"


    item_class: (item)->
      qty = round_quantity(item.filtered_stock_on_hand)

      reorder_quantity = item.reorder_quantity
      safty_quantity = item.safty_quantity
      max_quantity = item.max_quantity

      switch
        when qty is 0 then 'reorder_quantity'
        when qty < safty_quantity then 'lt-safty_quantity'
        when qty is safty_quantity then 'safty_quantity'
        when qty is reorder_quantity then 'reorder_quantity'
        when qty > reorder_quantity and qty < max_quantity then 'available'
        when qty is max_quantity then 'max_quantity'
        when qty > max_quantity then 'gt-max_quantity'


  computed:
    classList: ->
      list = {}
      list["so-item"]       = true
      list
