$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
_debounce = require 'lodash/debounce'
eventHub = require '../../event_hub.coffee'

window.jQuery = $

module.exports =
  template: "#hr-task-item-template"
  props: ['index', 'task']
  data: ->
    ready: false

  created: ->
    console.log 'task:: I am ready'


  renderError: (h, err)->
    greenPOS.notify_error "Error Rendering task!"


  watch:
    'task.done': -> @toggleDone()



  computed:
    validName: ->
      @task.name isnt null and @task.name isnt "" and @task.name.length > 2


    errors: ->
      name: @validName


    isValid: ->
      not _.some @errors, (v, k)-> v is false


    classList: ->
      list = {}
      list['error']         = not @isValid
      list["item-#{@_uid}"] = true
      list['invisible']        = @task._destroy
      list['js-hidden']        = @task._destroy
      list['is-done']        = @task.done
      list


  methods:
    _el: -> $(".item-#{@_uid}")

    task_url: -> "/hr/tasks/#{@task.id}"

    editTask: (index)->
      console.log "INDEX: #{index}"
      eventHub.$emit('editTask', {index: index})


    toggleDone: _debounce ->
      url = "#{@task_url()}/toggle_done"

      $.ajax({
        type: 'PUT',
        dataType: 'json',
        url: url,
      }).done((r)->
        console.log('response', r)
      )


    removeTask: (index)->
      @$set(@task, '_destroy', true)
      url = @task_url()

      $.ajax({
        type: 'DELETE',
        dataType: 'json',
        url: url,
      }).done((r)->
        console.log('response', r)
      )

