import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    fetched: { type: Boolean, default: false },
  }

  connect() {
    if (!this.element.classList.contains('has-children')) {
      this.disconnect();
      return;
    }
    console.log('account row connected')
  }

  fetch_subtree(event) {
    event.preventDefault();

    if (!this.element.classList.contains('has-children')) {
      return;
    }

    console.log('fetched ? ', this.fetchedValue)
    if (this.fetchedValue) {
      console.log('fetched')

      let id = `#children_of_${this.element.id}`;
      console.log('id', id);
      document.querySelector(id).remove();
      this.fetchedValue = false;
      this.element.classList.remove('open');
      return;
    }

    get(this.url)
      .then((response) => {
        return response.html
      })
      .then((response) => {
        this.add_subtree(response);
        this.fetchedValue = true;
        this.element.classList.add('open');
      })
  }

  add_subtree(subtree) {
    this.element.insertAdjacentHTML('afterend', subtree)
  }

  get url() {
    return this.element.dataset.url;
  }
};
